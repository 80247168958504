.product-review {
  font-family: "Glober-Regular";

  &__header {
    @media (max-width: 800px) {
      margin-bottom: 30px;
    }
    @media (min-width: 801px) {
      margin-bottom: 40px;
    }
  }

  &__name {
    color: #202020;
    display: inline;
    font-weight: 400;
    letter-spacing: 0.3px;
    @media (max-width: 800px) {
      font-size: 18px;
      line-height: 1.4;
    }
    @media (min-width: 801px) {
      font-size: 22px;
      line-height: 1.14;
    }
  }

  &__date {
    color: #878787;
    display: inline-block;
    @media (max-width: 800px) {
      font-size: 14px;
      line-height: 1.8;
      padding-left: 20px;
    }
    @media (min-width: 801px) {
      font-size: 16px;
      line-height: 1.56;
      padding-left: 18px;
    }
  }

  &__section {
    & + & {
      margin-top: 28px;
    }
  }

  &__section-title {
    color: #565656;
    font-weight: 600;
    line-height: 1.79;
    letter-spacing: 0.2px;
    position: relative;
    @media (max-width: 800px) {
      font-size: 14px;
      padding-left: 25px;
    }
    @media (min-width: 801px) {
      font-size: 16px;
      line-height: 1.56;
      padding-left: 30px;
    }
  }

  &__section-title-icon {
    fill: #565656;
    left: 0;
    position: absolute;
    top: 0;
    @media (max-width: 800px) {
      height: 17px;
      width: 17px;
    }
    @media (min-width: 801px) {
      height: 19px;
      width: 19px;
    }
  }

  &__section-content {
    color: #878787;
    letter-spacing: 0.2px;
    margin-top: 19px;
    @media (max-width: 800px) {
      font-size: 13px;
      line-height: 1.77;
    }
    @media (min-width: 801px) {
      font-size: 16px;
      line-height: 1.56;
    }
  }
}