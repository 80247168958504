.cart {
  font-family: "Glober-Regular";
  @media (max-width: 800px) {
    padding-bottom: 45px;
  }
  @media (min-width: 801px) {
    padding-bottom: 60px;
  }

  &__breadcrumbs {
    background-color: #f5f5f5;
    margin: 0 -9999px;
    padding-top: 50px;
    padding: 50px 9999px 0;
    @media (max-width: 800px) {
      display: none;
    }
  }

  &__title {
    background-color: #f5f5f5;
    color: #202020;
    font-weight: 400;
    @media (max-width: 800px) {
      font-size: 30px;
      line-height: 1.1;
      letter-spacing: -0.8px;
      margin-bottom: 26px;
      padding-top: 32px;
    }
    @media (min-width: 801px) {
      font-size: 44px;
      letter-spacing: -1.1px;
      margin-bottom: 62px;
      padding-top: 50px;
    }
  }

  &__items-labels {
    align-items: flex-start;
    margin-bottom: 22px;
    padding-right: 100px;
    @media (max-width: 800px) {
      display: none;
    }
    @media (min-width: 801px) {
      display: flex;
    }
  }

  &__items-label {
    color: #878787;
    flex: 0 0 auto;
    font-size: 16px;
    letter-spacing: 0.2px;
    line-height: 1.56;
  }
  &__items-label--name {
    flex: 1 1 0;
    margin-right: auto;
    max-width: 420px;
  }
  &__items-label--price {
    margin: 0 auto;
    text-align: center;
    width: 90px;
  }
  &__items-label--quantity {
    margin: 0 auto;
    text-align: center;
    width: 113px;
  }
  &__items-label--sum {
    margin-left: auto;
    width: 134px;
  }

  &__items-list {
    box-shadow: -2.3px 4.4px 15px 0 rgba(0, 0, 0, 0.04);
    @media (max-width: 800px) {
    }
    @media (min-width: 801px) {
      margin-left: -20px;
      margin-right: -20px;
    }
  }

  &__item {
    margin-top: 1px;
    @media (max-width: 800px) {
    }
    @media (min-width: 801px) {
    }
  }

  &__total {
    align-items: baseline;
    display: flex;
    @media (max-width: 800px) {
      margin-top: 29px;
    }
    @media (min-width: 801px) {
      margin-top: 32px;
      padding-right: 46px;
    }
  }

  &__total-label {
    color: #878787;
    letter-spacing: 0.3px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    @media (max-width: 800px) {
      font-size: 17px;
      margin-right: 19px;
    }
    @media (min-width: 801px) {
      font-size: 19px;
      margin-left: auto;
      margin-right: 24px;
    }
  }

  &__total-sum {
    color: #3a3a3a;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    @media (max-width: 800px) {
      font-size: 16px;
      letter-spacing: 0.4px;
      width: 126px;
    }
    @media (min-width: 801px) {
      font-size: 18px;
      letter-spacing: 0.5px;
      padding-right: 10px;
      width: 134px;
    }
  }

  &__total-quantity {
    background-color: @red-main; // earlier color: #dd2a1b
    border-radius: 5px;
    color: #fff;
    height: 29px;
    line-height: 28px;
    overflow: hidden;
    padding-top: 1px;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 54px;
    @media (max-width: 800px) {
      font-size: 12px;
      letter-spacing: .2px;
    }
    @media (min-width: 801px) {
      font-size: 13px;
      letter-spacing: .2px;
    }
  }

  &__ordering {
    @media (max-width: 800px) {
      margin-top: 67px;
    }
    @media (min-width: 801px) {
      margin-top: 70px;
    }
  }

  &__ordering-title {
    color: #202020;
    font-weight: 400;
    @media (max-width: 800px) {
      font-size: 25px;
      letter-spacing: -0.6px;
    }
    @media (min-width: 801px) {
      font-size: 30px;
      letter-spacing: -0.8px;
    }
  }

  &__ordering-form {
    @media (max-width: 800px) {
      margin-top: 28px;
    }
    @media (min-width: 801px) {
      margin-top: 40px;
    }
  }

  &__empty {
    flex-direction: column;
    justify-content: center;
    @media (max-width: 800px) {
    }
    @media (min-width: 801px) {
      display: flex;
      min-height: 400px;
    }
  }
  &__empty:not(.is-active) {
    display: none;
  }

  &__empty-icon {
    display: block;
    fill: #b0b0b0;
    flex: 0 0 auto;
    height: 135px;
    margin: 0 auto;
    width: 135px;
  }

  &__empty-title {
    color: #b0b0b0;
    flex: 0 0 auto;
    font-size: 16px;
    letter-spacing: .2px;
    margin-left: auto;
    margin-right: auto;
    // margin-top: 35px;
    margin-top: 15px;
    text-align: center;
    width: 100%;
  }
}