.small-cart {
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  font-size: 0;
  margin-left: 10px;
  text-align: right;
  white-space: nowrap;
  width: 165px;
  @media all and (max-width: @tablet) {
    width: auto;
  }
  @media all and (max-width: 768px) {
    margin: 0;
  }

  &__compare {
    color: rgba(13, 49, 71, 1);
    display: inline-block;
    height: 20px;
    position: relative;
    vertical-align: bottom;
    width: 18px;
    &:after,
    &:before {
      background-color: currentColor;
      border-radius: 4px;
      bottom: 0;
      content: '';
      position: absolute;
      width: 4px;
    }
    &:after {
      right: 0;
      height: 20px;
    }
    &:before {
      left: 0;
      height: 8px;
    }
    &:hover {
      color: rgba(13, 49, 71, 0.7);
    }
    @media all and (max-width: @mobile) {
      height: 17px;
      width: 16px;
      &:after {
        height: 17px;
      }
      &:before {
        height: 7px;
      }
    }
  }

  &__compare-body {
    background-color: currentColor;
    border-radius: 4px;
    bottom: 0;
    display: block;
    height: 14px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    width: 4px;
    @media all and (max-width: @mobile) {
      height: 12px;
    }
  }

  &__compare--active &__count {
    display: block;
  }

  &__logo {
    display: inline-block;
    height: 20px;
    position: relative;
    vertical-align: bottom;
    margin-left: 25px;
    @media all and (max-width: @tablet) {
      margin-left: 25px;
    }
    @media all and (max-width: 768px) {
      height: 17px;
      margin-left: 20px;
      vertical-align: bottom;
    }
  }
  &__logo .icon {
    color: #0d3147;
    font-size: 1.3rem;
    @media all and (max-width: 768px) {
      font-size: 1rem;
    }
  }

  &__count {
    color: white;
    display: none;
    font: 12px 'Glober-Regular';
    min-width: 18px;
    height: 18px;
    line-height: 15px;
    padding: 2.5px 3px 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: -6px;
    transform: translateX(50%);
    z-index: 1;
    &:before {
      background-color: @red-main; // earlier color: #dd2a1b
      border-radius: 50%;
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: -10;
    }
  }
  &__logo--active &__count {
    display: block;
  }

  &__total-price {
    color: #565656;
    display: none;
    font-size: 13px;
    margin-left: 14px;
    max-width: 90px;
    min-width: 78px;
    overflow: hidden;
    text-align: right;
    vertical-align: bottom;
    text-overflow: ellipsis;
    @media all and (max-width: @tablet) {
      display: none;
    }
  }
  &__logo--active + &__total-price {
    display: inline-block;
  }
}