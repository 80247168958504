.catalog-category {
  font-family: "Glober-Regular";
  position: relative;

  &__header {
    align-items: center;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 25px;
    position: relative;
    &:before {
      background-color: #535353;
      content: '';
      height: 100%;
      left: 0;
      opacity: .6;
      position: absolute;
      top: 0;
      width: 100%;
    }
    @media (max-width: 900px) {
      height: 200px;
      margin-bottom: 44px;
      margin-left: -28px;
      margin-right: -28px;
    }
    @media (min-width: 901px) {
      height: 280px;
      margin-bottom: 60px;
    }
    @media all and (max-width: 768px) {
      margin-left: -10px;
      margin-right: -10px;
    }
  }

  &__title {
    color: #fff;
    font-family: "Glober-Regular";
    font-weight: 600;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    @media (max-width: 900px) {
      font-size: 14px;
      line-height: 1.65;
    }
    @media (min-width: 901px) {
      font-size: 20px;
      letter-spacing: 0.7px;
      line-height: 2.14;
    }
  }

  &__supplier {
    & + & {
      margin-top: 52px;
    }
  }

  &__supplier-logo {
    @media (max-width: 900px) {
      margin-bottom: 32px;
    }
    @media (min-width: 901px) {
      margin-bottom: 44px;
    }
  }

  &__supplier-description {
    color: #565656;
    font-family: "Glober-Regular";
    @media (max-width: 900px) {
      font-size: 14px;
      letter-spacing: -0.3px;
      line-height: 1.71;
    }
    @media (min-width: 901px) {
      font-size: 16px;
      letter-spacing: -0.4px;
      line-height: 1.75;
    }
  }

  &__products-outer {
    margin-top: 60px;
    padding-bottom: 46px;
    position: relative;
  }

  &__products-inner {
    margin: 0 -200px 0 -280px;
    overflow: hidden;
    position: relative;
    &::before,
    &::after {
      content: '';
      height: 100%;
      position: absolute;
      top: 0;
      z-index: 1;
    }
    &::before {
      background-image: linear-gradient(to left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .59) 20%, rgba(255, 255, 255, 1) 100%);
      left: 0;
      width: 280px;
    }
    &::after {
      background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .59) 20%, rgba(255, 255, 255, 1) 100%);
      right: 0;
      width: 200px;
    }
  }

  &__products {
    align-items: flex-start;
    display: flex;
    margin-top: 60px;
    overflow-x: auto;
    overflow-y: visible;
    padding-bottom: 46px;
  }
  &__products-inner &__products {
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 280px;
    &::after {
      content: '';
      display: block;
      flex: 0 0 auto;
      height: 1px;
      width: 200px;
    }
  }

  &__products-scrolltrack {
    border-radius: 2px;
    border: 1px solid #d8d8d8;
    bottom: 0;
    height: 7px;
    left: 0;
    overflow: hidden;
    position: absolute;
    width: 100%;
  }

  &__products-scrollbar {
    background-color: #d8d8d8;
    height: 100%;
    position: relative;
  }

  &__products-direction-button {
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid currentColor;
    color: #565656;
    display: none;
    height: 46px;
    position: absolute;
    top: 152px;
    transition: all .3s ease;
    width: 46px;
    z-index: 1;
    &:hover {
      background-color: #dd2a1b;
      border-color: #dd2a1b;
      color: #fff;
    }
  }
  &__products-direction-button--previous {
    left: -23px;
  }
  &__products-direction-button--next {
    right: -23px;
  }
  &__products-outer &__products-direction-button {
    display: block;
  }

  &__products-direction-button-icon {
    bottom: 0;
    display: block;
    fill: currentColor;
    height: 19px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 19px;
  }
  &__products-direction-button--previous &__products-direction-button-icon {
    transform: scaleX(-1);
  }

  &__product {
    & + & {
      @media (max-width: 900px) {
        margin-left: 25px;
      }
      @media (min-width: 901px) {
        margin-left: 33px;
      }
    }
  }

  &__products-more-link {
    left: 50%;
    margin-top: 60px;
    position: relative;
    transform: translateX(-50%);
  }
}