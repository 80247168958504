.events {
  margin: 65px 0 90px;;
  &__list {
    display: flex;
    // justify-content: space-between;

    @media all and (max-width: @mobile) {
      display: inline-block;
      width: 100%;
    }
  }
  &__item {
    position: relative;
    max-width: 370px;
    min-width: 240px;
    background: white;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    transition: box-shadow 300ms;
    margin-right: 25px;
    &:last-child {
      margin-right: 0;
    }
    &:after {
      content: '';
      position: absolute;
      height: 1px;
      width: 100%;
      left: 0;
      bottom: 60px;
      background: #f2f2f2;
    }
    &:hover {
      box-shadow: 0 0 27px rgba(0, 0, 0, 0.08);
      transition: box-shadow 300ms;
    }
    @media all and (max-width: 1180px) {
      margin-right: 5px;
      &:last-child {
        margin-right: 0;
      }
    }
    @media all and (max-width: @mobile) {
      min-width: 100%;
      margin-top: 28px;
      &:first-child {
        margin-top: 0;
      }
    }

  }
  &__item:hover &__img:after {
    background: rgba(83, 83, 83, 0);
    transition: background-color 300ms;
  }
  &__backtime {
    position: absolute;
    top: 20px;
    left: 20px;
    height: 24px;
    background: @red-main;
    color: #ffffff;
    font-size: 11px;
    text-transform: lowercase;
    padding: 7px 10px;
    border-radius: 12px;
    z-index: 1;
  }
  &__img {
    position: relative;
    height: 233px;
    width: 100%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &:after {
      position: absolute;
      content: '';
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(83, 83, 83, 0.45);
      transition: background-color 300ms;
    }
  }
  
  &__description {
    padding: 20px 25px 0;
    height: 296px;
    @media all and (max-width: @tablet) {
      padding: 20px 20px 25px;
    }
  }
  &__header {
    margin: 0 0 16px;
    font: 16px 'Glober-SemiBold';
    color: #535353;
    line-height: 21px;
    max-height: 63px;
    min-height: 63px;
    overflow: hidden;
  }
  &__header-link {
    color: inherit;
    &::before {
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 2;
    }
  }
  &__text {
    font-size: 12px;
    line-height: 20px;
    max-height: 122px;
    min-height: 122px;
    overflow: hidden;
    margin-bottom: 35px;
    text-overflow: ellipsis;
  }
  &__icon-list {
    display: flex;
    justify-content: space-between;
  }
  &__icon-item {
    font-size: 12px;
    color: #535353;
    display: flex;
    align-items: center;
  }
  &__icon-item-clock {
    @media all and (max-width: @tablet) {
      display: none;
    }
    @media all and (max-width: @mobile) {
      display: flex;
      align-items: center;
    }
    @media all and (max-width: 390px) {
      display: none;
    }
  }
  &__icon {
    font-size: 1rem;
    margin-right: 8px;
    color: #535353;
    &--red {
      color: #dd2a1b;
    }
    @media all and (max-width: @tablet) {
      margin-right: 5px;
    }
  }
  &__bottom {
    margin: 65px 0 0 0;
    text-align: center;
  }
  &__all-link {
    background: @red-main;
    border-radius: 25px;
    color: white;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    height: 50px;
    padding: 18px 30px;   // earlier 18px 24px
    transition: background-color 300ms;
    vertical-align: middle;
    @media all and (max-width: 800px) {
      height: 36px;
      font-size: 12px;
      padding: 12px 20px;
    }
    &:hover {
      background: @red-main-hover;
      transition: background-color 300ms;
    }
  }
  &__all-link-icon {
    float: right;
    font-size: 0.9rem;
    left: 0;
    margin-left: 10px;
    position: relative;
    transition: left 200ms;
    vertical-align: middle;
  }
  &__all-link:hover &__all-link-icon {
    left: 5px;
    transition: left 200ms;
  }
}