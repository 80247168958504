.study {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  font-family: "Glober-Regular";
  margin: 0 auto;
  @media (max-width: 800px) {
    padding-bottom: 48px;
    padding-top: 32px;
  }
  @media (min-width: 801px) {
    padding-bottom: 100px;
    padding-top: 50px;
  }
  @media (max-width: 1240px) {
    max-width: 1200px;
  }
  @media (min-width: 1241px) {
    max-width: 1240px;
  }
  @media (max-width: @tablet) {
    padding-left: 28px;
    padding-right: 28px;
  }
  @media (max-width: 768px) {
    padding-left: 10px;
    padding-right: 10px;
  }
  @media (min-width: 769px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  &__breadcrumbs {
    background-color: #f5f5f5;
    flex: 0 0 auto;
    margin-bottom: 48px;
    width: 100%;
    @media (max-width: 800px) {
      display: none;
    }
    @media (min-width: 1241px) {
      padding: 0 20px;
    }
  }

  &__title {
    background-color: #f5f5f5;
    color: #202020;
    flex: 1 1 0;
    font-weight: 400;
    @media (max-width: 800px) {
      font-size: 30px;
      line-height: 1.1;
      letter-spacing: -0.8px;
    }
    @media (min-width: 801px) {
      font-size: 44px;
      letter-spacing: -1.1px;
    }
    @media (min-width: 1241px) {
      padding-left: 20px;
    }
  }

  &__calendar-input-toggler,
  &__list-input-toggler {
    display: none;
  }

  

  &__view-togglers {
    align-items: flex-start;
    display: flex;
    flex: 0 0 auto;
    margin-left: 20px;
  }

  &__view-toggler {
    border: 1px solid #b0b0b0;
    color: #b0b0b0;
    cursor: pointer;
    flex: 0 0 auto;
    position: relative;
    @media (max-width: 800px) {
      height: 35px;
      width: 56px;
    }
    @media (min-width: 801px) {
      height: 40px;
      width: 66px;
    }
  }
  &__view-toggler--left {
    border-radius: 5px 0 0 5px;
  }
  &__view-toggler--right {
    border-radius: 0 5px 5px 0;
    margin-left: -1px;
  }
  &__calendar-input-toggler:checked ~ &__view-togglers &__view-toggler--calendar,
  &__list-input-toggler:checked ~ &__view-togglers &__view-toggler--list {
    color: #000;
  }

  &__view-toggler-icon {
    bottom: 0;
    fill: currentColor;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    transition: fill .3s ease;
    &:hover {
      fill: #000;
    }
  }
  &__view-toggler-icon--calendar {
    @media (max-width: 800px) {
      height: 18px;
      width: 18px;
    }
    @media (min-width: 801px) {
      height: 22px;
      width: 22px;
    }
  }
  &__view-toggler-icon--list {
    @media (max-width: 800px) {
      height: 17px;
      width: 17px;
    }
    @media (min-width: 801px) {
      height: 20px;
      width: 20px;
    }
  }

  &__calendar {
    flex: 0 0 auto;
    width: 100%;
    @media (max-width: 800px) {
      margin-top: 44px;
    }
    @media (min-width: 801px) {
      margin-top: 68px;
    }
  }
  &__calendar-input-toggler:not(:checked) ~ &__calendar {
    // animation: smooth-out 1.2s ease-in-out;
    display: none;
  }

  &__calendar-input-toggler:checked ~ &__calendar {
    animation: smooth 1.2s ease-in-out;
  }

  &__events {
    margin-top: 40px;
    @media (min-width: 1241px) {
      padding: 0 20px;
    }
  }
  &__list-input-toggler:not(:checked) ~ &__events {
    // animation: smooth-out 1.2s ease-in-out;
    display: none;
  }

  &__list-input-toggler:checked ~ &__events {
    animation: smooth 1.2s ease-in-out;
  }

  &__events-filter-input {
    display: none;
  }

  &__events-filters {
    align-items: flex-start;
    border-bottom: 1px solid fade(#2c2c2c, 8%);
    display: flex;
    margin: 0 -25vw;
    padding: 0 25vw;

    // position: absolute;
    // margin: 0 -67% 0 -72%;
    // padding: 0 67% 0 -72%;
    // margin: 0 -9999px;
    // padding: 0 9999px;
  }

  &__events-filter-button {
    color: #878787;
    cursor: pointer;
    flex: 0 0 auto;
    border-bottom: 2px solid transparent;
    text-transform: uppercase;
    white-space: nowrap;
    position: relative;
    top: 1px;
    user-select: none;
    & + & {
      margin-left: 47px;
    }
    @media (max-width: 800px) {
      font-size: 12px;
      line-height: 36px;
    }
    @media (min-width: 801px) {
      font-size: 15px;
      line-height: 40px;
    }
  }
  &__events-filter-input--near:checked ~ &__events-filters &__events-filter-button--near,
  &__events-filter-input--archive:checked ~ &__events-filters &__events-filter-button--archive {
    border-bottom-color: #dd2a1b;
    color: #000;
  }

  &__events-filter-input--near:not(:checked) ~ &__events-list--near,
  &__events-filter-input--archive:not(:checked) ~ &__events-list--archive {
    display: none;
  }

  &__events-filter-input--near:checked ~ &__events-list--near,
  &__events-filter-input--archive:checked ~ &__events-list--archive {
    animation: smooth .6s ease-in-out;
  }

  &__events-list {
    align-items: flex-start;
    flex-wrap: wrap;
    padding-bottom: 56px;
    position: relative;
    z-index: 1;
    min-width: 1000px;
    &:before {
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
    @media (max-width: 800px) {
      margin-top: 44px;
    }
    @media (min-width: 801px) {
      display: flex;
      margin-left: -24px;
      padding-top: 32px;
    }
  }

  &__events-list-item {
    flex: 0 0 auto;
    position: relative;
    @media (max-width: 800px) {
      margin-left: auto;
      margin-right: auto;
      & + & {
        margin-top: 28px;
      }
    }
    @media (min-width: 801px) {
      margin: 24px 0 0 24px;
    }
    @media (min-width: 801px) and (max-width: 1200px) {
      width: ~"calc(50% - 24px)";
    }
    @media (min-width: 1201px) {
      width: ~"calc((100% - 72px) / 3)";
    }
  }

  &__events-more-link {
    border-radius: 50%;
    bottom: 0;
    font-size: 0;
    height: 10px;
    left: 24px;
    margin: 0 auto;
    position: absolute;
    right: 0;
    text-indent: -9999px;
    user-select: none;
    width: 10px;
    z-index: -1;
    &:before {
      animation-fill-mode: both;
      animation: pulse 1.8s infinite ease-in-out;
      animation-delay: -0.32s;
      border-radius: 50%;
      bottom: 0;
      content: '';
      height: 100%;
      margin-right: 12px;
      position: absolute;
      right: 100%;
      transform-origin: 50% 100%;
      width: 100%;
    }
    &:after {
      animation-fill-mode: both;
      animation: pulse 1.8s infinite ease-in-out;
      border-radius: 50%;
      bottom: 0;
      content: '';
      height: 100%;
      left: 100%;
      margin-left: 12px;
      position: absolute;
      transform-origin: 50% 100%;
      width: 100%;
    }
    &:not(.is-active) {
      opacity: 0;
    }
  }

  &__events-more-link-body {
    animation-fill-mode: both;
    animation: pulse 1.8s infinite ease-in-out;
    animation-delay: -0.16s;
    border-radius: 50%;
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    transform-origin: 50% 100%;
    width: 100%;
  }

  @-webkit-keyframes pulse {
    0%,
    80%,
    100% {
      background-color: fade(#b0b0b0, 35%);
      transform: scale(.8);
    }
    40% {
      background-color: #b0b0b0;
      transform: scale(1.0);
    }
  }
  @keyframes pulse {
    0%,
    80%,
    100% {
      background-color: fade(#b0b0b0, 35%);
      transform: scale(.8);
    }
    40% {
      background-color: #b0b0b0;
      transform: scale(1.0);
    }
  }
}



@keyframes smooth {
  0% {
    opacity: 0;
    display: block; 
  }

  50% {
    opacity: 1;
  }

  99% {
    
  }
}


@keyframes smooth-out {
  0% {
    opacity: 1;
    display: block; 
  }

  50% {
    opacity: 0;
    display: none;
  }

  99% {
    
  }
}