.error404 {
  align-items: flex-start;
  flex-wrap: wrap;
  font-family: "Glober-Regular";
  @media (max-width: 800px) {
    padding-bottom: 47px;
    padding-top: 30px;
  }
  @media (min-width: 801px) {
    display: flex;
    padding-bottom: 66px;
    padding-top: 60px;
  }

  &__logo {
    color: #000;
    flex: 1 0 auto;
    font-family: 'Glober-Light';
    font-weight: 400;
    line-height: 1;
    @media (max-width: 800px) {
      border-bottom: 1px solid fade(#000, 8%);
      font-size: 120px;
      // padding-bottom: 20px;
      text-align: center;
    }
    @media (min-width: 801px) {
      border-right: 1px solid fade(#000, 8%);
      font-size: 166px;
      height: 133px;
      letter-spacing: 2.5px;
      max-width: 500px;
      overflow: hidden;
      padding-right: 50px;
      text-align: right;
    }
  }

  &__description {
    flex: 1 1 0;
    @media (max-width: 800px) {
      margin-top: 35px;
      text-align: center;
    }
    @media (min-width: 801px) {
      padding-left: 52px;
    }
  }

  &__title {
    color: #000;
    font-weight: 400;
    line-height: 1.2;
    @media (max-width: 800px) {
      font-size: 30px;
      letter-spacing: .5px;
    }
    @media (min-width: 801px) {
      font-size: 44px;
      letter-spacing: .7px;
    }
  }

  &__notification {
    color: #565656;
    letter-spacing: .2px;
    line-height: 1.7;
    @media (max-width: 800px) {
      font-size: 14px;
      margin-top: 23px;
    }
    @media (min-width: 801px) {
      font-size: 16px;
      margin-top: 25px;
      max-width: 370px;
    }
  }

  &__categories {
    flex: 0 0 auto;
    margin-top: 60px;
    @media (min-width: 801px) {
      width: 100%;
    }
  }
}