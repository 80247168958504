.category {
  font-size: 0;
  overflow-x: auto;
  @media all and (max-width: @tablet) {
    margin-left: -28px;
    margin-right: -28px;
    padding-bottom: 40px;
  }
  @media all and (max-width: 768px) {
    margin-left: -10px;
    margin-right: -10px;
  }
  &__list {
    min-width: 1120px;
  }
  &__item {
    color: #000;
    position: relative;
    display: inline-block;
    width: 25%;
    min-width: 280px;
    height: 501px;
    overflow: hidden;
    cursor: pointer;
    background: #ffffff;
    &:after {
      position: absolute;
      content: '';
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(83,83,83,0.7);
      transition: background-color 300ms;
    }
    @media all and (max-width: 768px) {
      width: 280px;
      height: 290px;
    }
  }
  &__item:hover:after {
    background: rgba(83,83,83,0);
    transition: background-color 300ms;
  }
  &__item:hover &__description {
    height: 148px;
    padding: 15px 20px;
    opacity: 1;
    transition: opacity  0ms 190ms;
    box-shadow: 0 0 1.8px rgba(0, 0, 0, 0.1);
  }
  &__item:hover &__img {
    bottom: 140px;
    transition: bottom 300ms;
  }
  &__img {
    position: absolute;
    bottom: 0;
    transition: bottom 300ms;
    height: 100%;
    @media all and (max-width: 768px) {
      left: 0;
      right: 0;
    }
    
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      @media all and (max-width: 768px) {
        height: 100%;
        width: auto;
        display: block;
        margin: 0 auto;
      }
    }
  }
  &__item:hover &__overlay {
    opacity: 0;
    transition: opacity 200ms;
  }
  &__overlay {
    position: absolute;
    top: 50%;
    bottom: 0;
    right: 0;
    left: 0;
    height: 38px;
    overflow: hidden;
    font: 16px 'Glober-SemiBold';
    z-index: 9;
    color: white;
    text-transform: uppercase;
    text-align: center;
    opacity: 1;
    transition: opacity 200ms;
  }
  &__description {
    position: absolute;
    bottom: 0;
    height: 0;
    width: 100%;    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 0;
    padding: 0;
    background: white;
    opacity: 0;
  }
  &__header {
    text-transform: uppercase;
    font: 15px 'Glober-SemiBold';
    // white-space: nowrap;
    max-height: 36px;
    overflow: hidden;
    margin-top: 5px; 
    &_with-second-line {
      margin-top: 5px;
    }
  }
  &__text {
    font: 14px 'Glober-Book';
    color: #878787;
    line-height: 18px;
    max-height: 66px;
    overflow: hidden;
    margin-bottom: 30px;
    &-with-second-line {
      margin-bottom: 10px;
    }
    &:first-letter {
      text-transform: uppercase;
    }
  }

}

.nicescroll-rails {
  border: 1px solid #b0b0b0;
  border-radius: 8px;
}
// .nicescroll-rails.nicescroll-rails-hr {
//   // width: 80% !important;

// }