.product-detailed {
  align-items: flex-start;
  flex-wrap: wrap;
  font-family: "Glober-Regular";
  padding-top: 22px;
  @media (max-width: 800px) {
    border-top: 1px solid fade(#000, 8%);
  }
  @media (min-width: 801px) {
    display: flex;
  }

  &__breadcrumbs {
    padding-bottom: 31px;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: -9999px;
      right: -9999px;
      border-bottom: 1px solid fade(#2c2c2c, 8%);
    }
    @media (max-width: 800px) {
      display: none;
    }
  }

  &__gallery {
    flex: 0 0 auto;
    position: relative;
    @media (max-width: 900px) {
      margin-bottom: 25px;
      padding-bottom: 30px;
      &:before {
        border-bottom: 1px solid fade(#000, 8%);
        bottom: 0;
        content: '';
        left: -9999px;
        position: absolute;
        right: -9999px;
      }
    }
    @media (min-width: 901px) {
      border-right: 1px solid fade(#2c2c2c, 8%);
      height: 578px;
      width: 49.1%;
    }
  }

  &__gallery .slick-list {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &__gallery .slick-track {
    height: 100%;
  }

  &__gallery-previous-button,
  &__gallery-next-button {
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid @red-main; //earlier color: #dd2a1b
    position: absolute;
    @media (max-width: 900px) {
      height: 32px;
      margin-top: ~"calc(47% - 16px)";
      top: 0;
      width: 32px;
    }
    @media (min-width: 901px) {
      height: 46px;
      width: 46px;
    }
  }
  &__gallery-previous-button {
    left: 0;
    @media (min-width: 901px) {
      display: none !important;
    }
  }
  &__gallery-next-button {
    @media (max-width: 900px) {
      right: 0;
    }
    @media (min-width: 901px) {
      right: -23px;
      top: ~"calc(45% - 23px)";
    }
  }

  &__gallery-previous-button-icon,
  &__gallery-next-button-icon {
    bottom: 0;
    display: block;
    fill: @red-main; //earlier color: #dd2a1b
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    @media (max-width: 900px) {
      height: 14px;
      width: 14px;
    }
    @media (min-width: 901px) {
      height: 19px;
      width: 19px;
    }
  }
  &__gallery-previous-button-icon {
    transform: scaleX(-1);
  }

  &__gallery-items {
    &:before {
      content: '';
      display: block;
      padding-top: 95%;
    }
    @media (max-width: 900px) {
      margin-bottom: 20px;
    }
    @media (min-width: 901px) {
      margin: 20px 20px 0 -20px;
    }
  }

  &__gallery-item {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    &:nth-child(n+2) {
      display: none;
    }
  }
  &__gallery-items.slick-slider &__gallery-item {
    display: block;
    position: relative;
  }

  &__gallery-items.slick-slider &__gallery-item-image,
  &__gallery-item-image {
    display: none;
  }

  &__gallery-thumbnails {
    align-items: flex-start;
    @media (max-width: 900px) {
      display: flex;
      height: 64px;
      overflow-x: auto;
    }
    @media (min-width: 901px) {
      display: block;
      left: 0;
      position: absolute;
      top: 20px;
      width: 70px;
    }
  }

  &__gallery-thumbnail {
    border-radius: 5px;
    flex: 0 0 auto;
    overflow: hidden;
    padding: 2px;
    position: relative;
    &:before {
      border-radius: 5px;
      border: 1px solid rgba(38, 38, 38, 0.08);
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
    &.active {
      &:before {
        border: 2px solid @red-main; //earlier color: #dd2a1b
      }
    }
    @media (max-width: 900px) {
      height: 64px;
      width: 62px;
      & + & {
        margin-left: 8px;
      }
    }
    @media (min-width: 901px) {
      height: 70px;
      & + & {
        margin-top: 10px;
      }
    }
  }

  &__gallery-thumbnail-image {
    display: block;
    height: auto;
    width: 100%;
  }

  &__info {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    flex: 0 0 auto;
    &:after {
      flex: 0 0 auto;
      margin-bottom: auto;
      width: 100%;
    }
    @media (max-width: 900px) {
      padding-bottom: 40px;
    }
    @media (min-width: 901px) {
      height: 578px;
      padding-left: 125px;
      width: 50.9%;
      &:after {
        content: '';
      }
    }
  }

  &__title {
    color: #3a3a3a;
    flex: 0 0 auto;
    width: 100%;
    @media (max-width: 900px) {
      font-size: 17px;
      font-weight: 600;
      letter-spacing: 0.4px;
      line-height: 1.38;
      margin-bottom: 21px;
    }
    @media (min-width: 901px) {
      font-size: 25px;
      letter-spacing: 0.6px;
      line-height: 1.24;
      margin-bottom: 24px;
      margin-top: auto;
    }
  }

  &__property {
    color: #878787;
    flex: 0 0 auto;
    width: 100%;
    @media (max-width: 900px) {
      font-size: 12px;
      letter-spacing: 0.3px;
      line-height: 1.46;
      & + & {
        margin-top: 10px;
      }
    }
    @media (min-width: 901px) {
      font-size: 14px;
      letter-spacing: 0.4px;
      line-height: 1.64;
      & + & {
        margin-top: 12px;
      }
    }
  }

  &__info-description {
    color: #565656;
    flex: 0 0 auto;
    width: 100%;
    @media (max-width: 900px) {
      font-size: 12px;
      line-height: 1.77;
      margin-top: 22px;
    }
    @media (min-width: 901px) {
      font-size: 16px;
      line-height: 1.75;
      margin-top: 34px;
    }
  }

  &__price {
    color: #3a3a3a;
    flex: 0 0 auto;
    font-family: "Glober-Book";
    font-weight: 400;
    width: 100%;
    @media (max-width: 900px) {
      font-size: 17px;
      font-weight: 600;
      line-height: 1.03;
      margin: 37px 0 18px;
    }
    @media (min-width: 901px) {
      font-size: 25px;
      font-weight: 600;
      line-height: 0.92;
      margin-top: 58px;
    }
  }

  &__cart-button {
    background-color: @red-main; // earlier color: #dd2a1b
    border: 1px solid @red-main; // earlier color: #dd2a1b
    color: #fff;
    flex: 0 0 auto;
    padding: 0 10px;
    text-align: center;
    transition: background-color .3s ease, color .3s ease;
    @media (max-width: 900px) {
      border-radius: 15px;
      font-size: 12px;
      font-weight: 600;
      height: 31px;
      line-height: 29px;
      width: 98px;
    }
    @media (min-width: 901px) {
      border-radius: 20px;
      font-size: 15px;
      font-weight: 600;
      height: 40px;
      line-height: 36px;
      padding-top: 2px;
      width: 128px;
    }
  }
  &__cart-button:hover,
  &__cart-button--active {
    background-color: #fff;
    color: @red-main; // earlier color: #dd2a1b
    transition: background-color .3s ease, color .3s ease;
  }

  &__compare-button {
    border: 1px solid #565656;
    color: #565656;
    flex: 0 0 auto;
    padding: 0 10px;
    text-align: center;
    transition: background-color .3s ease, color .3s ease;
    @media (max-width: 900px) {
      border-radius: 15px;
      font-size: 12px;
      font-weight: 600;
      height: 31px;
      line-height: 29px;
      margin-left: 12px;
      width: 98px;
    }
    @media (min-width: 901px) {
      border-radius: 20px;
      font-size: 15px;
      font-weight: 600;
      height: 40px;
      line-height: 36px;
      margin-left: 16px;
      padding-top: 2px;
      width: 128px;
    }
  }
  &__compare-button:hover,
  &__compare-button--active {
    background-color: #565656;
    color: #fff;
    transition: background-color .3s ease, color .3s ease;
  }

  &__secondary-info {
    flex: 0 0 auto;
    position: relative;
    width: 100%;
    z-index: 1;
    &:before {
      background-color: #f5f5f5;
      content: '';
      height: 100%;
      left: -9999px;
      position: absolute;
      right: -9999px;
      top: 0;
      z-index: -1;
    }
  }

  &__tab-input-toggler {
    display: none;
  }

  &__tabs-buttons {
    display: block;
    margin: 0 -9999px;
    overflow: hidden;
    padding: 0 9999px;
    position: relative;
    &:before {
      border-bottom: 1px solid fade(#2c2c2c, 8%);
      bottom: 0;
      content: '';
      left: -9999px;
      position: absolute;
      right: -9999px;
      z-index: -1;
    }
    @media (max-width: 800px) {
      height: 60px;
    }
    @media (min-width: 801px) {
      height: 54px;
    }
  }

  &__tabs-buttons-body {
    align-items: flex-start;
    display: flex;
    height: 200px;
    overflow-x: auto;
  }

  &__tab-button {
    border-bottom: 2px solid transparent;
    color: #878787;
    cursor: pointer;
    flex: 0 0 auto;
    text-transform: uppercase;
    transition: border-bottom-color .3s ease, color .3s ease;
    white-space: nowrap;
    @media (max-width: 800px) {
      font-size: 13px;
      height: 60px;
      line-height: 46px;
      padding-top: 12px;
      & + & {
        margin-left: 40px;
      }
    }
    @media (min-width: 801px) {
      font-size: 15px;
      height: 54px;
      line-height: 46px;
      padding-top: 8px;
      & + & {
        margin-left: 43px;
      }
    }
  }
  &__tab-input-toggler:nth-of-type(1):checked ~ &__tabs-buttons &__tab-button:nth-child(1),
  &__tab-input-toggler:nth-of-type(2):checked ~ &__tabs-buttons &__tab-button:nth-child(2),
  &__tab-input-toggler:nth-of-type(3):checked ~ &__tabs-buttons &__tab-button:nth-child(3),
  &__tab-input-toggler:nth-of-type(4):checked ~ &__tabs-buttons &__tab-button:nth-child(4),
  &__tab-input-toggler:nth-of-type(5):checked ~ &__tabs-buttons &__tab-button:nth-child(5) {
    border-bottom-color: @red-main; //earlier color: #dd2a1b
    color: #000;
  }

  &__description {
    @media (max-width: 800px) {
      padding-bottom: 56px;
      padding-top: 46px;
    }
    @media (min-width: 801px) {
      padding-bottom: 78px;
      padding-top: 67px;
    }
  }
  &__tab-input-toggler:nth-of-type(1):not(:checked) ~ &__description {
    display: none;  
    // opacity: 0;
    // animation: smooth .6s ease-in-out;
  }

  &__tab-input-toggler:nth-of-type(1):checked ~ &__description {
    
    // opacity: 0;
    animation: smooth .6s ease-in-out;
  }



  &__description-section {
    color: #878787;
    position: relative;
    & + & {
      margin-top: 4.5em;
    }
    @media (max-width: 800px) {
      font-size: 13px;
      line-height: 1.77;
    }
    @media (min-width: 801px) {
      font-size: 16px;
      line-height: 1.75;
    }
  }

  &__description-section-title {
    color: #202020;
    font-weight: 400;
    line-height: 1.2;
    margin-bottom: 1em;
    @media (max-width: 800px) {
      font-size: 25px;
      letter-spacing: -0.6px;
    }
    @media (min-width: 801px) {
      font-size: 33px;
      letter-spacing: -0.8px;
    }
  }

  &__description-section-title-warning-icon {
    display: inline-block;
    fill: @red-main; // earlier color #dd2a1b
    @media (max-width: 800px) {
      height: 16px;
      margin-left: 6px;
      width: 16px;
    }
    @media (min-width: 801px) {
      height: 27px;
      margin-left: 15px;
      width: 27px;
    }
  }

  &__description-titled-list {
    position: relative;
  }
  &__description-section-spoiler-switcher:not(:checked) + &__description-titled-list {
  }

  &__description-titled-list li {
    position: relative;
    &:before {
      background-color: #000;
      content: '';
      height: 2px;
      left: 0;
      position: absolute;
      top: 9px;
    }
    @media (max-width: 800px) {
      padding-left: 33px;
      &:before {
        width: 15px;
      }
    }
    @media (min-width: 801px) {
      padding-left: 43px;
      &:before {
        width: 21px;
      }
    }
  }
  &__description-titled-list li + li {
    margin-top: 37px;
  }
  &__description-section-spoiler-switcher:not(:checked) + &__description-titled-list li {
    &:nth-child(4) {
      height: 180px;
      overflow: hidden;
      &:after {
        background-image: linear-gradient(0deg, #f5f5f5 0%, #f5f5f5 30%, fade(#f5f5f5, 80%) 100%);
        bottom: 0;
        content: '';
        height: 180px;
        left: 0;
        position: absolute;
        width: 100%;
      }
    }
    &:nth-child(n+5) {
      display: none;
    }
  }

  &__description-titled-list h4 {
    color: #3a3a3a;
    font-family: 'Glober-Book';
    margin-bottom: 1.2em;
    @media (max-width: 800px) {
      font-size: 14px;
      line-height: 1.8;
    }
    @media (min-width: 801px) {
      font-size: 16px;
      line-height: 1.6;
    }
  }

  &__description-section-spoiler-button {
    border-radius: 50%;
    border: 1px solid #000;
    bottom: 0;
    cursor: pointer;
    display: block;
    height: 37px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    transform: rotate(90deg);
    width: 37px;
  }
  &__description-section-spoiler-switcher:checked ~ &__description-section-spoiler-button {
    display: none;
  }

  &__description-section-spoiler-button-icon {
    bottom: 0;
    fill: #000;
    height: 14px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 14px;
  }

  &__video-review {
    max-width: 780px;
    position: relative;
    &:before {
      content: '';
      display: block;
    }
  }

  &__video-review iframe {
    height: 100% !important;
    left: 0;
    position: absolute;
    top: 0;
    width: 100% !important;
  }

  &__characteristics {
    @media (max-width: 800px) {
      padding-bottom: 56px;
      padding-top: 46px;
    }
    @media (min-width: 801px) {
      padding-bottom: 78px;
      padding-top: 67px;
    }
  }
  &__tab-input-toggler:nth-of-type(2):not(:checked) ~ &__characteristics {
    display: none;
  }

  &__tab-input-toggler:nth-of-type(2):checked ~ &__characteristics {
    animation: smooth .6s ease-in-out;
  }

  &__characteristics-section {
    & + & {
      @media (max-width: 800px) {
        margin-top: 48px;
      }
      @media (min-width: 801px) {
        margin-top: 64px;
      }
    }
  }

  &__characteristics-section-title {
    color: #565656;
    font-weight: 600;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    @media (max-width: 800px) {
      font-size: 15px;
      line-height: 1.67;
      margin-bottom: 30px;
    }
    @media (min-width: 801px) {
      font-size: 17px;
      line-height: 1.47;
      margin-bottom: 43px;
    }
  }

  &__characteristics-table {
    color: #878787;
    @media (max-width: 800px) {
      display: block;
      font-size: 13px;
      line-height: 2;
    }
    @media (min-width: 801px) {
      font-size: 16px;
      line-height: 1.63;
    }
  }

  &__characteristics-table-row {
    @media (max-width: 800px) {
      display: block;
      & + & {
        margin-top: 30px;
      }
    }
  }

  &__characteristics-table-title-cell {
    font-weight: 400;
    overflow: hidden;
    text-align: left;
    vertical-align: top;
    @media (max-width: 800px) {
      color: #000;
      display: block;
    }
    @media (min-width: 801px) {
      width: 388px;
    }
  }

  &__characteristics-table-title-dots {
    position: relative;
    &:after {
      border-bottom: 1px dotted #878787;
      bottom: 6px;
      content: '';
      margin-left: 3px;
      position: absolute;
      width: 9999px;
    }
    @media (max-width: 800px) {
      display: none;
    }
    @media (min-width: 801px) {
      display: inline;
    }
  }

  &__characteristics-table-cell {
    vertical-align: top;
    @media (max-width: 800px) {
      display: block;
    }
    @media (min-width: 801px) {
    }
  }


  &__files {
    flex: 0 0 auto;
    width: 100%;
    margin-bottom: 60px;
    @media (max-width: 800px) {
      padding-top: 30px;
      text-align: center;
    }
    @media (min-width: 801px) {
      padding-top: 60px;
    }
  }

  &__files-list {
    align-items: flex-start;
    flex-wrap: wrap;
    // display: -webkit-flex;
    // display: -moz-flex;
    // display: -ms-flex;
    // display: -o-flex;
    // display: flex;
    // justify-content: center;
    @media (max-width: 800px) {
      display: inline-block;
      text-align: left;
      vertical-align: top;
    }
    @media (min-width: 801px) {
      display: flex;
      margin-top: -64px;
      padding: 0 73px;
    }
  }

  &__file {
    flex: 0 0 auto;
    @media (max-width: 800px) {
      font-size: 13px;
      line-height: 1.63;
      & + & {
        margin-top: 26px;
      }
    }
    @media (min-width: 801px) {
      font-size: 16px;
      line-height: 1.75;
      margin-top: 64px;
      padding-left: 50px;
    }
    @media (min-width: 801px) and (max-width: 1090px) {
      width: 50%;
    }
    @media (min-width: 1091px) {
      width: ~"calc(100% / 3)";
    }
  }

  &__file-link {
    color: #878787;
    display: inline-block;
    white-space: nowrap;
  }

  &__file-icon {
    display: inline-block;
    fill: @red-main; // earlier color: #dd2a1b
    height: 30px;
    vertical-align: top;
    width: 30px;
  }

  &__file-text {
    display: inline-block;
    padding-top: 4px;
    vertical-align: top;
  }

  &__recomendations {
    flex: 0 0 auto;
    position: relative;
    width: 100%;
    top: -50px;
    margin-bottom: -50px;
    @media (max-width: 800px) {
      margin-top: 46px;
    }
    @media (min-width: 801px) {
      margin-top: 58px;
      padding: 0 60px;
      &:before {
        border-top: 1px solid fade(#303030, 8%);
        content: '';
        left: -9999px;
        position: absolute;
        right: -9999px;
        top: 0;
      }
    }
  }

  &__recomendations-title {
    color: #202020;
    font-size: 17px;
    font-weight: 400;
    letter-spacing: -0.4px;
    line-height: 1.45;
    margin-bottom: 21px;
    @media (min-width: 801px) {
      .visually-hidden-content();
    }
  }

  &__recomendations-items {
    position: relative;
    @media (max-width: 800px) {
      padding: 25px 0;
      &:before {
        border-top: 1px solid fade(#303030, 8%);
        content: '';
        left: -9999px;
        position: absolute;
        right: -9999px;
        top: 0;
      }
    }
    @media (min-width: 801px) {
      &:before,
      &:after {
        bottom: 0;
        content: '';
        height: 100%;
        position: absolute;
        top: 1px;
        width: 60px;
        z-index: 1;
      }
      &:before {
        background-image: linear-gradient(90deg, #fff 0%, #fff 10%, fade(#fff, 70%) 70%, transparent 100%);
        left: 0;
      }
      &:after {
        background-image: linear-gradient(-90deg, #fff 0%, #fff 10%, fade(#fff, 70%) 70%, transparent 100%);
        right: 0;
      }
    }
  }

  &__recomendations-items-list {
    align-items: flex-start;
    display: flex;
    @media (min-width: 801px) {
      overflow: hidden;
    }
  }

  &__recomendations-item {
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    position: relative;
    @media (max-width: 800px) {
      width: 100%;
    }
    @media (min-width: 801px) {
      height: 256px;
      padding: 0 60px;
      width: 50%;
      &:before {
        border-left: 1px solid fade(#303030, 8%);
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
      }
    }
  }
  &__recomendations-items &__recomendations-item {
    display: flex;
  }

  &__recomendation-item-cover {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
    flex: 0 0 auto;
    @media (max-width: 800px) {
      height: 86px;
      width: 62px;
    }
    @media (min-width: 801px) {
      height: 145px;
      width: 145px;
    }
  }

  img&__recomendation-item-cover-image {
    display: none;
  }

  &__recomendation-item-content {
    flex: 1 1 0;
    @media (max-width: 800px) {
      padding-left: 22px;
    }
    @media (min-width: 801px) {
      padding-left: 50px;
    }
  }

  &__recomendation-item-title {
    color: #3a3a3a;
    @media (max-width: 800px) {
      font-size: 13px;
      font-weight: 600;
      letter-spacing: 0.3px;
      line-height: 1.21;
    }
    @media (min-width: 801px) {
      font-size: 17px;
      font-weight: 600;
      letter-spacing: 0.4px;
      line-height: 1.24;
    }
  }

  &__recomendation-item-title-link {
    color: inherit;
  }

  &__recomendation-item-company {
    color: #878787;
    font-size: 12px;
    letter-spacing: 0.3px;
    line-height: 1.92;
    margin-top: 11px;
    @media (max-width: 800px) {
      display: none;
    }
  }

  &__recomendation-item-price {
    color: #565656;
    line-height: 1.44;
    @media (max-width: 800px) {
      font-size: 12px;
      letter-spacing: 0.3px;
      margin-top: 19px;
    }
    @media (min-width: 801px) {
      font-size: 16px;
      letter-spacing: 0.4px;
      margin-top: 28px;
    }
  }

  &__recomendations-slider-button {
    border-radius: 50%;
    display: block;
    position: absolute;
    z-index: 2;
    @media (max-width: 800px) {
      border: 1px solid #565656;
      height: 32px;
      top: 63px;
      width: 32px;
    }
    @media (min-width: 801px) {
      height: 36px;
      top: 50%;
      transform: translateY(-50%);
      width: 36px;
    }
  }
  &__recomendations-slider-button--previous {
    @media (max-width: 800px) {
      left: 0;
    }
    @media (min-width: 801px) {
      left: -6px;
    }
  }
  &__recomendations-slider-button--next {
    @media (max-width: 800px) {
      right: 0;
    }
    @media (min-width: 801px) {
      right: -6px;
    }
  }

  &__recomendations-slider-button-icon {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    @media (max-width: 800px) {
      fill: #565656;
      height: 12px;
      width: 12px;
    }
    @media (min-width: 801px) {
      fill: #b0b0b0;
      height: 24px;
      width: 24px;
    }
  }
  &__recomendations-slider-button-icon--previous {
    transform: scaleX(-1);
  }

  &__reviews {
    @media (max-width: 800px) {
      padding: 45px 0;
    }
    @media (min-width: 801px) {
      padding: 58px 0 80px 0;
    }
  }
  &__tab-input-toggler:nth-of-type(3):not(:checked) ~ &__reviews {
    display: none;
  }

  &__tab-input-toggler:nth-of-type(3):checked ~ &__reviews {
    animation: smooth .6s ease-in-out;
  }

  &__reviews-title {
    color: #202020;
    font-weight: 400;
    @media (max-width: 800px) {
      font-size: 25px;
      letter-spacing: -0.6px;
      margin-bottom: 42px;
    }
    @media (min-width: 801px) {
      font-size: 33px;
      letter-spacing: -0.8px;
      margin-bottom: 58px;
    }
  }

  &__reviews-title-quantity {
    display: inline;
  }

  &__reviews-list {
    max-width: 777px;
  }

  &__review {
    & + & {
      position: relative;
      @media (max-width: 800px) {
        padding-top: 83px;
        &:before {
          content: '';
          position: absolute;
          top: 40px;
          left: -9999px;
          right: -9999px;
          border-top: 1px solid #efefef;
        }
      }
      @media (min-width: 801px) {
        border-top: 1px solid #efefef;
        margin-top: 40px;
        padding-top: 42px;
      }
    }
  }
  &__reviews-list.is-compact &__review:nth-child(n+4) {
    display: none;
  }

  &__reviews-more-button {
    border-radius: 50%;
    border: 1px solid #000;
    cursor: pointer;
    display: block;
    font-size: 0;
    height: 37px;
    margin-top: 65px;
    text-indent: -9999px;
    transform: rotate(90deg);
    width: 37px;
  }
  &__reviews-more-button--reversed {
    transform: rotate(-90deg);
  }

  &__reviews-more-button-icon {
    bottom: 0;
    fill: #000;
    height: 14px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 14px;
  }

  &__reviews-form {
    max-width: 1018px;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      left: -9999px;
      right: -9999px;
      border-top: 1px solid #efefef;
    }
    @media (max-width: 800px) {
      padding-top: 94px;
      &:before {
        top: 54px;
      }
    }
    @media (min-width: 801px) {
      padding-top: 90px;
      &:before {
        top: 46px;
      }
    }
  }

  &__study {
    @media (max-width: 800px) {
      padding: 45px 0;
    }
    @media (min-width: 801px) {
      padding: 58px 0 80px 0;
    }
  }
  &__tab-input-toggler:nth-of-type(4):not(:checked) ~ &__study {
    display: none;
  }

  &__tab-input-toggler:nth-of-type(4):checked ~ &__study {
    animation: smooth .6s ease-in-out;
  }

  &__study-title {
    color: #202020;
    font-weight: 400;
    @media (max-width: 800px) {
      font-size: 25px;
      letter-spacing: -0.6px;
      margin-bottom: 42px;
    }
    @media (min-width: 801px) {
      font-size: 33px;
      letter-spacing: -0.8px;
      margin-bottom: 58px;
    }
  }

  &__study-list {
    align-items: flex-start;
    @media (min-width: 801px) {
      display: flex;
    }
  }

  &__study-list-item {
    flex: 0 0 auto;
    @media (max-width: 800px) {
      margin-left: auto;
      margin-right: auto;
      & + & {
        margin-top: 28px;
      }
    }
    @media (min-width: 801px) {
      width: ~"calc((100% - 48px) / 3)";
      & + & {
        margin-left: 24px;
      }
    }
  }

  &__accessories {
    @media (max-width: 800px) {
      padding: 45px 0;
    }
    @media (min-width: 801px) {
      padding: 58px 0 80px 0;
    }
  }
  &__tab-input-toggler:nth-of-type(5):not(:checked) ~ &__accessories {
    display: none;
  }

  &__tab-input-toggler:nth-of-type(5):checked ~ &__accessories {
    animation: smooth .6s ease-in-out;
  }

  &__accessories-title {
    color: #202020;
    font-weight: 400;
    @media (max-width: 800px) {
      font-size: 25px;
      letter-spacing: -0.6px;
      margin-bottom: 42px;
    }
    @media (min-width: 801px) {
      font-size: 33px;
      letter-spacing: -0.8px;
      margin-bottom: 58px;
    }
  }
}

@keyframes smooth {
  0% {
    opacity: 0;
    display: block; 
  }

  98% {
    opacity: 1;
  }

  99% {
    
  }
}

