.cart-delete-popup {
  align-items: flex-start;
  background-color: fade(#000, 20%);
  display: none;
  font-family: "Glober-Regular";
  height: 100%;
  left: 0;
  overflow-y: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  &--active {
    display: flex;
  }

  &__body {
    align-items: flex-start;
    background-color: #fff;
    display: flex;
    flex-wrap: wrap;
    flex: 0 0 auto;
    justify-content: center;
    margin: auto;
    max-width: 634px;
    position: relative;
    width: ~"calc(100% - 20px)";
    @media (max-width: 800px) {
      padding: 44px 19px 40px;
    }
    @media (min-width: 801px) {
      padding: 74px 70px 56px;
    }
  }

  &__title {
    color: #000;
    flex: 0 0 auto;
    line-height: 1.5;
    width: 100%;
    @media (max-width: 800px) {
      font-size: 14px;
      margin-bottom: 29px;
      text-align: center;
    }
    @media (min-width: 801px) {
      font-size: 18px;
      margin-bottom: 40px;
    }
  }

  &__delete-button {
    border: 1px solid #878787;
    color: #878787;
    flex: 0 0 auto;
    font-size: 16px;
    text-align: center;
    @media (max-width: 800px) {
      border-radius: 19px;
      height: 38px;
      line-height: 36px;
      width: 112px;
    }
    @media (min-width: 801px) {
      border-radius: 23px;
      height: 46px;
      line-height: 44px;
      width: 137px;
    }
  }

  &__cancel-button {
    background-color: #dd2a1b;
    color: #fff;
    flex: 0 0 auto;
    font-size: 16px;
    text-align: center;
    @media (max-width: 800px) {
      border-radius: 19px;
      height: 38px;
      line-height: 38px;
      margin-left: 31px;
      width: 112px;
    }
    @media (min-width: 801px) {
      border-radius: 23px;
      height: 46px;
      line-height: 46px;
      margin-left: 38px;
      width: 137px;
    }
  }

  &__close-button {
    font-size: 0;
    height: 26px;
    position: absolute;
    text-indent: -9999px;
    user-select: none;
    width: 26px;
    &::before,
    &::after {
      background-color: #909090;
      bottom: 0;
      content: '';
      height: 26px;
      left: 0;
      margin: auto;
      position: absolute;
      right: 0;
      top: 0;
      transform-origin: 50% 50%;
      width: 2px;
    }
    &:hover::after,
    &:hover::before {
      background-color: @red-main;
    }
    &::before {
      transform: rotate(45deg);
    }
    &::after {
      transform: rotate(-45deg);
    }
    @media (max-width: 800px) {
      right: 15px;
      top: 15px;
    }
    @media (min-width: 801px) {
      right: 30px;
      top: 25px;
    }
  }
}