.catalog-page {
  align-items: flex-start;
  flex-wrap: wrap;
  font-family: "Glober-Regular";
  @media (max-width: 900px) {
    padding-bottom: 55px;
  }
  @media (min-width: 901px) {
    display: flex;
    padding-bottom: 42px;
    padding-top: 42px;
  }

  &__breadcrumbs {
    flex: 0 0 auto;
    margin-bottom: 52px;
    width: 100%;
  }

  &__menu {
    flex: 0 0 auto;
    margin-bottom: -1px;
    @media (min-width: 901px) {
      padding-right: 30px;
      width: 37.2%;
    }
  }

  &__content {
    flex: 0 0 auto;
    @media (min-width: 901px) {
      width: 62.8%;
    }
  }

  &__tiles {
    overflow: hidden;
    @media (max-width: 900px) {
      padding-top: 34px;
    }
  }

  &__category {
    & + & {
      @media (max-width: 900px) {
        margin-top: 70px;
      }
      @media (min-width: 901px) {
        margin-top: 93px;
      }
    }
  }
}