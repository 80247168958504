.comparing {
  font-family: "Glober-Regular";
  padding-bottom: 88px;
  @media (max-width: 950px) {
    padding-top: 32px;
  }

  &__wrapper {
    @media (max-width: 950px) {
      height: auto !important;
      max-height: none !important;
      min-height: 0 !important;
    }
  }

  &__breadcrumbs {
    margin-bottom: 45px;
    @media (max-width: 800px) {
      display: none;
    }
  }

  &__title {
    color: #202020;
    font-weight: 400;
    @media (max-width: 950px) {
      font-size: 25px;
      letter-spacing: -0.6px;
      line-height: 1.32;
    }
    @media (min-width: 951px) {
      font-size: 44px;
      letter-spacing: -1.1px;
    }
  }

  &__body {
    overflow: hidden;
    position: relative;
    z-index: 1;
    @media (max-width: 950px) {
      margin-top: 27px;
    }
    @media (min-width: 951px) {
      margin-left: -19px;
      margin-right: -19px;
      margin-top: 35px;
    }
  }

  &__adjustments {
    position: absolute;
    top: 0;
    @media (max-width: 950px) {
      background-color: #fff;
      box-shadow: -2.3px 4.4px 15px 0 rgba(0, 0, 0, 0.04);
      height: 418px;
      left: 0;
      width: 100%;
    }
    @media (min-width: 951px) {
      width: 34%;
      z-index: 4;
    }
  }
  &__adjustments--common {
    @media (max-width: 950px) {
      padding: 24px 16px 0;
    }
    @media (min-width: 951px) {
      height: 202px;
      padding: 32px 32px 0;
    }
  }
  &__wrapper.is-fixed &__adjustments {
    @media (min-width: 951px) {
      height: 0;
      left: 0;
      margin: 0 auto;
      max-width: 1198px;
      position: fixed;
      right: 0;
      width: 100%;
    }
  }

  &__adjustments-body {
    position: relative;
    @media (max-width: 950px) {
      height: 100%;
      padding: 22px 16px 0;
    }
    @media (min-width: 951px) {
      background-color: #fff;
      height: 202px;
      padding: 32px 32px 0;
      &:before {
        background-color: #fff;
        border-right: 1px solid fade(#000, 6%);
        content: '';
        height: 100%;
        position: absolute;
        right: -1px;
        top: 0;
        width: 1px;
      }
    }
  }
  &__wrapper.is-fixed &__adjustments-body {
    @media (min-width: 951px) {
      width: 34%;
    }
  }

  &__category-adjustment-title {
    color: #878787;
    letter-spacing: 0.2px;
    @media (max-width: 950px) {
      font-size: 14px;
      line-height: 1.79;
      margin-bottom: 13px;
      position: relative;
      z-index: 2;
    }
    @media (min-width: 951px) {
      font-size: 16px;
      line-height: 1.56;
      margin-bottom: 16px;
    }
  }

  &__category-custom-select {
    color: #565656;
    line-height: 1.78;
    letter-spacing: 0.2px;
    outline: none;
    position: relative;
    @media (max-width: 950px) {
      font-size: 12px;
      z-index: 2;
    }
    @media (min-width: 951px) {
      font-size: 14px;
      z-index: 1;
    }
  }

  &__category-custom-select-label {
    background-color: #fff;
    border-radius: 5px;
    border: solid 1px rgba(38, 38, 38, 0.08);
    color: #565656;
    cursor: pointer;
    letter-spacing: .2px;
    overflow: hidden;
    padding: 3px 38px 0 15px;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
    z-index: 1;
    @media (max-width: 950px) {
      font-size: 13px;
      height: 45px;
      line-height: 42px;
    }
    @media (min-width: 951px) {
      font-size: 16px;
      height: 50px;
      line-height: 45px;
    }
  }

  &__category-custom-select-dropdown {
    background-color: #fff;
    border-radius: 0 0 5px 5px;
    border: 1px solid rgba(38, 38, 38, 0.08);
    box-shadow: -2.3px 4.4px 15px 0 rgba(0, 0, 0, 0.04);
    display: none;
    left: 0;
    position: absolute;
    top: 100%;
    width: 100%;
    margin-top: -4px;
    &.is-active {
      display: block;
    }
  }

  &__category-custom-select-item {
    cursor: pointer;
    padding-left: 12px;
    padding-right: 12px;
    &.is-highlighted {
      background-color: #f5f5f5;
    }
    @media (max-width: 950px) {
      padding-bottom: 11px;
      padding-top: 11px;
    }
    @media (min-width: 951px) {
      padding-bottom: 15px;
      padding-top: 15px;
    }
    &:first-child {
      padding-top: 17px;
    }
  }

  &__filter-adjustment {
    border: none;
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
    @media (max-width: 950px) {
      padding: 0 16px;
    }
    @media (min-width: 951px) {
      padding: 0 32px;
    }
  }

  &__filter-adjustment-items {
    align-items: flex-start;
    display: flex;
    @media (max-width: 950px) {
      justify-content: space-between;
    }
    @media (min-width: 951px) {
      &:before {
        content: '';
        flex: 1 0 auto;
        display: block;
        max-width: 45px;
        min-width: 15px;
      }
    }
  }

  &__filter-adjustment-item {
    flex: 0 1 auto;
    &:nth-of-type(1) {
      order: -1;
    }
    @media (max-width: 950px) {
      & + & {
        margin-left: 15px;
      }
    }
  }

  &__filter-input {
    display: none;
  }

  &__filter-label {
    border-bottom: 2px solid transparent;
    color: #878787;
    cursor: pointer;
    display: block;
    text-transform: uppercase;
    user-select: none;
    @media (max-width: 950px) {
      font-size: 12px;
      line-height: 34px;
    }
    @media (min-width: 951px) {
      font-size: 13px;
      line-height: 38px;
    }
  }
  &__filter-input:checked + &__filter-label {
    border-bottom-color: @red-main; // earlier color: #dd2a1b
    color: #000;
  }

  &__tables-wrapper {
    @media (min-width: 951px) {
      margin-left: 34%;
      overflow-x: auto;
      width: 66%;
    }
  }

  &__table {
    display: none;
    @media (max-width: 950px) {
      padding-top: 418px;
    }
    @media (min-width: 951px) {
      padding-top: 202px;
    }
  }
  &__table--common {
    @media (min-width: 951px) {
      margin-left: 34%;
      overflow-x: auto;
      width: 66%;
    }
  }
  &__table--active {
    display: block;
  }

  &__table-scrolltrack {
    border-radius: 2px;
    border: 1px solid #d8d8d8;
    bottom: 0;
    display: none;
    height: 5px;
    left: ~"calc(34% + 24px)";
    overflow: hidden;
    position: absolute;
    right: 24px;
  }
  &__tables-wrapper._scrollbar &__table-scrolltrack {
    @media (min-width: 951px) {
      display: block;
    }
  }

  &__table-scrollbar {
    background-color: #d8d8d8;
    height: 100%;
    position: relative;
  }

  &__head {
    background-color: #fff;
    display: block;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 3;
    @media (max-width: 950px) {
      padding-top: 120px;
    }
    @media (min-width: 951px) {
      height: 202px;
    }
  }
  &__wrapper.is-fixed &__head {
    left: 0;
    margin: 0 auto;
    max-width: 1198px;
    position: fixed;
    right: 0;
    width: 100%;
  }

  &__head-row {
    align-items: flex-start;
    display: flex;
    @media (max-width: 950px) {
      height: 243px;
      padding: 0 10px;
      position: relative;
    }
    @media (min-width: 951px) {
      height: calc(100% + 50px);
      margin-left: auto;
      overflow-x: auto;
      width: 66%;
    }
  }

  &__head-empty-cell {
    display: none;
  }

  &__head-cell {
    align-items: flex-start;
    flex: 0 0 auto;
    flex-wrap: wrap;
    width: 50%;
    @media (max-width: 950px) {
      display: block;
      padding: 23px 10px 0;
      &:nth-child(3) {
        &:before {
          background-color: fade(#000, 8%);
          content: '';
          height: 100%;
          left: 0;
          margin: 0 auto;
          position: absolute;
          right: 0;
          top: 0;
          width: 1px;
        }
      }
      &:nth-child(n+4) {
        display: none;
      }
    }
    @media (min-width: 951px) {
      display: flex;
      padding: 48px 32px 10px 152px;
      position: relative;
      &:before {
        background-color: #fff;
        content: '';
        height: 202px;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -1;
      }
      &:not(:last-child):after {
        border-right: 1px solid fade(#000, 6%);
        content: '';
        height: 202px;
        position: absolute;
        right: -1px;
        top: 0;
      }
    }
  }

  &__product-cover {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
    @media (max-width: 950px) {
      height: 84px;
      margin: 0 auto 14px;
      width: 70px;
    }
    @media (min-width: 951px) {
      height: 120px;
      left: 32px;
      position: absolute;
      top: 40px;
      width: 85px;
    }
  }

  &__product-cover-image {
    display: none;
  }

  &__product-title {
    font-family: "Glober-SemiBold";
    font-weight: 400;
    @media (max-width: 950px) {
      font-size: 12px;
      height: 1em * 1.4 * 5;
      letter-spacing: 0.3px;
      line-height: 1.4;
      overflow: hidden;
      text-align: center;
    }
    @media (min-width: 951px) {
      font-size: 15px;
      height: 1em * 1.27 * 3;
      letter-spacing: 0.4px;
      line-height: 1.27;
      margin-bottom: 5px;
      overflow: hidden;
      text-align: left;
    }
  }

  &__product-title-link {
    color: #3a3a3a;
  }

  &__product-price {
    color: #565656;
    flex: 0 1 auto;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0.4px;
    line-height: 1.3;
    text-align: left;
    padding-right: 24px;
    white-space: nowrap;
    @media (max-width: 950px) {
      display: none;
    }
  }

  &__product-cart-button {
    color: #0b2a3d;
    flex: 0 0 auto;
    font-size: 0;
    height: 20px;
    overflow: hidden;
    position: relative;
    text-indent: -9999px;
    top: -6px;
    width: 18px;
    @media (max-width: 950px) {
      display: none;
    }
  }
  &__product-cart-button--active {
    color: #dd2a1b;
  }

  &__product-cart-button-icon {
    display: block;
    fill: currentColor;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &__product-delete-button {
    display: block;
    font-size: 0;
    height: 18px;
    position: absolute;
    right: 34px;
    top: 16px;
    width: 18px;
    &::before,
    &::after {
      background-color: #878787;
      bottom: 0;
      content: '';
      height: 1px;
      left: 0;
      margin: auto 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
    &:hover::after,
    &:hover::before {
      background-color: @red-main;
    }
    &::before {
      transform: rotate(-45deg);
    }
    &::after {
      transform: rotate(45deg);
    }
    @media (max-width: 950px) {
      display: none;
    }
  }

  &__parameters {
    display: block;
    @media (max-width: 950px) {
      & + & {
        border-top: 1px solid fade(#000, 8%);
        margin-top: 35px;
        padding-top: 27px;
      }
    }
  }
  &__parameters--first {
    @media (max-width: 950px) {
      padding-top: 29px;
    }
    @media (min-width: 951px) {
      padding-top: 10px;
    }
  }

  &__parameters-title-row {
    display: block;
    @media (max-width: 950px) {
      margin-bottom: 32px;
    }
    @media (min-width: 951px) {
      margin-top: 10px;
      &:before {
        border-top: 1px solid fade(#000, 6%);
        content: '';
        position: absolute;
        left: 0;
        width: 100%;
      }
    }
  }

  &__parameters-title-name {
    color: #565656;
    font-weight: 600;
    letter-spacing: .4px;
    text-transform: uppercase;
    @media (max-width: 950px) {
      font-size: 14px;
      line-height: 1.4;
      text-align: center;
    }
    @media (min-width: 951px) {
      font-size: 15px;
      line-height: 1.67;
      text-align: left;
    }
  }
  &__parameters-title-name--visible {
    display: block;
    @media (min-width: 951px) {
      background-color: #f5f5f5;
      left: 0;
      margin-top: -10px;
      padding: 40px 32px 13px;
      position: absolute;
      width: 34%;
      &:before {
        border-top: 1px solid fade(#000, 6%);
        content: '';
        left: 0;
        position: absolute;
        top: 10px;
        width: 100%;
      }
    }
  }
  &__parameters-title-name--hidden {
    @media (max-width: 950px) {
      display: none;
    }
    @media (min-width: 951px) {
      display: block;
      margin-left: -50%;
      padding: 30px 32px 13px;
      width: 50%;
    }
  }

  /*
  &__parameter-title-row {
    @media (max-width: 950px) {
      display: block;
    }
    @media (min-width: 951px) {
      display: none;
    }
  }
  
  &__parameter-title-name {
    color: #878787;
    font-size: 14px;
    font-weight: 400;
    line-height: 2;
    text-align: center;
  }
  */

  &__parameters-row {
    display: flex;
    @media (max-width: 950px) {
      align-items: flex-start;
      flex-wrap: wrap;
      & + & {
        margin-top: 40px;
      }
    }
    @media (min-width: 951px) {
      align-items: stretch;
      &:hover {
        background-color: #ededed;
      }
    }
  }

  // animation of hiding items
  &__table.is-filtered &__parameters-row:not(.is-active) {
    // opacity: 0;
    // transition: linear 1s;
    animation: is-active-animation 0.5s;
    animation-fill-mode: forwards;
  }

  @keyframes is-active-animation {
    0% {
      opacity: 1;
      height: 45px; //100%  / 45px  
    }

    7% {
      opacity: 0;
    }

    100% {
      display: none;
      opacity: 0;
      height: 0;
    }
  }

  // &__parameters-row:not(.is-unactive) {
  //   // opacity: 0;
  //   // transition: linear 1s;
  //   animation: is-active-animation-show 0.5s;
  //   animation-fill-mode: forwards;
  // }

  @keyframes is-active-animation-show {
    0% {
      display: none;
      opacity: 0;
      height: 0;  
    }

    99% {
      opacity: 1;
      height: 45px;
    }

    100% {
      // opacity: 1;
      // display: none;
    }
  }


  &__table.is-filtered &__parameters-row:not(.is-active-delete-element) {
    // display: none;
  }

  &__table.is-filtered &__parameters-row:not(.is-active) + &__parameters-row.is-active {
    @media (max-width: 950px) {
      margin-top: 0;
    }
  }

  

  &__parameter-name {
    color: #878787;
    flex: 0 0 auto;
    font-size: 14px;
    font-weight: 400;
    @media (max-width: 950px) {
      line-height: 1.3;
      margin-bottom: 14px;
      text-align: center;
    }
    @media (min-width: 951px) {
      line-height: 2;
      padding: 13px 32px 13px;
      text-align: left;
    }
  }
  &__parameters-row:hover &__parameter-name {
    @media (min-width: 951px) {
      background-color: transparent;
      &:before {
        background-color: #ededed;
      }
    }
  }
  &__parameter-name--visible {
    display: block;
    @media (max-width: 950px) {
      width: 100%;
    }
    @media (min-width: 951px) {
      background-color: #f5f5f5;
      left: 0;
      position: absolute;
      width: 34%;
      &:before {
        background-color: #f5f5f5;
        content: '';
        height: 9999px;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -1;
      }
      &:after {
        content: '';
        height: 100%;
        position: absolute;
        right: -1px;
        top: 0;
        width: 1px;
        z-index: 3;
      }
    }
  }
  &__parameter-name--hidden {
    @media (max-width: 950px) {
      display: none;
      text-align: center;
    }
    @media (min-width: 951px) {
      display: block;
      margin-left: -50%;
      width: 50%;
    }
  }

  &__parameter-value {
    flex: 0 0 auto;
    width: 50%;
    @media (max-width: 950px) {
      color: #000;
      font-size: 13px;
      line-height: 1.3;
      padding: 0 5px;
      text-align: center;
      &:nth-of-type(n+3) {
        display: none;
      }
    }
    @media (min-width: 951px) {
      color: #878787;
      font-size: 14px;
      line-height: 2;
      padding: 13px 32px 13px;
      position: relative;
      text-align: left;
      &:before {
        content: '';
        height: 100%;
        position: absolute;
        right: -1px;
        top: 0;
        width: 1px;
        z-index: 3;
      }
    }
  }
  &__parameters-row:hover &__parameter-value {
    @media (min-width: 951px) {
      background-color: #ededed;
    }
  }

  &__decoration-row {
    &:after {
      border-bottom: 1px solid fade(#000, 6%);
      content: '';
      display: block;
      flex: 0 0 auto;
      height: 100%;
      width: 50%;
    }
    @media (max-width: 950px) {
      display: none;
    }
    @media (min-width: 951px) {
      align-items: flex-start;
      display: flex;
      height: 34px;
      padding-bottom: 23px;
    }
  }

  &__decoration-cell {
    display: block;
  }
  &__decoration-cell--title {
    background-color: #f5f5f5;
    height: 34px;
    left: 0;
    position: absolute;
    width: 34%;
    &:before {
      background-color: #f5f5f5;
      // background-color: #ededed;
      border-right: 1px solid fade(#000, 6%);
      bottom: 23px;
      content: '';
      height: 9999px;
      position: absolute;
      right: -1px;
      z-index: 1;
    }
    &:after {
      background-color: #f5f5f5;
      border-top: 1px solid fade(#000, 6%);
      content: '';
      height: 9999px;
      left: 0;
      position: absolute;
      top: 10px;
      width: 100%;
    }
  }
  &__decoration-cell--value {
    border-bottom: 1px solid fade(#000, 6%);
    flex: 0 0 auto;
    height: 100%;
    position: relative;
    width: 50%;
    &:before {
      border-right: 1px solid fade(#000, 6%);
      bottom: 0;
      content: '';
      height: 9999px;
      position: absolute;
      right: -1px;
      z-index: 1;
    }
  }

  &__parameter-notification {
    display: inline-block;
    height: 16px;
    margin-left: 6px;
    position: relative;
    top: 3px;
    width: 16px;
    @media (max-width: 800px) {
      display: none;
    }
  }

  &__parameter-notification-input-toggler {
    display: none;
  }

  &__parameter-notification-button {
    border-radius: 50%;
    border: 1px solid #757575;
    color: #757575;
    cursor: pointer;
    display: block;
    font-size: 11px;
    font-weight: 600;
    height: 100%;
    line-height: 11px;
    overflow: hidden;
    padding-top: 3px;
    text-align: center;
    user-select: none;
    width: 100%;
  }
  &__parameter-notification-input-toggler:checked ~ &__parameter-notification-button {
    border-color: #dd2a1b;
    color: #dd2a1b;
  }

  &__parameter-notification-content {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: -9px 14px 27px 0px rgba(0, 0, 0, .06);
    color: #565656;
    display: none;
    font-size: 14px;
    left: -24px;
    line-height: 1.6;
    padding: 22px;
    position: absolute;
    width: 326px;
    z-index: 2;
    &::before {
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      content: '';
      height: 0;
      left: 24px;
      position: absolute;
      width: 0;
    }
  }
  &__parameter-notification-content--bottom {
    margin-top: 15px;
    top: 100%;
    &::before {
      bottom: 100%;
      border-bottom: 14px solid #fff;
    }
  }
  &__parameter-notification-content--top {
    bottom: 100%;
    margin-bottom: 15px;
    &::before {
      border-top: 14px solid #fff;
      top: 100%;
    }
  }
  &__parameter-notification-input-toggler:checked ~ &__parameter-notification-content {
    display: block;
  }

  &__empty {
    flex-direction: column;
    justify-content: center;
    @media (min-width: 801px) {
      display: flex;
      min-height: 400px;
    }
  }
  &__body + &__empty {
    display: none;
  }

  &__empty-icon {
    display: block;
    fill: #b0b0b0;
    flex: 0 0 auto;
    height: 135px;
    margin: 0 auto;
    width: 135px;
  }

  &__empty-title {
    color: #b0b0b0;
    flex: 0 0 auto;
    font-size: 16px;
    letter-spacing: .2px;
    margin-left: auto;
    margin-right: auto;
    // margin-top: 35px;
    margin-top: 15px;
    text-align: center;
    width: 100%;
  }
}