.cart-item {
  // align-items: flex-start;
  align-items: center;
  background-color: #fff;
  font-family: "Glober-Regular";
  position: relative;
  @media (max-width: 800px) {
    padding: 35px 17px 26px 103px;
  }
  @media (min-width: 801px) {
    display: flex;
    padding: 24px 120px 24px 20px;
  }

  &__cover {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
    flex: 0 0 auto;
    @media (max-width: 800px) {
      position: absolute;
      width: 90px;
      height: 90px;
      left: 3px;
      top: 35px;
    }
    @media (min-width: 801px) {
      height: 140px;
      width: 160px;
    }
  }

  &__cover-image {
    display: none;
  }

  &__primary-info {
    flex: 1 1 0;
    @media (max-width: 800px) {
    }
    @media (min-width: 801px) {
      margin-right: auto;
      max-width: 260px;
      padding-left: 20px;
      // padding-top: 52px;
    }
  }

  &__name {
    color: #3a3a3a;
    font-weight: 600;
    @media (max-width: 800px) {
      font-size: 14px;
      letter-spacing: 0.2px;
      line-height: 1.36;
    }
    @media (min-width: 801px) {
      font-size: 17px;
      letter-spacing: 0.4px;
      line-height: 1.24;
    }
  }

  &__name-link {
    color: #3a3a3a;
  }

  &__parameter {
    color: #878787;
    font-size: 12px;
    letter-spacing: 0.3px;
    @media (max-width: 800px) {
      margin-top: 12px;
    }
    @media (min-width: 801px) {
      margin-top: 14px;
    }
  }

  &__price {
    color: #565656;
    flex: 0 0 auto;
    font-size: 16px;
    letter-spacing: 0.4px;
    line-height: 1.44;
    margin: 0 auto;
    overflow: hidden;
    padding-top: 4px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 90px;
    @media (max-width: 800px) {
      display: none;
    }
  }

  &__quantity {
    border-radius: 5px;
    border: solid 1px rgba(38, 38, 38, 0.08);
    flex: 0 0 auto;
    position: relative;
    @media (max-width: 800px) {
      height: 37px;
      margin-top: 15px;
      width: 94px;
    }
    @media (min-width: 801px) {
      height: 45px;
      margin: 0 auto;
      padding: 0 30px;
      width: 113px;
    }
  }

  &__quantity-input {
    border: none;
    color: #565656;
    display: block;
    font-family: "Glober-Regular";
    font-weight: 600;
    height: 100%;
    padding-top: 6px;
    text-align: center;
    width: 100%;
    @media (max-width: 800px) {
      font-size: 13px;
      letter-spacing: .3px;
    }
    @media (min-width: 801px) {
      font-size: 16px;
      letter-spacing: .4px;
    }
  }

  &__quantity-decrease-button {
    bottom: 0;
    display: block;
    height: 16px;
    left: 9px;
    margin: auto 0;
    position: absolute;
    top: 0;
    width: 16px;
    &:before {
      background-color: #878787;
      bottom: 0;
      content: '';
      height: 2px;
      left: 0;
      margin: auto;
      position: absolute;
      right: 0;
      top: 0;
      width: 6px;
    }
  }

  &__quantity-increase-button {
    bottom: 0;
    display: block;
    height: 16px;
    margin: auto 0;
    position: absolute;
    right: 9px;
    top: 0;
    width: 16px;
    &:before {
      background-color: #878787;
      bottom: 0;
      content: '';
      height: 2px;
      left: 0;
      margin: auto;
      position: absolute;
      right: 0;
      top: 0;
      width: 10px;
    }
    &:after {
      background-color: #878787;
      bottom: 0;
      content: '';
      height: 10px;
      left: 0;
      margin: auto;
      position: absolute;
      right: 0;
      top: 0;
      width: 2px;
    }
  }

  &__sum {
    flex: 0 0 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    @media (max-width: 800px) {
      color: #565656;
      font-size: 14px;
      letter-spacing: .4px;
      margin-top: 17px;
    }
    @media (min-width: 801px) {
      color: #3a3a3a;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: .5px;
      margin-left: auto;
      padding: 4px 10px 0 0;
      width: 134px;
    }
  }

  &__delete-button {
    height: 16px;
    position: absolute;
    right: 44px;
    width: 16px;
    
    &::before,
    &::after {
      background-color: #909090;
      bottom: 0;
      content: '';
      height: 16px;
      left: 0;
      margin: auto;
      position: absolute;
      right: 0;
      top: 0;
      transform-origin: 50% 50%;
      width: 1px;
    }
    &:hover::after,
    &:hover::before {
      background-color: @red-main;
    }

    &::before {
      transform: rotate(45deg);
    }
    &::after {
      transform: rotate(-45deg);
    }
    @media (max-width: 800px) {
      right: 15px;
      top: 12px;
    }
    @media (min-width: 801px) {
      bottom: 0;
      margin: auto 0;
      top: 0;
    }
  }
}