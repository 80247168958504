.catalog-product {
  align-items: flex-start;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid fade(#000, 8%);
  display: flex;
  flex-wrap: wrap;
  flex: 0 0 auto;
  font-family: "Glober-Regular";
  position: relative;
  &--white {
    background-color: #fff;
    border: none;
    box-shadow: -2.3px 4.4px 15px 0 rgba(0, 0, 0, 0.04);
  }
  @media (max-width: 1000px) {
    height: 273px;
    padding: 20px 14px;
    width: 220px;
  }
  @media (min-width: 1001px) {
    height: 386px;
    padding: 26px 19px;
    width: 288px;
  }

  &__cover {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    flex: 0 0 auto;
    margin-left: auto;
    margin-right: auto;
    max-width: 150px;
    width: 100%;
    @media (max-width: 1000px) {
      height: 150px;
      margin-bottom: 18px;
    }
    @media (min-width: 1001px) {
      height: 190px;
      margin-bottom: 32px;
    }
  }

  &__cover-image {
    display: none;
  }

  &__title {
    color: #3a3a3a;
    flex: 0 0 auto;
    font-weight: 600;
    overflow: hidden;
    width: 100%;
    &:hover {
      color: @red-main; // erlier color: #ff2513
    }
    @media (max-width: 1000px) {
      color: #3a3a3a;
      font-size: 13px;
      height: 2em * 1.23;
      letter-spacing: 0.3px;
      line-height: 1.23;
    }
    @media (min-width: 1001px) {
      font-size: 17px;
      height: 2em * 1.24;
      letter-spacing: 0.4px;
      line-height: 1.24;
    }
  }

  &__title-link {
    color: inherit;
  }

  &__company {
    color: #878787;
    flex: 0 0 auto;
    font-size: 12px;
    letter-spacing: 0.3px;
    line-height: 1.92;
    margin: 10px 0 12px;
    width: 100%;
    @media (max-width: 1000px) {
      display: none;
    }
  }

  &__price {
    align-self: baseline;
    color: #565656;
    flex: 1 1 1px;
    overflow: hidden;
    padding-right: 8px;
    text-overflow: ellipsis;
    white-space: nowrap;
    @media (max-width: 1000px) {
      font-size: 12px;
      line-height: 1.46;
      letter-spacing: 0.3px;
      margin-top: 11px;
    }
    @media (min-width: 1001px) {
      font-size: 16px;
      letter-spacing: 0.4px;
      line-height: 1.44;
    }
  }

  &__label {
    border-radius: 13px;
    border: 1px solid currentColor;
    height: 26px;
    line-height: 22px;
    overflow: hidden;
    padding-top: 2px;
    position: absolute;
    right: 25px;
    text-align: center;
    text-overflow: ellipsis;
    top: 22px;
    white-space: nowrap;
    width: 70px;
    &--new {
      color: #0d3147;
    }
    &--sale {
      color: @red-main; //earlier color: #dd2a1b
    }
  }

  &__compare-button {
    align-self: baseline;
    color: #0b2a3d;
    height: 20px;
    margin-left: auto;
    opacity: .2;
    position: relative;
    transition: opacity .3s ease;
    width: 18px;
    &:after,
    &:before {
      background-color: currentColor;
      border-radius: 4px;
      bottom: 0;
      content: '';
      position: absolute;
      width: 4px;
    }
    &:after {
      right: 0;
      height: 20px;
    }
    &:before {
      left: 0;
      height: 8px;
    }
    &:hover {
      opacity: 1;
      transition: opacity .3s ease;
    }
    @media (max-width: 1000px) {
      display: none;
    }
  }
  &__compare-button--active {
    opacity: 1;
  }

  &__compare-button-body {
    background-color: currentColor;
    border-radius: 4px;
    bottom: 0;
    display: block;
    font-size: 0;
    height: 14px;
    left: 0;
    margin: 0 auto;
    overflow: hidden;
    position: absolute;
    right: 0;
    text-indent: -9999px;
    white-space: nowrap;
    width: 4px;
  }

  &__cart-button {
    align-self: baseline;
    color: #0b2a3d;
    height: 20px;
    margin-left: 20px;
    opacity: .2;
    position: relative;
    transition: all .3s ease;
    width: 18px;
    &:hover {
      color: #ff2513;
      opacity: 1;
      transition: all .3s ease;
    }
    @media (max-width: 1000px) {
      display: none;
    }
  }
  &__cart-button--active {
    color: #ff2513;
    opacity: 1;
  }

  &__cart-button-icon {
    fill: currentColor;
    font-size: 1.3rem;
    height: 100%;
    width: 100%;
  }
}