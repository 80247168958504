.privacy-popup {
  background-color: fade(#fff, 80%);
  bottom: 0;
  color: #565656;
  font-family: "Glober-Regular";
  font-size: 12px;
  left: 0;
  letter-spacing: .2px;
  line-height: 2;
  min-width: 320px;
  position: fixed;
  width: 100%;
  z-index: 10;
  @media (max-width: 800px) {
  }
  @media (min-width: 801px) {
  }

  &__body {
    margin: 0 auto;
    max-width: 1240px;
    position: relative;
  }

  &__text {
    margin: 0 auto;
    max-width: 870px;
    padding: 28px 40px 28px 20px;
  }

  &__close-button {
    display: block;
    font-size: 0;
    height: 22px;
    position: absolute;
    right: 7px;
    text-indent: -9999px;
    top: 26px;
    width: 22px;
    &::before,
    &::after {
      background-color: #565656;
      bottom: 0;
      content: '';
      height: 1px;
      left: 0;
      margin: auto 0;
      position: absolute;
      top: 0;
      transform-origin: 50% 50%;
      width: 100%;
    }
    &:hover::after,
    &:hover::before {
      background-color: @red-main;
    }
    &::before {
      transform: rotate(-45deg);
    }
    &::after {
      transform: rotate(45deg);
    }
  }
}