.event {
  font-family: "Glober-Regular";
  position: relative;
  text-align: center;
  z-index: 1;
  @media (min-width: 801px) {
    padding-top: 20px;
  }

  &__breadcrumbs {
    background-color: #f5f5f5;
    flex: 0 0 auto;
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
    @media (max-width: 800px) {
      display: none;
    }
    @media (max-width: 768px) {
      padding-left: 10px;
      padding-right: 10px;
    }
    @media (min-width: 769px) and (max-width: @tablet) {
      padding-left: 28px;
      padding-right: 28px;
    }
    @media (min-width: (@tablet + 1px)) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  &__title {
    color: #fff;
    font-weight: 400;
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
    &::before {
      background-image: linear-gradient(242deg, #5e90d4, #feaba4);
      content: '';
      height: 428px;
      left: 0;
      position: absolute;
      width: 100%;
      z-index: -1;
    }
    @media (max-width: 800px) {
      font-size: 24px;
      line-height: 1.4;
      padding-top: 35px;
      &::before {
        margin-top: -35px;
      }
    }
    @media (min-width: 801px) {
      font-size: 33px;
      letter-spacing: -0.8px;
      line-height: 1.3;
      margin-top: 27px;
      padding-top: 48px;
      &::before {
        margin-top: -48px;
      }
    }
    @media (max-width: 768px) {
      padding-left: 10px;
      padding-right: 10px;
    }
    @media (min-width: 769px) and (max-width: @tablet) {
      padding-left: 28px;
      padding-right: 28px;
    }
    @media (min-width: (@tablet + 1px)) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  &__description {
    align-items: flex-start;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: -9.5px 14.1px 27px 0 rgba(0, 0, 0, 0.08);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 1160px;
    @media (max-width: 800px) {
      font-size: 14px;
      margin-top: 40px;
      padding: 22px 13px 50px;
      &::before {
        content: '';
        display: block;
        flex: 0 0 auto;
        margin-bottom: 25px;
        width: 100%;
      }
    }
    @media (min-width: 801px) {
      font-size: 16px;
      margin-top: 50px;
      padding: 24px 0 60px;
    }
    @media (max-width: 768px) {
      max-width: ~"calc(100% - 20px)";
    }
    @media (min-width: 769px) and (max-width: @tablet) {
      max-width: ~"calc(100% - 56px)";
    }
    @media (min-width: (@tablet + 1px)) {
      max-width: ~"calc(100% - 40px)";
    }
  }

  &__time {
    color: #878787;
    flex: 0 0 auto;
    position: relative;
    white-space: nowrap;
    @media (max-width: 800px) {
      order: -1;
      padding-left: 36px;
    }
    @media (min-width: 801px) {
      padding-left: 38px;
    }
  }

  &__time-icon {
    fill: #878787;
    left: 0;
    position: absolute;
    top: -6px;
    @media (max-width: 800px) {
      height: 24px;
      width: 26px;
    }
    @media (min-width: 801px) {
      height: 26px;
      width: 28px;
    }
  }

  &__duration {
    color: #878787;
    flex: 0 1 auto;
    padding-left: 32px;
    position: relative;
    white-space: nowrap;
    @media (min-width: 801px) {
      margin-left: 55px;
      &::before {
        background-color: fade(#000, 8%);
        content: '';
        height: 21px;
        left: -28px;
        position: absolute;
        top: -3px;
        width: 1px;
      }
    }
  }

  &__duration-icon {
    fill: #878787;
    height: 20px;
    left: 0;
    position: absolute;
    top: -3px;
    width: 20px;
  }

  &__price {
    color: #878787;
    flex: 0 0 auto;
    position: relative;
    white-space: nowrap;
    @media (max-width: 800px) {
      margin-left: 35px;
      padding-left: 30px;
    }
    @media (min-width: 801px) {
      margin-left: 55px;
      padding-left: 32px;
      &::before {
        background-color: fade(#000, 8%);
        content: '';
        height: 21px;
        left: -28px;
        position: absolute;
        top: -3px;
        width: 1px;
      }
    }
  }

  &__price-icon {
    fill: #878787;
    left: 0;
    position: absolute;
    top: -4px;
    @media (max-width: 800px) {
      height: 19px;
      width: 21px;
    }
    @media (min-width: 801px) {
      height: 20px;
      width: 22px;
    }
  }
  &__price-icon--free {
    fill: @red-main; // earlier color: #dd2a1b
  }

  &__description-title {
    flex: 0 0 auto;
    font-weight: 400;
    margin-top: 26px;
    position: relative;
    position: relative;
    text-align: center;
    width: 100%;
    &::before {
      border-top: 1px solid fade(#000, 8%);
      content: '';
      position: absolute;
      top: 0;
    }
    @media (max-width: 800px) {
      font-size: 24px;
      letter-spacing: 0.4px;
      padding-top: 40px;
      &::before {
        left: -13px;
        right: -13px;
      }
    }
    @media (min-width: 801px) {
      font-size: 30px;
      letter-spacing: 0.5px;
      padding-top: 43px;
      &::before {
        left: 0;
        right: 0;
      }
    }
  }

  &__description-content {
    color: #565656;
    flex: 0 0 auto;
    letter-spacing: 0.2px;
    margin-left: auto;
    margin-right: auto;
    max-width: 984px;
    text-align: left;
    width: 100%;
    @media (max-width: 800px) {
      line-height: 2;
      margin-top: 36px;
    }
    @media (min-width: 801px) {
      line-height: 1.75;
      margin-top: 42px;
      padding: 0 20px;
    }
  }

  &__participate-button {
    border: 1px solid @red-main; // earlier color: #dd2a1b
    color: @red-main; // earlier color: #dd2a1b
    display: inline-block;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 1px 15px 0;
    text-align: center;
    transition: all .3s ease;
    vertical-align: top;
    &:hover {
      background-color: @red-main; // earlier color: #dd2a1b
      color: #fff;
      transition: all .3s ease;
    }
    @media (max-width: 800px) {
      border-radius: 24px;
      height: 48px;
      line-height: 45px;
      margin-top: 48px;
      min-width: 156px;
    }
    @media (min-width: 801px) {
      border-radius: 27px;
      height: 54px;
      line-height: 51px;
      margin-top: 60px;
      min-width: 174px;
    }
  }

  &__gallery {
    margin-left: auto;
    margin-right: auto;
    width: 1160px;
    @media (max-width: 800px) {
      margin-top: 77px;
    }
    @media (min-width: 801px) {
      margin-top: 90px;
    }
    @media (max-width: 768px) {
      max-width: ~"calc(100% - 20px)";
    }
    @media (min-width: 769px) and (max-width: @tablet) {
      max-width: ~"calc(100% - 56px)";
    }
    @media (min-width: (@tablet + 1px)) {
      max-width: ~"calc(100% - 40px)";
    }
  }

  &__gallery-items {
    @media (max-width: 800px) {
      height: 216px;
    }
    @media (min-width: 801px) {
      height: 658px;
    }
  }
  &__gallery-items.slick-initialized {
    height: auto;
  }

  &__gallery-items .slick-list {
    @media (max-width: 800px) {
      height: 216px;
    }
    @media (min-width: 801px) {
      height: 658px;
    }
  }

  &__gallery-items .slick-track,
  &__gallery-items .slick-slide,
  &__gallery-items .slick-slide div {
    height: 100%;
  }

  &__gallery-item {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
  }
  .slick-slide &__gallery-item {
    vertical-align: top;
  }

  &__gallery-item &__gallery-item-image {
    display: none;
  }

  &__gallery-dots {
    align-items: flex-start;
    display: flex;
    justify-content: center;
    font-size: 0;
    text-indent: -9999px;
    @media (max-width: 800px) {
      margin-top: 40px;
    }
    @media (min-width: 801px) {
      margin-top: 37px;
    }
  }

  &__gallery-dots li {
    border-radius: 50%;
    border: 1px solid #000;
    flex: 0 0 auto;
    &.slick-active {
      background-color: @red-main; // earlier color: #dd2a1b
      border-color: @red-main; // earlier color: #dd2a1b
    }
    @media (max-width: 800px) {
      height: 9px;
      width: 9px;
    }
    @media (min-width: 801px) {
      height: 12px;
      width: 12px;
    }
  }
  &__gallery-dots li + li {
    @media (max-width: 800px) {
      margin-left: 11px;
    }
    @media (min-width: 801px) {
      margin-left: 15px;
    }
  }

  &__gallery-dots button {
    display: block;
    height: 100%;
    width: 100%;
  }

  &__experts {
    margin-left: auto;
    margin-right: auto;
    width: 1160px;
    @media (max-width: 800px) {
      margin-top: 77px;
    }
    @media (min-width: 801px) {
      margin-top: 90px;
    }
    @media (max-width: 768px) {
      max-width: ~"calc(100% - 20px)";
    }
    @media (min-width: 769px) and (max-width: @tablet) {
      max-width: ~"calc(100% - 56px)";
    }
    @media (min-width: (@tablet + 1px)) {
      max-width: ~"calc(100% - 40px)";
    }
  }

  &__experts-title {
    color: #000;
    font-weight: 400;
    text-align: left;
    @media (max-width: 800px) {
      font-size: 24px;
      letter-spacing: 0.4px;
    }
    @media (min-width: 801px) {
      font-size: 30px;
      letter-spacing: 0.5px;
    }
  }

  &__experts-content {
    margin-top: 55px;
    position: relative;
    @media (max-width: 800px) {
    }
    @media (min-width: 801px) {
      padding: 0 66px;
    }
  }

  &__experts-slider-button {
    border-radius: 50%;
    display: block;
    position: absolute;
    z-index: 2;
    @media (max-width: 800px) {
      border: 1px solid #565656;
      height: 32px;
      top: 63px;
      width: 32px;
    }
    @media (min-width: 801px) {
      height: 36px;
      top: 82px;
      width: 36px;
    }
  }
  &__experts-slider-button--previous {
    @media (max-width: 800px) {
      left: 0;
    }
    @media (min-width: 801px) {
      left: -6px;
    }
  }
  &__experts-slider-button--next {
    @media (max-width: 800px) {
      right: 0;
    }
    @media (min-width: 801px) {
      right: -6px;
    }
  }

  &__experts-slider-button-icon {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    @media (max-width: 800px) {
      fill: #565656;
      height: 12px;
      width: 12px;
    }
    @media (min-width: 801px) {
      fill: #b0b0b0;
      height: 24px;
      width: 24px;
    }
  }
  &__experts-slider-button-icon--previous {
    transform: scaleX(-1);
  }

  &__expert {
    position: relative;
    @media (max-width: 800px) {
      text-align: center;
    }
    @media (min-width: 801px) {
      min-height: 200px;
      padding-left: 246px;
      text-align: left;
    }
  }

  &__expert-cover {
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    @media (max-width: 800px) {
      height: 158px;
      margin: 0 auto 29px;
      width: 158px;
    }
    @media (min-width: 801px) {
      height: 200px;
      position: absolute;
      top: 0;
      left: 0;
      width: 200px;
    }
  }

  &__expert-cover-image {
    display: block;
    height: auto;
    min-height: 100%;
    width: 100%;
  }

  &__expert-name {
    color: #565656;
    font-weight: 600;
    letter-spacing: 0.2px;
    @media (max-width: 800px) {
      font-size: 14px;
    }
    @media (min-width: 801px) {
      font-size: 16px;
    }
  }

  &__expert-position {
    color: #878787;
    letter-spacing: 0.2px;
    @media (max-width: 800px) {
      font-size: 12px;
      line-height: 1.65;
      margin-top: 20px;
    }
    @media (min-width: 801px) {
      font-size: 14px;
      line-height: 2;
      margin-top: 14px;
    }
  }

  &__expert-description {
    color: #565656;
    font-size: 16px;
    letter-spacing: .2px;
    line-height: 1.75;
    margin-top: 34px;
    @media (max-width: 800px) {
      display: none;
    }
  }

  &__map {
    border-top: 1px solid fade(#000, 8%);
    display: block;
    @media (max-width: 800px) {
      margin-top: 75px;
    }
    @media (min-width: 801px) {
      height: 490px;
      margin-top: 103px;
    }
  }

  &__map-info {
    background-color: #fff;
    border-radius: 5px;
    color: #565656;
    letter-spacing: 0.2px;
    text-align: left;
    @media (max-width: 800px) {
      font-size: 12px;
      line-height: 1.5;
      padding: 28px 10px;
    }
    @media (min-width: 801px) {
      display: none;
      font-size: 14px;
      width: 395px;
    }
  }
  &__map-info-popup &__map-info {
    display: block;
  }
  &__map-info-popup .gm-style-iw ~ div {
    display: none !important;
  }

  &__map-info-title {
    color: #000;
    line-height: 1.2;
    @media (max-width: 800px) {
      font-size: 24px;
      letter-spacing: .4px;
    }
    @media (min-width: 801px) {
      font-size: 30px;
      letter-spacing: .5px;
    }
  }

  &__map-info-place {
    font-weight: 600;
    line-height: 1.2;
    @media (max-width: 800px) {
      font-size: 14px;
      margin-top: 20px;
    }
    @media (min-width: 801px) {
      font-size: 16px;
      letter-spacing: .2px;
      margin-top: 21px;
    }
  }

  &__map-info-address {
    @media (max-width: 800px) {
      margin-top: 15px;
    }
    @media (min-width: 801px) {
    }
  }

  &__map-info-date {
    display: block;
    @media (max-width: 800px) {
      margin-top: 17px;
    }
    @media (min-width: 801px) {
    }
  }

  &__map-body {
    @media (max-width: 800px) {
      height: 340px;
    }
    @media (min-width: 801px) {
      height: 490px;
    }
  }

  &__subscribe {
    background-image: linear-gradient(318deg, #5e90d4, #feaba4);
    position: relative;
    @media (min-width: 801px) {
      padding: 85px 0 96px;
    }
  }
  &__subscribe.is-completed {
    @media (max-width: 800px) {
      padding: 41px 10px;
    }
    @media (min-width: 801px) {
      align-items: center;
      display: flex;
      min-height: 500px;
    }
  }

  &__subscribe-form {
    background-color: #fff;
    border-radius: 5px;
    margin: 0 auto;
    position: relative;
    text-align: left;
    @media (max-width: 768px) {
      padding-left: 10px;
      padding-right: 10px;
    }
    @media (min-width: 769px) and (max-width: 800px) {
      padding-left: 28px;
      padding-right: 28px;
    }
    @media (max-width: 800px) {
      padding-bottom: 50px;
      padding-top: 34px;
    }
    @media (min-width: 801px) {
      padding: 44px 84px 57px;
      width: 952px;
    }
    @media (min-width: 801px) and (max-width: @tablet) {
      max-width: ~"calc(100% - 56px)";
    }
    @media (min-width: (@tablet + 1px)) {
      max-width: ~"calc(100% - 40px)";
    }
  }

  &__subscribe-success {
    border-radius: 5px;
    border: 1px solid #fff;
    box-shadow: -0.9px 1.8px 15px 0 rgba(0, 0, 0, 0.04);
    color: #fff;
    margin: 0 auto;
    max-width: 574px;
    text-align: left;
    @media (max-width: 800px) {
      padding: 37px 30px;
    }
    @media (min-width: 801px) {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
      min-height: 208px;
      padding: 30px 45px 30px 95px;
    }
  }

  &__subscribe-success-title {
    flex: 0 0 auto;
    line-height: 1.2;
    position: relative;
    width: 100%;
    @media (max-width: 800px) {
      font-size: 25px;
      letter-spacing: -.6px;
      padding-left: 46px;
    }
    @media (min-width: 801px) {
      font-size: 33px;
      letter-spacing: -.8px;
    }
  }

  &__subscribe-success-icon {
    fill: #fff;
    position: absolute;
    top: -2px;
    @media (max-width: 800px) {
      height: 30px;
      left: 0;
      width: 30px;
    }
    @media (min-width: 801px) {
      height: 33px;
      left: -52px;
      width: 33px;
    }
  }

  &__subscribe-success-subtitle {
    flex: 0 0 auto;
    margin-top: 17px;
    width: 100%;
    @media (max-width: 800px) {
      font-size: 12px;
      letter-spacing: 0.2px;
    }
    @media (min-width: 801px) {
      font-size: 16px;
      line-height: 1.3;
    }
  }
}