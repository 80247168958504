body {
  font: 16px 'Glober-Regular';
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100vh;
  min-width: 320px;
  &.index,
  &.theme-gray {
    background-color: #f5f5f5;
  }
}

input:focus {
  outline: none;
}

.container {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  min-height: 100%;
  min-width: 320px;
  overflow: hidden;
}

.header {
  position: relative;
  &--highlighted {
    background-color: #f5f5f5;
  }
  &--bordered {
    @media all and (max-width: 768px) {
      border-bottom: 1px solid #e9e9e9;
    }
  }
  @media all and (max-width: 768px) {
    padding-bottom: 65px;
  }
}

main {
  flex: 1 0 auto;
  overflow: hidden;
}
.study-page main {
  overflow: visible;
}

img[data-obj-fit] {
	object-fit: contain;
	font-family: 'object-fit: cover;'
}

.wrap {
  max-width: 1200px;
  display: block;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  .index & {
    margin-top: 105px;
    @media all and (max-width: 1200px) {
      margin-top: 70px;
    }
    @media all and (max-width: 800px) {
      margin-top: 140px;
    }
    @media all and (max-width: 768px) {
      margin-top: 140px;
    }
  }
  @media all and (max-width: @tablet) {
    padding: 0 28px;
  }
  @media all and (max-width: 768px) {
    padding: 0 10px;
  }
}

.main-page .category {
  margin-top: 85px;
  margin-bottom: 90px;
  @media all and (max-width: @tablet) {
    margin-bottom: 60px;
  }
  @media all and (max-width: 768px) {
    margin-top: 55px;
  }
}

.icon {
  &--grey {
    color: rgba(80,80,80, 0.5);
  }
}

.h2 {
  font: 44px 'Glober-Regular';
  color: #202020;
  @media all and (max-width: 768px) {
    font-size: 30px;
    padding: 0 20px 0;
  }
}

.index .h2 {
  margin: 0 0 30px;
  @media all and (max-width: @tablet) {
    // margin-top: 70px;
  }
  @media all and (max-width: 768px) {
    margin-bottom: 35px;
  }
}

.description {
  font-size: 16px;
  color: #565656;
  line-height: 28px;
  max-width: 900px;
  @media all and (max-width: 768px) {
    margin: 0 20px;
  }
}
a {
  transition: color 300ms;
  &:hover {
    transition: color 300ms;
  }
}
.btn-up {
  position: fixed;
  display: block;
  bottom: 110px; //earlier 50px
  right: 100px;
  transform: rotate(-90deg);
  color: #c5c5c5;
  transition: color 300ms;
  z-index: 10;
  @media all and (max-width: 1200px) {
    bottom: 20px;
    right: 20px;
  }
  &:hover {
    color: #8a8a8a;
    transition: color 300ms;
  }
  &:after {
    position: absolute;
    top: -15px;
    left: -13px;
    content: '';
    display: block;
    width: 45px;
    height: 45px;
    border: 1px solid #c5c5c5;
    border-radius: 50%;
    transition: border-color 300ms;
    // background: #f5f5f5;
    z-index: 9
  }
  &:hover::after {
    border-color: #8a8a8a;
    transition: border-color 300ms;
  }
}

// hiding content for sighted users (content stay available to screen reader users)
.visually-hidden-content {
  height: 1px;
  left: -10000px;
  overflow: hidden;
  position: absolute;
  top: auto;
  width: 1px;
}