.news-preview {
  align-items: flex-start;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  font-family: "Glober-Regular";
  position: relative;
  height: 450px;
  @media (max-width: 800px) {
    padding-bottom: 16px;
  }
  @media (min-width: 801px) {
    padding-bottom: 22px;
  }
  transition: box-shadow linear 300ms;
  &:hover &__cover:before {        
    background: rgba(83, 83, 83, 0);
    transition: background-color linear 300ms;
  }
  &:hover {
    box-shadow: 0 0 27px rgba(0, 0, 0, 0.08);
    transition: box-shadow linear 300ms;
  }

  &__cover {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    flex: 0 0 auto;
    position: relative;
    width: 100%;
    &:before {
      background-color: fade(#535353, 45%);
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      transition: all linear 300ms;
      // transition: box-shadow linear 300ms;
    }
    @media (max-width: 800px) {
      height: 186px;
      margin-bottom: 18px;
    }
    @media (min-width: 801px) {
      height: 233px;
      margin-bottom: 24px;
    }
  }
  // &__cover:hover:before {
  //   background: fade(#535353, 0%);
  //   transition: background-color 300ms;
  // }

  &__cover-image {
    display: none;
  }

  &__date {
    border-radius: 12px;
    border: solid 1px #fff;
    color: #fff;
    font-size: 11px;
    height: 24px;
    left: 22px;
    letter-spacing: -0.3px;
    line-height: 22px;
    padding: 2px 13px 0;
    position: absolute;
    top: 20px;
    @media (max-width: 800px) {
      display: none;
    }
  }

  &__title {
    flex: 0 0 auto;
    overflow: hidden;
    width: 100%;

    @media (max-width: 800px) {
      color: #202020;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0.2px;
      line-height: 1.2;
      min-height: 1em * 1.2 * 2;
      padding: 0 17px;
    }
    @media (min-width: 801px) {
      color: #202020;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0.2px;
      line-height: 1.3;
      // height: 1em * 1.3 * 3;
      padding: 0 22px;
      margin-bottom: -15px;
      &_with-two-lines {
        // margin-bottom: -38px;
      }
    }
  }

  &__title-link {
    color: inherit;
    &:before {
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
    }

  }

  &__description {
    color: #565656;
    flex: 0 0 auto;
    font-size: 12px;
    letter-spacing: 0.2px;
    line-height: 1.67;
    overflow: hidden;
    width: 100%;
    @media (max-width: 800px) {
      margin-top: 18px;
      padding: 0 17px;
    }  
    @media (min-width: 801px) {
      @inner-height: 1em * 1.67 * 5;
      height: ~"calc(@{inner-height})";
      margin-top: 24px;
      padding: 0 22px;
    }
  }
}