.event-preview {
  align-items: flex-start;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  font-family: "Glober-Regular";
  position: relative;
  @media (max-width: 800px) {
    padding-bottom: 16px;
  }
  @media (min-width: 801px) {
    padding-bottom: 22px;
  }
  height: 550px;
  transition: box-shadow linear 300ms;
  &:hover &__cover:before {        
    background: rgba(83, 83, 83, 0);
    transition: background-color linear 300ms;
  }
  &:hover {
    box-shadow: 0 0 27px rgba(0, 0, 0, 0.08);
    transition: box-shadow linear 300ms;
  }


  &__wrapper-bottom {
    border: 1px solid blue;
    display: block;
    width: 400px;
    height: 200px;
  }

  &__cover {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    flex: 0 0 auto;
    position: relative;
    width: 100%;
    &:before {
      background-color: fade(#535353, 45%);
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      transition: all linear 300ms;
    }
    @media (max-width: 800px) {
      height: 186px;
      margin-bottom: 18px;
    }
    @media (min-width: 801px) {
      height: 233px;
      margin-bottom: 24px;
    }
  }

  &__cover-image {
    display: none;
  }

  &__time-left {
    color: #fff;
    position: absolute;
    white-space: nowrap;
    @media (max-width: 800px) {
      border-radius: 22px;
      border: solid 1px #fff;
      font-size: 18px;
      height: 44px;
      left: 50%;
      letter-spacing: -0.5px;
      line-height: 41px;
      max-width: 100%;
      padding: 1px 23px 0;
      top: 70px;
      transform: translateX(-50%);
    }
    @media (min-width: 801px) {
      background-color: transparent; // earlier color: #dd2a1b, @red-main
      border: 1px solid  #fff;  
      border-radius: 12px;
      font-size: 11px;
      height: 26px; // earlier settings 24px
      left: 22px;
      letter-spacing: -0.3px;
      line-height: 22px;
      padding: 2px 13px 0;
      top: 20px;
    }
  }

  &__title {
    flex: 0 0 auto;
    overflow: hidden;
    width: 100%;
    @media (max-width: 800px) {
      color: #202020;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0.2px;
      line-height: 1.2;
      // height: 1em * 1.2 * 3;
      padding: 0 17px;
    }
    @media (min-width: 801px) {
      color: #202020;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0.2px;
      line-height: 1.3;
      // height: 1em * 1.3 * 3;              // высота заголовка 
      padding: 0 22px;
    }
  }

  &__title-link {
    color: inherit;
    &:before {
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
    }
  }

  &__description {
    border-bottom: 1px solid fade(#000, 5%);
    color: #565656;
    flex: 0 0 auto;
    font-size: 12px;
    letter-spacing: 0.2px;
    line-height: 1.67;
    overflow: hidden;
    width: 100%;
    @media (max-width: 800px) {
      margin-bottom: 15px;
      margin-top: 18px;
      padding: 0 17px 28px;
    }
    @media (min-width: 801px) {
      @inner-height: 1em * 1.67 * 5;
      height: ~"calc(@{inner-height} + 35px)";
      margin-bottom: 19px;
      margin-top: 10px;
      padding: 0 22px 34px;
    }
  }

  &__bottom_two-lines-headline-sz {
    padding-bottom: 155px;
  }

  &__time {
    color: #878787;
    flex: 0 0 auto;
    font-size: 12px;
    position: relative;
    white-space: nowrap;
    @media (max-width: 800px) {
      padding-left: 48px;
    }
    @media (min-width: 801px) {
      padding-left: 53px;
    }
  }

  &__time-icon {
    fill: #878787;
    position: absolute;
    top: -4px;
    @media (max-width: 800px) {
      height: 14px;
      left: 16px;
      width: 19px;
    }
    @media (min-width: 801px) {
      height: 20px;
      left: 20px;
      width: 21px;
    }
  }

  &__duration {
    color: #878787;
    flex: 0 1 auto;
    font-size: 12px;
    margin-left: auto;
    padding-left: 25px;
    position: relative;
    white-space: nowrap;
    @media (max-width: 800px) {
      display: none;
    }
  }

  &__duration-icon {
    fill: #878787;
    height: 16px;
    left: 0;
    position: absolute;
    top: -2px;
    width: 16px;
  }

  &__price {
    color: #878787;
    flex: 0 0 auto;
    font-size: 12px;
    margin-left: auto;
    position: relative;
    white-space: nowrap;
    @media (max-width: 800px) {
      padding-left: 28px;
      padding-right: 17px;
    }
    @media (min-width: 801px) {
      padding-left: 25px;
      padding-right: 22px;
    }
  }

  &__price-icon {
    fill: #878787;
    left: 0;
    position: absolute;
    top: -2px;
    @media (max-width: 800px) {
      height: 13px;
      width: 15px;
    }
    @media (min-width: 801px) {
      height: 15px;
      width: 17px;
    }
  }
  &__price-icon_free {
    fill: @red-main; // earlier color: #dd2a1b
  }
}