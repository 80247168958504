.contacts-response-form {
  align-items: flex-start;
  flex-wrap: wrap;
  font-family: "Glober-Regular";
  @media (max-width: 800px) {
    text-align: center;
  }
  @media (min-width: 801px) {
    display: flex;
  }

  &__title {
    color: #565656;
    flex: 0 0 auto;
    font-weight: 600;
    letter-spacing: .3px;
    margin-bottom: 16px;
    text-align: left;
    text-transform: uppercase;
    width: 100%;
    @media (max-width: 800px) {
      font-size: 15px;
    }
    @media (min-width: 801px) {
      font-size: 17px;
    }
  }

  &__notification {
    color: #878787;
    flex: 0 0 auto;
    letter-spacing: .2px;
    position: relative;
    text-align: left;
    width: 100%;
    @media (max-width: 800px) {
      font-size: 12px;
      margin-bottom: 6px;
    }
    @media (min-width: 801px) {
      font-size: 14px;
      margin-bottom: 16px;
    }
  }

  &__field {
    border-radius: 5px;
    border: 1px solid fade(#000, 8%);
    box-shadow: -0.5px 0.9px 5px 0 rgba(0, 0, 0, 0.04);
    display: block;
    flex: 0 0 auto;
    font-family: "Glober-Regular";
    font-size: 15px;
    margin-top: 16px;
    padding-left: 16px;
    padding-right: 16px;
    width: 100%;
    &::-webkit-input-placeholder {
      color: #b3b3b3;
    }
    &::-moz-placeholder {
      color: #b3b3b3;
      opacity: 1 !important;
    }
    &:-ms-input-placeholder {
      color: #b3b3b3;
    }
    &.is-invalid {
      border-color: #dd2a1b;
    }
    @media (max-width: 800px) {
      font-size: 15px;
      letter-spacing: .4px;
    }
    @media (min-width: 801px) {
      font-size: 16px;
      letter-spacing: .2px;
    }
  }
  &__field--input {
    height: 50px;
    padding-top: 5px;
  }
  &__field--textarea {
    height: 126px;
    padding-bottom: 16px;
    padding-top: 16px;
    resize: none;
  }

  &__submit-button {
    background-color: @red-main;        // earlier color: #dd2a1b
    color: #fff;
    cursor: pointer;
    display: inline-block;
    flex: 0 0 auto;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: -0.3px;
    overflow: hidden;
    transition: background-color .3s ease;
    white-space: nowrap;
    line-height: 1.25;                  // earlier version: line-height: 1.15;
    &:hover {
      background: fade(@red-main, 80%); // earlier color: #dd2a1b
    }
    @media (max-width: 800px) {
      border-radius: 24px;
      font-size: 12px;
      height: 48px;                     // высота кнопки "Отправить"
      margin-top: 50px;
      max-width: 100%;
      min-width: 156px;
      padding: 0 15px;
      text-align: center;
    }
    @media (min-width: 801px) {
      border-radius: 22px;
      height: 40px;                     // высота кнопки "Отправить"
      margin-top: 44px;
      padding: 0 24px;
    }
  }

  &__submit-button-icon {
    display: inline-block;
    fill: #fff;
    font-family: "Glober-Regular";
    font-size: 0.9rem;
    height: 1em;
    left: 0;
    margin-left: 6px;
    position: relative;
    top: -1px;
    transition: left .2s ease;
    vertical-align: middle;
    width: 1em;
    @media (max-width: 800px) {
      display: none;
    }
  }
  &__submit-button:hover &__submit-button-icon {
    left: 5px;
  }

  &__privacy {
    align-self: center;
    color: #878787;
    @media (max-width: 800px) {
      flex: 0 0 auto;
      font-size: 12px;
      line-height: 1.75;
      margin-top: 32px;
      text-align: center;
      width: 100%;
    }
    @media (min-width: 801px) {
      flex: 1 1 0;
      font-size: 14px;
      line-height: 1.3;
      margin: 44px 0 0 26px;
    }
  }

  &__privacy-link {
    color: @red-main; // earlier color: #dd2a1b
  }
}