.nls {
  display: inline-block;
  width: 192px;
  font-size: 0;
  @media all and (max-width: @tablet) {
    display: block;
    text-align: left;
    margin: 45px auto;
  }
  &__logo {
    display: inline-block;
    margin-right: 15px;
  }
  &__description {
    display: inline-block;
    width: 152px;
    color: #8a8a8a;
    font: 12px 'Glober-Regular';
    vertical-align: top;
  }
  &__link {
    display: inline-block;
    color: #b0b0b0;
    font: 12px 'Glober-Regular';
    margin-top: 4px;
    &:hover {
      color: #8a8a8a;
    }
  }
}