
  @keyframes fadeInLeft {
    from {
      opacity: 1;
      transform: translate3d(-500%, 0, 0);
    }
  
    to {
      opacity: 1;
      // transform: none;
    }
  }
  from {
    opacity: 1;
  }
  
  @keyframes fadeOutLeft {
    from {
      opacity: 1;
    }
  
    to {
      opacity: 1;
      transform: translate3d(-500%, 0, 0);
    }
  }

  @keyframes fadeInRight {
    from {
      opacity: 0;
      transform: translate3d(100%, 0, 0);
    }
  
    to {
      opacity: 1;
      // transform: none;
    }
  }

  @keyframes fadeOutRight {
    from {
      opacity: 1;
    }
  
    to {
      opacity: 1;
      transform: translate3d(100%, 0, 0);
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
  
    to {
      opacity: 0;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
  
    to {
      opacity: 1 !important;
    }
  }

  @keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translate3d(0, -100%, 0);
    }
  
    to {
      opacity: 1;
      transform: none;
    }
  }