.breadcrumbs {
  align-items: flex-start;
  display: flex;
  font-family: "Glober-Regular";
  font-size: 13px;
  letter-spacing: 0.2px;
  line-height: 1.92;
  text-align: left;
  @media (max-width: 900px) {
    display: none;
  }

  &__item {
    flex: 0 0 auto;
    & + & {
      margin-left: 4px;
      &:before {
        content: '/';
        padding-right: 2px;
      }
    }
  }

  &__link {
    color: #878787;
    &_width-large {
      display: inline-block; 
      min-width: 395px;  // earlier set 330px
    }
    &:hover {
      color: #000;
    }
  }
}