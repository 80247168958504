.news-detailed {
  font-family: "Glober-Regular";
  @media (max-width: 800px) {
    padding-bottom: 45px;
  }
  @media (min-width: 801px) {
    padding-bottom: 60px;
  }

  &__breadcrumbs {
    background-color: #f5f5f5;
    margin: 0 -9999px;
    padding-top: 50px;
    padding: 50px 9999px 0;
    @media (max-width: 800px) {
      display: none;
    }
  }

  &__title {
    color: #202020;
    font-weight: 400;
    margin-left: auto;
    margin-right: auto;
    max-width: 860px;
    @media (max-width: 800px) {
      font-size: 22px;
      line-height: 1.45;
      letter-spacing: -0.6px;
      margin-bottom: 30px;
      padding-top: 32px;
    }
    @media (min-width: 801px) {
      font-size: 33px;
      line-height: 1.3;
      margin-bottom: 40px;
      padding-top: 50px;
    }
  }

  &__cover {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    margin-left: auto;
    margin-right: auto;
    max-width: 860px;
    @media (max-width: 800px) {
      height: 330px;
      margin-bottom: 34px;
    }
    @media (min-width: 801px) {
      height: 430px;
      margin-bottom: 32px;
    }
  }

  &__cover-image {
    display: none;
  }

  &__date {
    color: #878787;
    display: block;
    font-size: 14px;
    letter-spacing: 0.2px;
    line-height: 1.5;
    margin-left: auto;
    margin-right: auto;
    max-width: 860px;
    @media (max-width: 800px) {
      margin-bottom: 30px;
    }
    @media (min-width: 801px) {
      margin-bottom: 34px;
    }
  }

  &__description {
    color: #565656;
    letter-spacing: 0.2px;
    margin-left: auto;
    margin-right: auto;
    max-width: 860px;
    @media (max-width: 800px) {
      font-size: 14px;
      line-height: 1.43;
    }
    @media (min-width: 801px) {
      font-size: 16px;
      line-height: 1.35;
    }
  }

  &__share {
    margin: 45px auto 0;
    max-width: 860px;
  }

  &__share-body {
    border-top: 1px solid fade(#000, 8%);
    display: inline-block;
    max-width: 100%;
    min-width: 200px;
    vertical-align: top;
    @media (max-width: 800px) {
      padding-top: 44px;
    }
    @media (min-width: 801px) {
      padding-top: 46px;
    }
  }

  &__share-label {
    color: #565656;
    font-weight: 600;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    @media (max-width: 800px) {
      font-size: 16px;
      line-height: 1.56;
      margin-bottom: 7px; // earlier 32px
    }
    @media (min-width: 801px) {
      font-size: 17px;
      line-height: 1.47;
      margin-bottom: 11px; // earlier 36px
    }
  }

  /* &__share-list {
    @media (max-width: 800px) {
    }
    @media (min-width: 801px) {
    }
  } */

  &__share .ya-share2__list {
    align-items: center;
    display: flex;
    font-size: 0;
    /*text-indent: -9999px;*/
  }

  &__share .ya-share2__item {
    flex: 0 0 auto;
  }
  &__share .ya-share2__item + .ya-share2__item {
    margin-left: 24px;
  }

  &__share .ya-share2__link {
    display: block;
  }

  &__share .ya-share2__badge {
    align-items: center;
    background-color: transparent !important;
    display: flex;
  }

  &__share .ya-share2__icon {
    background-position: 50% 50% !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
    display: block;
    flex: 0 0 auto;
  }
  &__share .ya-share2__item_service_facebook .ya-share2__icon {
    background-image: url(../img/facebook.svg);
    background-size: 23px 23px !important;
    height: 23px;
    width: 14px;
  }
  &__share .ya-share2__item_service_vkontakte .ya-share2__icon {
    background-image: url(../img/vk.svg);
    background-size: 29px 29px !important;
    height: 16px;
    width: 29px;
  }
  &__share .ya-share2__item_service_twitter .ya-share2__icon {
    background-image: url(../img/twitter.svg);
    background-size: 25px 25px !important;
    height: 20px;
    width: 25px;
  }

  &__share .ya-share2__counter {
    color: #878787;
    display: block;
    flex: 0 0 auto;
    font-family: 'Glober-SemiBold';
    font-size: 14px !important;
    line-height: 1.2 !important;
    margin-left: 12px;
    padding: 0 !important;
    position: relative;
    top: 3px;
  }
  /*&__share .ya-share2__counter_visible {
    display: block;
  }*/
}