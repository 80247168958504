.review-form {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 800px) {
  }
  @media (min-width: 801px) {
  }

  &__header {
    align-items: baseline;
    flex: 0 0 auto;
    width: 100%;
    @media (max-width: 800px) {
    }
    @media (min-width: 801px) {
      display: flex;
    }
  }

  &__title {
    color: #565656;
    font-weight: 600;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    @media (max-width: 800px) {
      font-size: 15px;
      line-height: 1.67;
    }
    @media (min-width: 801px) {
      font-size: 17px;
      line-height: 1.47;
    }
  }

  &__moderating {
    flex: 0 0 auto;
    height: 18px;
    margin-left: 13px;
    position: relative;
    vertical-align: bottom;
    width: 16px;
    @media (max-width: 800px) {
      display: none;
    }
    @media (min-width: 801px) {
    }
  }

  &__moderating-icon {
    display: block;
    fill: @red-main; // earlier color: #dd2a1b
    height: 100%;
    width: 100%;
  }

  &__moderating-text {
    background-color: #fff;
    border-radius: 5px;
    bottom: 100%;
    color: #565656;
    display: none;
    font-size: 14px;
    line-height: 1.64;
    margin-bottom: 16px;
    margin-left: -24px;
    padding: 16px 18px;
    position: absolute;
    width: 256px;
  }
  &__moderating:hover &__moderating-text {
    display: block;
  }

  &__notification {
    flex: 0 0 auto;
    width: 100%;
    @media (max-width: 800px) {
      font-size: 12px;
      line-height: 2.08;
      margin: 14px 0 40px;
    }
    @media (min-width: 801px) {
      font-size: 14px;
      line-height: 1.79;
      margin: 8px 0 42px 0;
    }
  }
  &__notification--default {
    color: #878787;
  }
  &.not-valid &__notification--default {
    display: none;
  }
  &__notification--error {
    color: #dd2a1b;
    display: none;
  }
  &.not-valid &__notification--error {
    display: block;
  }

  &__item {
    flex: 0 0 auto;
    & + & {
      margin-top: 34px;
    }
    @media (max-width: 800px) {
    }
    @media (min-width: 801px) {
    }
  }
  &__item--name {
    @media (max-width: 800px) {
      width: 100%;
    }
    @media (min-width: 801px) {
      margin-right: 53px;
      width: ~"calc(50% - 26px)";
    }
  }
  &__item--props {
    @media (max-width: 800px) {
      width: 100%;
    }
    @media (min-width: 801px) {
      width: ~"calc(50% - 26px)";
    }
  }
  &__item--cons {
    @media (max-width: 800px) {
      width: 100%;
    }
    @media (min-width: 801px) {
      margin-left: 52px;
      width: ~"calc(50% - 26px)";
    }
  }
  &__item--impression {
    width: 100%;
    @media (min-width: 801px) {
      margin-bottom: 41px;
    }
  }

  &.is-completed &__item {
    display: none;
  }

  &__item-label {
    color: #878787;
    letter-spacing: 0.2px;
    @media (max-width: 800px) {
      font-size: 13px;
      line-height: 1.92;
    }
    @media (min-width: 801px) {
      font-size: 16px;
      line-height: 1.56;
    }
  }

  &__item-input {
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid transparent;
    box-shadow: -0.5px 0.9px 5px 0 rgba(0, 0, 0, 0.04);
    display: block;
    height: 50px;
    margin-top: 14px;
    padding: 0 15px;
    width: 100%;
    &.is-invalid {
      border-color: #dd2a1b;
    }
  }

  &__item-textarea {
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid transparent;
    box-shadow: -0.5px 0.9px 5px 0 rgba(0, 0, 0, 0.04);
    display: block;
    margin-top: 14px;
    padding: 15px;
    resize: none;
    width: 100%;
    &.is-invalid {
      border-color: #dd2a1b;
    }
    @media (max-width: 800px) {
      height: 138px;
    }
    @media (min-width: 801px) {
      height: 180px;
    }
  }

  &__submit-button {
    border: solid 1px #565656;
    color: #565656;
    flex: 0 0 auto;
    @media (max-width: 800px) {
      border-radius: 19px;
      font-size: 12px;
      height: 38px;
      letter-spacing: -0.3px;
      line-height: 36px;
      margin-top: 41px;
      width: 161px;
    }
    @media (min-width: 801px) {
      border-radius: 22px;
      font-size: 13px;
      height: 44px;
      letter-spacing: -0.3px;
      line-height: 42px;
      width: 186px;
    }
  }
  &.is-completed &__submit-button {
    display: none;
  }

  &__submit-button-icon {
    display: inline-block;
    fill: #565656;
    height: 14px;
    margin-left: 18px;
    position: relative;
    top: 3px;
    width: 14px;
  }

  &__privacy {
    color: #878787;
    @media (max-width: 800px) {
      flex: 0 0 auto;
      width: 100%;
      font-size: 12px;
      line-height: 1.75;
      margin-top: 30px;
    }
    @media (min-width: 801px) {
      align-self: center;
      flex: 1 1 0;
      font-size: 14px;
      line-height: 1.79;
      padding-left: 35px;
    }
  }
  &.is-completed &__privacy {
    display: none;
  }

  &__privacy-link {
    color: @red-main; // earlier color: #dd2a1b
  }

  &__success {
    border-radius: 5px;
    color: #fff;
    display: none;
    flex: 0 0 auto;
    border-radius: 5px;
    border: 1px solid #e2e2e2;
    box-shadow: -0.9px 1.8px 15px 0 rgba(0, 0, 0, 0.04);
    @media (max-width: 800px) {
      padding: 37px 30px;
    }
    @media (min-width: 801px) {
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
      max-width: 495px;
      min-height: 180px;
      padding: 30px 40px 30px 90px;
    }
  }
  &.is-completed &__success {
    @media (max-width: 800px) {
      display: block;
    }
    @media (min-width: 801px) {
      display: flex;
    }
  }

  &__success-title {
    color: #30576f;
    flex: 0 0 auto;
    line-height: 1.2;
    position: relative;
    width: 100%;
    @media (max-width: 800px) {
      font-size: 25px;
      letter-spacing: -.6px;
      padding-left: 46px;
    }
    @media (min-width: 801px) {
      font-size: 28px;
      letter-spacing: -.8px;
    }
  }

  &__success-icon {
    fill: #30576f;
    position: absolute;
    top: -2px;
    @media (max-width: 800px) {
      height: 30px;
      left: 0;
      width: 30px;
    }
    @media (min-width: 801px) {
      height: 28px;
      left: -44px;
      width: 28px;
    }
  }

  &__success-subtitle {
    color: #565656;
    flex: 0 0 auto;
    margin-top: 17px;
    width: 100%;
    @media (max-width: 800px) {
      font-size: 12px;
      letter-spacing: 0.2px;
    }
    @media (min-width: 801px) {
      font-size: 14px;
      line-height: 1.3;
    }
  }
}