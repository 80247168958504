.order-form {
  align-items: flex-start;
  flex-wrap: wrap;
  font-family: "Glober-Regular";
  @media (max-width: 800px) {
  }
  @media (min-width: 801px) {
    display: flex;
    margin-left: -20px;
    margin-right: -20px;
  }

  &__contacts {
    align-items: flex-start;
    flex: 0 0 auto;
    flex-wrap: wrap;
    width: 100%;
    @media (max-width: 800px) {
      border-top: 1px solid #e2e2e2;
      padding-top: 30px;
    }
    @media (min-width: 801px) {
      border-radius: 5px;
      border: 1px solid #e2e2e2;
      display: flex;
      padding: 35px 45px 35px 45px; // отступ снизу 80px (карточка Доставка)
    }
  }

  &__contacts-title {
    color: #565656;
    flex: 0 0 auto;
    font-weight: 600;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    width: 100%;
    @media (max-width: 800px) {
      font-size: 15px;
    }
    @media (min-width: 801px) {
      font-size: 17px;
    }
  }

  &__contacts-notification {
    color: #878787;
    flex: 0 0 auto;
    letter-spacing: 0.2px;
    width: 100%;
    @media (max-width: 800px) {
      font-size: 12px;
      margin: 14px 0 28px;
    }
    @media (min-width: 801px) {
      font-size: 14px;
      margin-top: 13px;
    }
  }
  &.not-valid &__contacts-notification {
    color: #dd2a1b;
  }

  &__field {
    flex: 0 0 auto;
    @media (max-width: 800px) {
      & + & {
        margin-top: 34px;
      }
    }
    @media (min-width: 801px) {
      margin-top: 30px;
    }
  }
  &__field--name {
    @media (min-width: 801px) {
      width: ~"calc(50% - 26px)";
    }
  }
  &__field--surname {
    @media (min-width: 801px) {
      margin-left: 52px;
      width: ~"calc(50% - 26px)";
    }
  }
  &__field--email {
    @media (min-width: 801px) {
      width: ~"calc(50% - 26px)";
    }
  }
  &__field--phone {
    @media (min-width: 801px) {
      margin-left: 52px;
      width: ~"calc(50% - 26px)";
    }
  }
  &__field--city {
    @media (max-width: 800px) {
      width: 100%;
    }
    @media (min-width: 801px) {
      width: ~"calc(50% - 26px)";
    }
  }
  &__field--street {
    @media (max-width: 800px) {
      width: 100%;
    }
    @media (min-width: 801px) {
      margin-left: 52px;
      width: ~"calc(50% - 26px)";
    }
  }
  &__field--house {
    @media (max-width: 800px) {
      width: ~"calc(50% - 21px)";
    }
    @media (min-width: 801px) {
      width: ~"calc((50% - 98px) / 3)";
    }
  }
  &__field--block {
    @media (max-width: 800px) {
      margin-left: 42px;
      width: ~"calc(50% - 21px)";
    }
    @media (min-width: 801px) {
      margin-left: 36px;
      width: ~"calc((50% - 98px) / 3)";
    }
  }
  &__field--building {
    @media (max-width: 800px) {
      width: ~"calc(50% - 21px)";
    }
    @media (min-width: 801px) {
      margin-left: 36px;
      width: ~"calc((50% - 98px) / 3)";
    }
  }
  &__field--entrance {
    @media (max-width: 800px) {
      margin-left: 42px;
      width: ~"calc(50% - 21px)";
    }
    @media (min-width: 801px) {
      margin-left: 52px;
      width: ~"calc((50% - 98px) / 3)";
    }
  }
  &__field--floor {
    @media (max-width: 800px) {
      width: ~"calc(50% - 21px)";
    }
    @media (min-width: 801px) {
      margin-left: 36px;
      width: ~"calc((50% - 98px) / 3)";
    }
  }
  &__field--apartment {
    @media (max-width: 800px) {
      margin-left: 42px;
      width: ~"calc(50% - 21px)";
    }
    @media (min-width: 801px) {
      margin-left: 36px;
      width: ~"calc((50% - 98px) / 3)";
    }
  }
  &__field--index {
    @media (max-width: 800px) {
      width: 100%;
    }
    @media (min-width: 801px) {
      width: ~"calc(50% - 26px)";
    }
  }
  &__field--note {
    @media (max-width: 800px) {
      width: 100%;
    }
    @media (min-width: 801px) {
      margin-left: 52px;
      width: ~"calc(50% - 26px)";
    }
  }

  &__field-label {
    color: #878787;
    @media (max-width: 800px) {
      font-size: 14px;
    }
    @media (min-width: 801px) {
      font-size: 16px;
      letter-spacing: .2px;
    }
  }

  &__field-label-required-sign {
    color: #dd2a1b;
  }

  &__field-input {
    background-color: #fff;
    border-radius: 5px;
    border: none;
    color: #565656;
    display: block;
    font-family: "Glober-Regular";
    height: 50px;
    margin-top: 18px;
    width: 100%;
    &.is-invalid {
      border-color: #dd2a1b;
    }
    &--common {
      border: 1px solid transparent;
      box-shadow: -0.5px 0.9px 5px 0 rgba(0, 0, 0, .04);
    }
    &--outlined {
      border: 1px solid fade(#000, 8%);
    }
    @media (max-width: 800px) {
      font-size: 15px;
      letter-spacing: .4px;
      padding: 0 19px;
    }
    @media (min-width: 801px) {
      font-size: 16px;
      letter-spacing: .2px;
      padding: 0 17px;
    }
  }

  &__field-textarea {
    background-color: #fff;
    border-radius: 5px;
    border: none;
    color: #565656;
    display: block;
    font-family: "Glober-Regular";
    margin-top: 18px;
    resize: none;
    width: 100%;
    &--common {
      border: 1px solid transparent;
      box-shadow: -0.5px 0.9px 5px 0 rgba(0, 0, 0, .04);
    }
    &--outlined {
      border: 1px solid fade(#000, 8%);
    }
    @media (max-width: 800px) {
      font-size: 15px;
      height: 134px;
      letter-spacing: .4px;
      padding: 15px 19px;
    }
    @media (min-width: 801px) {
      font-size: 16px;
      height: 174px;
      letter-spacing: .2px;
      padding: 15px 17px;
    }
  }

  &__field-select-container {
    border-radius: 5px;
    height: 50px;
    margin-top: 18px;
    position: relative;
    &:before {
      background-color: #fff;
      border-radius: 5px;
      border: 1px solid fade(#000, 8%);
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 2;
    }
  }

  &__field-select {
    appearance: none;
    border: none;
    background-color: transparent;
    color: #565656;
    display: block;
    font-family: "Glober-Regular";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    @media (max-width: 800px) {
      font-size: 15px;
      letter-spacing: .4px;
      padding: 0 19px;
    }
    @media (min-width: 801px) {
      font-size: 16px;
      letter-spacing: .2px;
      padding: 0 17px;
    }
  }

  &__custom-select {
    color: #565656;
    font-size: 14px;
    line-height: 1.78;
    letter-spacing: 0.2px;
    outline: none;
    position: relative;
  }

  &__custom-select-label {
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    height: 50px;
    line-height: 46px;
    overflow: hidden;
    padding: 4px 38px 0 15px;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
    z-index: 2;
  }

  &__custom-select-dropdown {
    background-color: #fff;
    border-radius: 0 0 5px 5px;
    border: 1px solid rgba(38, 38, 38, 0.08);
    box-shadow: -2.3px 4.4px 15px 0 rgba(0, 0, 0, 0.04);
    display: none;
    left: 0;
    margin-top: -4px;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 1;
    &.is-active {
      display: block;
    }
  }

  &__custom-select-item {
    cursor: pointer;
    padding: 12px 15px;
    &:first-child {
      padding-top: 17px;
    }
    &.is-highlighted {
      background-color: #f5f5f5;
    }
  }

  &__person-type {
    align-items: flex-start;
    border: none;
    flex-wrap: wrap;
    margin-left: -35px;
    margin-right: 0;
    padding: 0;
    width: ~"calc(100% + 35px)";
    @media (max-width: 800px) {
      margin-top: 58px;
    }
    @media (min-width: 801px) {
      display: flex;
      margin-top: 38px;
    }
  }

  &__person-type-title {
    color: #878787;
    display: block;
    flex: 0 0 auto;
    letter-spacing: 0.2px;
    padding: 0 0 5px 35px;
    width: 100%;
    @media (max-width: 800px) {
      font-size: 14px;
    }
    @media (min-width: 801px) {
      font-size: 16px;
    }
  }

  &__person-type-item {
    flex: 0 0 auto;
    margin-left: 35px;
    margin-top: 21px;
  }

  &__person-type-input {
    display: none;
  }

  &__person-type-label {
    color: #878787;
    letter-spacing: .2px;
    padding-left: 28px;
    position: relative;
    &:before {
      border-radius: 50%;
      border: 1px solid fade(#000, 10%);
      content: '';
      height: 16px;
      left: 0;
      position: absolute;
      top: -1px;
      width: 16px;
      cursor: pointer;

    }
    @media (max-width: 800px) {
      font-size: 13px;
    }
    @media (min-width: 801px) {
      font-size: 14px;
    }
  }

  &__person-type-input:checked + &__person-type-label {
    &:before {
      background-image: radial-gradient(circle at 50% 50%, @red-main 3px, transparent 4px); // earlier color: #dd2a1b
      // background-color: red;

      cursor: pointer;
      animation: tick .6s ease-in-out;
    }
  }


  &__delivery {
    background-color: #fff;
    flex: 0 0 auto;
    @media (max-width: 800px) {
      margin: 24px -9999px 38px;
      padding: 31px 9999px 30px;
    }
    @media (min-width: 801px) {
      border-radius: 5px;
      box-shadow: -0.9px 1.8px 15px 0 rgba(0, 0, 0, 0.04);
      margin: 21px 0 48px;
      padding: 35px 46px 71px 46px; // отступ снизу 80px (карточка Доставка)
      width: 100%;
    }
  }

  &__delivery-title {
    color: #565656;
    display: inline-block;
    font-weight: 600;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    @media (max-width: 800px) {
      font-size: 15px;
    }
    @media (min-width: 801px) {
      font-size: 17px;
    }
  }

  &__delivery-notification {
    display: inline-block;
    height: 16px;
    margin-left: 6px;
    position: relative;
    width: 16px;
    @media (max-width: 800px) {
      display: none;
    }
  }

  &__delivery-notification-input-toggler {
    display: none;
  }

  &__delivery-notification-button {
    border-radius: 50%;
    border: 1px solid #757575;
    color: #757575;
    cursor: pointer;
    display: block;
    font-size: 11px;
    font-weight: 600;
    height: 100%;
    line-height: 11px;
    overflow: hidden;
    padding-top: 3px;
    text-align: center;
    user-select: none;
    width: 100%;
    transition: fill .1s ease;
  }
  &__delivery-notification-input-toggler:hover ~ &__delivery-notification-button {
    border-color: #dd2a1b;
    // color: #dd2a1b;
    color: @red-main;
  }

  &__delivery-notification-content {
    background-color: #fff;
    border-radius: 5px;
    bottom: 100%;
    box-shadow: -9px 14px 27px 0px rgba(0, 0, 0, .06);
    color: #565656;
    // display: none;
    display: block;
    font-size: 14px;
    height: 122px;
    left: -24px;
    line-height: 1.6;
    margin-bottom: 15px;
    padding: 22px;
    position: absolute;
    width: 326px;
    opacity: 0;                       // плавное появление окна при наведении
    visibility: hidden;               // плавное появление окна при наведении
    transition: all .3s ease;     // плавное появление окна при наведении
                                      // заменить opacity на all для плавного исчезновения окна при отведении мыши
    &:before {
      border-top: 14px solid #fff;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      content: '';
      height: 0;
      left: 24px;
      position: absolute;
      top: 100%;
      width: 0;
    }
  }
  &__delivery-notification-input-toggler:hover ~ &__delivery-notification-content {
    // display: block;
    visibility: visible;              // плавное появление окна при наведении
    opacity: 1;                       // плавное появление окна при наведении

  }

  &__delivery-notification-text {
    display: block;
    margin-bottom: 15px;
  }

  &__delivery-notification-phone {
    color: #3a3a3a;
    font-size: 18px;
  }

  &__delivery-type {
    align-items: flex-start;
    border: none;
    display: flex;
    flex-wrap: wrap;
    flex: 0 0 auto;
    margin-left: -35px;
    margin-right: 0;
    padding: 0;
    width: ~"calc(100% + 35px)";
    @media (max-width: 800px) {
      margin-top: 10px;
    }
    @media (min-width: 801px) {
      margin-top: 35px;
    }
  }

  &__delivery-type-item {
    flex: 0 0 auto;
    margin-left: 35px;
    @media (max-width: 800px) {
      margin-top: 14px;
    }
    @media (min-width: 801px) {
      margin-top: 21px;
    }
  }

  &__delivery-type-input {
    display: none;
  }

  &__delivery-type-label {
    color: #878787;
    letter-spacing: .2px;
    padding-left: 28px;
    position: relative;
    &:before {
      border-radius: 50%;
      border: 1px solid fade(#000, 10%);
      content: '';
      height: 16px;
      left: 0;
      position: absolute;
      top: -1px;
      width: 16px;
      cursor: pointer;
    }
    @media (max-width: 800px) {
      font-size: 13px;
    }
    @media (min-width: 801px) {
      font-size: 14px;
    }
  }
  &__delivery-type-input:checked + &__delivery-type-label {
    &:before {
      background-image: radial-gradient(circle at 50% 50%, @red-main 3px, transparent 4px); // earlier color: #dd2a1b
      animation: tick .6s ease-in-out;
    }
  }

  &__delivery-courier {
    align-items: flex-start;
    display: none;
    flex-wrap: wrap;
    @media (max-width: 800px) {
      margin-top: 32px;
    }
    &.is-active {
      display: flex;
    }
  }

  &__delivery-pickup-address {
    color: #878787;
    display: none;
    letter-spacing: 0.2px;
    line-height: 1.5;
    padding-left: 32px;
    position: relative;
    &.is-active {
      display: block;
    }
    @media (max-width: 800px) {
      font-size: 14px;
      margin-top: 30px;
    }
    @media (min-width: 801px) {
      font-size: 16px;
      margin-top: 44px;
    }
  }

  &__delivery-pickup-address-icon {
    fill: #878787;
    height: 18px;
    left: 0;
    position: absolute;
    top: 0;
    width: 18px;
  }

  &__submit-button {
    background-color: #ff2513;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    flex: 0 0 auto;
    font-weight: 600;
    position: relative;
    transition: background-color .3s ease;
    &:hover {
      background-color: fade(#ff2513, 90%);
    }
    @media (max-width: 800px) {
      border-radius: 20px;
      height: 40px;
      font-size: 14px;
      left: 50%;
      padding: 12px 20px;
      transform: translateX(-50%);
    }
    @media (min-width: 801px) {
      border-radius: 22px;
      font-size: 13px;
      height: 44px;
      padding: 0 28px;
    }
  }

  &__submit-button-icon {
    fill: #fff;
    font-size: 0.9rem;
    height: 1em;
    left: 0;
    margin-left: 10px;
    position: relative;
    transition: left .2s ease;
    vertical-align: middle;
    width: 1em;
    @media (max-width: 800px) {
      display: none;
    }
    @media (min-width: 801px) {
      display: inline-block;
    }
  }
  &__submit-button:hover &__submit-button-icon {
    left: 5px;
  }

  &__privacy {
    align-self: center;
    color: #878787;
    flex: 1 1 0;
    @media (max-width: 800px) {
      font-size: 12px;
      line-height: 1.75;
      margin-top: 30px;
      text-align: center;
    }
    @media (min-width: 801px) {
      font-size: 14px;
      line-height: 1.8;
      margin-left: 42px;
    }
  }

  &__privacy-link {
    color: @red-main; // earlier color: #dd2a1b
  }
}



@keyframes tick {
  // 0% {
  //   background-image: radial-gradient(circle at 50% 50%, fade(blue, 0%) 3px, transparent 4px); // earlier color: #dd2a1b

  // }
  // 100% {
  //   background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 100%) 3px, transparent 4px); // earlier color: #dd2a1b
  // }


  0% {

    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 0%) 3px, transparent 4px);
  }
  1% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 1%) 3px, transparent 4px);
  }

  2% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 2%) 3px, transparent 4px);
  }

  3% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 3%) 3px, transparent 4px);
  }

  4% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 4%) 3px, transparent 4px);
  }

  5% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 5%) 3px, transparent 4px);
  }

  6% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 6%) 3px, transparent 4px);
  }

  7% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 7%) 3px, transparent 4px);
  }

  8% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 8%) 3px, transparent 4px);
  }

  9% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 9%) 3px, transparent 4px);
  }

  10% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 10%) 3px, transparent 4px);
  }

  11% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 11%) 3px, transparent 4px);
  }

  12% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 12%) 3px, transparent 4px);
  }

  13% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 13%) 3px, transparent 4px);
  }

  14% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 14%) 3px, transparent 4px);
  }

  15% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 15%) 3px, transparent 4px);
  }

  16% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 16%) 3px, transparent 4px);
  }

  17% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 17%) 3px, transparent 4px);
  }

  18% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 18%) 3px, transparent 4px);
  }

  19% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 19%) 3px, transparent 4px);
  }

  20% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 20%) 3px, transparent 4px);
  }

  21% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 21%) 3px, transparent 4px);
  }

  22% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 22%) 3px, transparent 4px);
  }

  23% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 23%) 3px, transparent 4px);
  }

  24% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 24%) 3px, transparent 4px);
  }

  25% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 25%) 3px, transparent 4px);
  }

  26% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 26%) 3px, transparent 4px);
  }

  27% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 27%) 3px, transparent 4px);
  }

  28% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 28%) 3px, transparent 4px);
  }

  29% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 29%) 3px, transparent 4px);
  }

  30% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 30%) 3px, transparent 4px);
  }

  31% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 31%) 3px, transparent 4px);
  }

  32% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 32%) 3px, transparent 4px);
  }

  33% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 33%) 3px, transparent 4px);
  }

  34% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 34%) 3px, transparent 4px);
  }

  35% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 35%) 3px, transparent 4px);
  }

  36% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 36%) 3px, transparent 4px);
  }

  37% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 37%) 3px, transparent 4px);
  }

  38% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 38%) 3px, transparent 4px);
  }

  39% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 39%) 3px, transparent 4px);
  }

  40% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 40%) 3px, transparent 4px);
  }


  41% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 41%) 3px, transparent 4px);
  }

  42% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 42%) 3px, transparent 4px);
  }

  43% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 43%) 3px, transparent 4px);
  }

  44% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 44%) 3px, transparent 4px);
  }

  45% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 45%) 3px, transparent 4px);
  }

  46% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 46%) 3px, transparent 4px);
  }

  47% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 47%) 3px, transparent 4px);
  }

  48% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 48%) 3px, transparent 4px);
  }

  49% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 49%) 3px, transparent 4px);
  }

  50% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 50%) 3px, transparent 4px);
  }

  51% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 51%) 3px, transparent 4px);
  }

  52% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 52%) 3px, transparent 4px);
  }

  53% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 53%) 3px, transparent 4px);
  }

  54% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 54%) 3px, transparent 4px);
  }

  55% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 55%) 3px, transparent 4px);
  }

  56% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 56%) 3px, transparent 4px);
  }

  57% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 57%) 3px, transparent 4px);
  }

  58% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 58%) 3px, transparent 4px);
  }

  59% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 59%) 3px, transparent 4px);
  }

  60% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 60%) 3px, transparent 4px);
  }

  61% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 61%) 3px, transparent 4px);
  }

  62% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 62%) 3px, transparent 4px);
  }

  63% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 63%) 3px, transparent 4px);
  }

  64% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 64%) 3px, transparent 4px);
  }

  65% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 65%) 3px, transparent 4px);
  }

  66% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 66%) 3px, transparent 4px);
  }

  67% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 67%) 3px, transparent 4px);
  }

  68% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 68%) 3px, transparent 4px);
  }

  69% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 69%) 3px, transparent 4px);
  }

  70% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 70%) 3px, transparent 4px);
  }

  71% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 71%) 3px, transparent 4px);
  }

  72% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 72%) 3px, transparent 4px);
  }

  73% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 73%) 3px, transparent 4px);
  }

  74% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 74%) 3px, transparent 4px);
  }

  75% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 75%) 3px, transparent 4px);
  }

  76% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 76%) 3px, transparent 4px);
  }

  77% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 77%) 3px, transparent 4px);
  }

  78% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 78%) 3px, transparent 4px);
  }

  79% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 79%) 3px, transparent 4px);
  }

  80% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 80%) 3px, transparent 4px);
  }

  // **********
  81% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 81%) 3px, transparent 4px);
  }

  82% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 82%) 3px, transparent 4px);
  }

  83% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 83%) 3px, transparent 4px);
  }

  84% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 84%) 3px, transparent 4px);
  }

  85% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 85%) 3px, transparent 4px);
  }

  86% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 86%) 3px, transparent 4px);
  }

  87% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 87%) 3px, transparent 4px);
  }

  88% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 88%) 3px, transparent 4px);
  }

  89% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 89%) 3px, transparent 4px);
  }

  90% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 90%) 3px, transparent 4px);
  }

  91% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 91%) 3px, transparent 4px);
  }

  92% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 92%) 3px, transparent 4px);
  }

  93% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 93%) 3px, transparent 4px);
  }

  94% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 94%) 3px, transparent 4px);
  }

  95% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 95%) 3px, transparent 4px);
  }

  96% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 96%) 3px, transparent 4px);
  }

  97% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 97%) 3px, transparent 4px);
  }

  98% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 98%) 3px, transparent 4px);
  }

  99% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 99%) 3px, transparent 4px);
  }

  100% {
    background-image: radial-gradient(circle at 50% 50%, fade(@red-main, 100%) 3px, transparent 4px);
  }

}

// @keyframes tick {
//   0% {
//     opacity: 0; 

//   }
//   100% {
//     opacity: 1;
//   }
// }