.news {
  font-family: "Glober-Regular";
  @media (max-width: 800px) {
    padding-bottom: 45px;
  }
  @media (min-width: 801px) {
    padding-bottom: 60px;
  }

  &__breadcrumbs {
    background-color: #f5f5f5;
    margin: 0 -9999px;
    padding-top: 50px;
    padding: 50px 9999px 0;
    @media (max-width: 800px) {
      display: none;
    }
  }

  &__title {
    background-color: #f5f5f5;
    color: #202020;
    font-weight: 400;
    margin-left: -9999px;
    margin-right: -9999px;
    padding-left: 9999px;
    padding-right: 9999px;
    @media (max-width: 800px) {
      font-size: 30px;
      line-height: 1.1;
      letter-spacing: -0.8px;
      padding-top: 32px;
    }
    @media (min-width: 801px) {
      font-size: 44px;
      letter-spacing: -1.1px;
      padding-top: 50px;
    }
  }

  &__items {
    align-items: flex-start;
    flex-wrap: wrap;
    padding-bottom: 56px;
    position: relative;
    z-index: 1;
    &:before {
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
    @media (max-width: 800px) {
      padding-top: 6px;
    }
    @media (min-width: 801px) {
      display: flex;
      margin-left: -24px;
      padding-top: 24px;
    }
  }

  &__item {
    flex: 0 0 auto;
    position: relative;
    @media (max-width: 800px) {
      margin-top: 28px;
    }
    @media (min-width: 801px) {
      margin-left: 24px;
      margin-top: 21px;
      width: ~"calc((100% - 72px) / 3)";
    }
  }

  &__more-link {
    border-radius: 50%;
    bottom: 0;
    font-size: 0;
    height: 10px;
    left: 24px;
    margin: 0 auto;
    position: absolute;
    right: 0;
    text-indent: -9999px;
    user-select: none;
    width: 10px;
    z-index: -1;
    &:before {
      animation-fill-mode: both;
      animation: pulse 1.8s infinite ease-in-out;
      animation-delay: -0.32s;
      border-radius: 50%;
      bottom: 0;
      content: '';
      height: 100%;
      margin-right: 12px;
      position: absolute;
      right: 100%;
      transform-origin: 50% 100%;
      width: 100%;
    }
    &:after {
      animation-fill-mode: both;
      animation: pulse 1.8s infinite ease-in-out;
      border-radius: 50%;
      bottom: 0;
      content: '';
      height: 100%;
      left: 100%;
      margin-left: 12px;
      position: absolute;
      transform-origin: 50% 100%;
      width: 100%;
    }
    &:not(.is-active) {
      opacity: 0;
    }
  }

  &__more-link-body {
    animation-fill-mode: both;
    animation: pulse 1.8s infinite ease-in-out;
    animation-delay: -0.16s;
    border-radius: 50%;
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    transform-origin: 50% 100%;
    width: 100%;
  }

  @-webkit-keyframes pulse {
    0%,
    80%,
    100% {
      background-color: fade(#b0b0b0, 35%);
      transform: scale(.8);
    }
    40% {
      background-color: #b0b0b0;
      transform: scale(1.0);
    }
  }
  @keyframes pulse {
    0%,
    80%,
    100% {
      background-color: fade(#b0b0b0, 35%);
      transform: scale(.8);
    }
    40% {
      background-color: #b0b0b0;
      transform: scale(1.0);
    }
  }
}