
/*------------------------------------------------------------------------------
                          FONTS
------------------------------------------------------------------------------*/
@font-face {
  font-family: 'Glober-Regular';
  src: url('../css/fonts/GloberRegular.eot');
  src: url('../css/fonts/GloberRegular.woff2') format('woff2'),
       url('../css/fonts/GloberRegular.woff') format('woff'),
       url('../css/fonts/GloberRegular.ttf') format('truetype'),
       url('../css/fonts/GloberRegular.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Glober-Book';
  src: url('../css/fonts/GloberBook.eot');
  src: url('../css/fonts/GloberBook.woff2') format('woff2'),
       url('../css/fonts/GloberBook.woff') format('woff'),
       url('../css/fonts/GloberBook.ttf') format('truetype'),
       url('../css/fonts/GloberBook.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Glober-Light';
  src: url('../css/fonts/GloberLight.eot');
  src: url('../css/fonts/GloberLight.woff2') format('woff2'),
       url('../css/fonts/GloberLight.woff') format('woff'),
       url('../css/fonts/GloberLight.ttf') format('truetype'),
       url('../css/fonts/GloberLight.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Glober-Bold';
  src: url('../css/fonts/GloberBold.eot');
  src: url('../css/fonts/GloberBold.woff2') format('woff2'),
       url('../css/fonts/GloberBold.woff') format('woff'),
       url('../css/fonts/GloberBold.ttf') format('truetype'),
       url('../css/fonts/GloberBold.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Glober-SemiBold';
  src: url('../css/fonts/GloberSemiBold.eot');
  src: url('../css/fonts/GloberSemiBold.woff2') format('woff2'),
       url('../css/fonts/GloberSemiBold.woff') format('woff'),
       url('../css/fonts/GloberSemiBold.ttf') format('truetype'),
       url('../css/fonts/GloberSemiBold.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Glober-SemiBold-Free';
  src: url('../css/fonts/GloberSemiBoldFree.eot');
  src: url('../css/fonts/GloberSemiBoldFree.woff2') format('woff2'),
       url('../css/fonts/GloberSemiBoldFree.woff') format('woff'),
       url('../css/fonts/GloberSemiBoldFree.ttf') format('truetype'),
       url('../css/fonts/GloberSemiBoldFree.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}