.about {
  font-family: "Glober-Regular";
  @media (max-width: 800px) {
    padding-bottom: 45px;
  }
  @media (min-width: 801px) {
    padding-bottom: 60px;
  }

  &__breadcrumbs {
    background-color: #f5f5f5;
    margin: 0 -9999px;
    padding-top: 50px;
    padding: 50px 9999px 0;
    @media (max-width: 800px) {
      display: none;
    }
  }

  &__title {
    background-color: #f5f5f5;
    color: #202020;
    font-weight: 400;
    margin-left: -9999px;
    margin-right: -9999px;
    padding-left: 9999px;
    padding-right: 9999px;
    @media (max-width: 800px) {
      font-size: 30px;
      line-height: 1.1;
      letter-spacing: -0.8px;
      padding-top: 32px;
    }
    @media (min-width: 801px) {
      font-size: 44px;
      letter-spacing: -1.1px;
      padding-top: 50px;
    }
  }

  &__description {
    background-color: #f5f5f5;
    color: #565656;
    line-height: 1.75;
    margin-left: -9999px;
    margin-right: -9999px;
    padding-left: 9999px;
    padding-right: 9999px;
    @media (max-width: 800px) {
      font-size: 14px;
      padding-top: 30px;
    }
    @media (min-width: 801px) {
      font-size: 16px;
      padding-top: 45px;
    }
  }

  &__advantages {
    background-color: #f5f5f5;
    margin-left: -9999px;
    margin-right: -9999px;
    padding-left: 9999px;
    padding-right: 9999px;
    // min-width: 150px;
    @media (max-width: 800px) {
      padding-bottom: 49px;
      padding-top: 45px;
    }
    @media (min-width: 801px) {
      padding-bottom: 62px;
      padding-top: 65px;
    }
  }

  &__advantages-list-wrapper {
    overflow: hidden;
    @media (max-width: 1200px) {
      margin: 0 -20px;
    }
    @media all and (max-width: @tablet) {
      margin: 0 -28px;
    }
    @media all and (max-width: 768px) {
      margin: 0 -10px;
    }
  }

  &__advantages-list {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 34px;
    @media (max-width: 1200px) {
      margin: 0 -20px;
      padding-left: 20px;
      padding-right: 20px;
    }
    @media all and (max-width: @tablet) {
      margin: 0 -28px;
      padding-left: 28px;
      padding-right: 28px;
    }
    @media all and (max-width: 768px) {
      margin: 0 -10px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  &__advantages-list-wrapper &__advantages-list {
    margin: 0;
  }

  &__advantages-scrolltrack {
    border-radius: 3.5px;
    border: 1px solid #d8d8d8;
    opacity: 0;
    height: 7px;
    overflow: hidden;
  }
  &__advantages._scrollbar &__advantages-scrolltrack {
    opacity: 1;
  }

  &__advantages-scrollbar {
    background-color: #d8d8d8;
    border-radius: 2.5px;
    height: 100%;
    position: relative;
  }

  &__advantage {
    flex: 0 0 auto;
    min-width: 190px;
    & + & {
      margin-left: 32px;
      min-width: 190px;

    }
  }

  &__advantage-logo {
    border-radius: 50%;
    border: 1px solid @red-main; // earlier color: #dd2a1b
    display: block;
    margin: 0 auto 16px;
    position: relative;
    @media (max-width: 800px) {
      height: 70px;
      width: 70px;
    }
    @media (min-width: 801px) {
      height: 80px;
      width: 80px;
    }
  }

  &__advantage-logo-icon {
    fill: #FF2513; // earlier color: #dd2a1b
    height: auto;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 40%;
  }

  &__advantage-title {
    color: #878787;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: -0.3px;
    text-align: center;
  }

  &__advantage-title-primary {
    color: #565656;
    display: block;
    font-weight: 600;
    letter-spacing: 0.2px;
    margin-bottom: 4px;
    text-transform: uppercase;
  }

  &__props {
    margin-top: 43px;
  }

  &__props-title {
    color: #202020;
    font-weight: 400;
    @media (max-width: 800px) {
      font-size: 24px;
      letter-spacing: -0.6px;
    }
    @media (min-width: 801px) {
      font-size: 30px;
      letter-spacing: -0.8px;
    }
  }

  &__props-content {
    color: #565656;
    display: block;
  }

  &__props-content-body {
    display: inline-block;
    padding-top: 5px;
    width: 100%;
  }

  &__props-item {
    display: block;
    @media (max-width: 800px) {
      margin-top: 28px;
    }
    @media (min-width: 801px) {
      margin-top: 32px;
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5) {
        float: left;
        padding-right: 1%;
        width: 51%;
      }
      &:nth-child(6),
      &:nth-child(7),
      &:nth-child(8),
      &:nth-child(9) {
        margin-left: 50%;
      }

    }
  }

  &__props-name {
    display: block;
    font-weight: 600;
    text-align: left;
    text-transform: uppercase;
    @media (max-width: 800px) {
      font-size: 13px;
      line-height: 1.92;
      letter-spacing: 0.3px;
    }
    @media (min-width: 801px) {
      font-size: 15px;
      line-height: 1.67;
      letter-spacing: 0.4px;
    }
  }

  &__props-value {
    display: block;
    font-size: 16px;
    line-height: 1.38;
    margin-top: 14px;
  }

  &__vacancies {
    background-color: #f5f5f5;
    margin-left: -9999px;
    margin-right: -9999px;
    padding-left: 9999px;
    padding-right: 9999px;
    @media (max-width: 800px) {
      margin-top: 52px;
      padding-bottom: 30px;
      padding-top: 38px;
    }
    @media (min-width: 801px) {
      margin-top: 46px;
      padding-bottom: 54px;
      padding-top: 60px;
    }
  }

  &__vacancies-title {
    color: #202020;
    font-weight: 400;
    @media (max-width: 800px) {
      font-size: 30px;
      letter-spacing: -0.7px;
      margin-bottom: 40px;
    }
    @media (min-width: 801px) {
      font-size: 44px;
      letter-spacing: -1.1px;
      margin-bottom: 50px;
    }
  }

  &__vacancy {
    color: #565656;
    @media (max-width: 800px) {
      & + & {
        border-top: 1px solid fade(#565656, 8%);
        margin-top: 37px;
        padding-top: 36px;
      }
    }
    @media (min-width: 801px) {
      & + & {
        border-top: 1px solid fade(#565656, 8%);
        margin-top: 45px;
        padding-top: 46px;
      }
    }
  }

  &__vacancy-title {
    font-weight: 600;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    @media (max-width: 800px) {
      font-size: 15px;
      line-height: 1.66;
    }
    @media (min-width: 801px) {
      font-size: 17px;
      line-height: 1.47;
    }
  }

  &__vacancy-content {
    @media (max-width: 800px) {
      font-size: 13px;
      line-height: 1.84;
      margin-top: 16px;
    }
    @media (min-width: 801px) {
      font-size: 16px;
      line-height: 1.75;
      margin-top: 15px;
    }
  }

  &__media {
    margin-top: 35px;
    @media (max-width: 800px) {
    }
    @media (min-width: 801px) {
    }
  }

  &__media-title {
    color: #202020;
    font-weight: 400;
    @media (max-width: 800px) {
      font-size: 30px;
      letter-spacing: -0.7px;
      margin-bottom: 26px;
    }
    @media (min-width: 801px) {
      font-size: 44px;
      letter-spacing: -1.1px;
      margin-bottom: 32px;
    }
  }

  &__media-subtitle {
    color: #565656;
    font-weight: 400;
    @media (max-width: 800px) {
      font-size: 12px;
      line-height: 1.83;
    }
    @media (min-width: 801px) {
      font-size: 16px;
      line-height: 1.75;
    }
  }

  &__media-items {
    align-items: flex-start;
    flex-wrap: wrap;
    @media (max-width: 800px) {
      margin-top: 40px;
    }
    @media (min-width: 801px) {
      display: flex;
      margin-left: -70px;
      padding-top: 1px;
    }
  }

  &__media-item {
    align-items: flex-start;
    display: flex;
    flex: 0 0 auto;
    @media (max-width: 800px) {
      & + & {
        margin-top: 26px;
      }
    }
    @media (min-width: 801px) {
      margin: 49px 0 0 70px;
      max-width: 35%;
    }
  }

  &__media-item-icon {
    display: block;
    flex: 0 0 auto;
    fill: @red-main; // earlier color: #dd2a1b
    margin-right: 12px;
    @media (max-width: 800px) {
      height: 22px;
      width: 20px;
    }
    @media (min-width: 801px) {
      height: 30px;
      width: 28px;
    }
  }

  &__media-item-name {
    align-self: center;
    color: #878787;
    flex: 1 1 60px;
    @media (max-width: 800px) {
      font-size: 13px;
    }
    @media (min-width: 801px) {
      font-size: 16px;
      padding-top: 2px;
    }
  }
}