.cart-success {
  font-family: "Glober-Regular";
  @media (max-width: 800px) {
    padding-bottom: 40px;
    padding-top: 40px;
  }
  @media (min-width: 801px) {
    padding-bottom: 70px;
    padding-top: 70px;
  }

  &__title {
    color: #30576f;
    font-weight: 400;
    line-height: 1.2;
    position: relative;
    @media (max-width: 800px) {
      font-size: 30px;
      letter-spacing: -.6px;
      padding-left: 46px;
    }
    @media (min-width: 801px) {
      font-size: 44px;
      letter-spacing: -.8px;
      padding-left: 70px;
    }
  }

  &__title-icon {
    display: block;
    fill: #30576f;
    left: 0;
    position: absolute;
    top: -2px;
    @media (max-width: 800px) {
      height: 30px;
      width: 30px;
    }
    @media (min-width: 801px) {
      height: 44px;
      width: 44px;
    }
  }

  &__content {
    color: #565656;
    line-height: 1.7;
    @media (max-width: 800px) {
      font-size: 15px;
      margin-top: 28px;
    }
    @media (min-width: 801px) {
      font-size: 16px;
      margin-top: 36px;
    }
  }

  &__content-highlight {
    color: #202020;
  }
}