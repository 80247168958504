.footer {
  border-top: 1px solid #e6e6e6;
  width: 100%;
  &--highlighted {
    background-color: #f5f5f5;
  }

  .footer__content {
    max-width: 1200px;
    margin: 30px auto 25px;
    display: flex;
    justify-content: space-between;
    @media all and (max-width: @tablet) {
      display: block;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    color: #4a4a4a;
    @media all and (max-width: @tablet) {
      text-align: center;
    }
  }

  &__link {
    color: #b0b0b0;
    &:hover {
      color: #8a8a8a;
    }
  }

}