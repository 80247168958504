.catalog-tiles {
  padding-bottom: 56px;
  position: relative;

  &__items {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 900px) {
      justify-content: space-around;
      margin-left: -20px;
      margin-right: -20px;
      margin-top: -20px;
    }
    @media (min-width: 901px) {
      justify-content: space-between;
    }
  }

  &__item {
    flex: 0 0 auto;
    @media (max-width: 900px) {
      // margin: 20px 0 0 20px;
      margin: 20px 20px 0;
    }
    @media (min-width: 901px) {
      &:nth-child(n+3) {
        margin-top: 32px;
      }
    }
  }

  &__more-link {
    border-radius: 50%;
    bottom: 0;
    font-size: 0;
    height: 10px;
    left: 24px;
    margin: 0 auto;
    position: absolute;
    right: 0;
    text-indent: -9999px;
    user-select: none;
    width: 10px;
    z-index: -1;
    &:before {
      animation-fill-mode: both;
      animation: pulse 1.8s infinite ease-in-out;
      animation-delay: -0.32s;
      border-radius: 50%;
      bottom: 0;
      content: '';
      height: 100%;
      margin-right: 12px;
      position: absolute;
      right: 100%;
      transform-origin: 50% 100%;
      width: 100%;
    }
    &:after {
      animation-fill-mode: both;
      animation: pulse 1.8s infinite ease-in-out;
      border-radius: 50%;
      bottom: 0;
      content: '';
      height: 100%;
      left: 100%;
      margin-left: 12px;
      position: absolute;
      transform-origin: 50% 100%;
      width: 100%;
    }
    &:not(.is-active) {
      opacity: 0;
    }
  }

  &__more-link-body {
    animation-fill-mode: both;
    animation: pulse 1.8s infinite ease-in-out;
    animation-delay: -0.16s;
    border-radius: 50%;
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    transform-origin: 50% 100%;
    width: 100%;
  }

  @-webkit-keyframes pulse {
    0%,
    80%,
    100% {
      background-color: fade(#b0b0b0, 35%);
      transform: scale(.8);
    }
    40% {
      background-color: #b0b0b0;
      transform: scale(1.0);
    }
  }
  @keyframes pulse {
    0%,
    80%,
    100% {
      background-color: fade(#b0b0b0, 35%);
      transform: scale(.8);
    }
    40% {
      background-color: #b0b0b0;
      transform: scale(1.0);
    }
  }
}