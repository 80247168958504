.icon {
	display: inline-block;
	width: 1em;
	height: 1em;
	fill: currentColor;
}

.icon-arrow {
	font-size:(31.49/10)*1rem;
	width:(31.49/31.49)*1em;
}
.icon-arrow-right {
	font-size:(477.18/10)*1rem;
	width:(477.18/477.18)*1em;
}
.icon-balance {
	font-size:(33/10)*1rem;
	width:(33/33)*1em;
}
.icon-box {
	font-size:(77.02/10)*1rem;
	width:(77.02/77.02)*1em;
}
.icon-calendar {
	font-size:(512/10)*1rem;
	width:(512/512)*1em;
}
.icon-card {
	font-size:(512/10)*1rem;
	width:(512/512)*1em;
}
.icon-cart {
	font-size:(486.57/10)*1rem;
	width:(452.17/486.57)*1em;
}
.icon-clock {
	font-size:(41.3/10)*1rem;
	width:(41.3/41.3)*1em;
}
.icon-download {
	font-size:(479/10)*1rem;
	width:(479/479)*1em;
}
.icon-facebook {
	font-size:(310/10)*1rem;
	width:(310/310)*1em;
}
.icon-file {
	font-size:(512/10)*1rem;
	width:(512/512)*1em;
}
.icon-happy {
	font-size:(559.46/10)*1rem;
	width:(559.46/559.46)*1em;
}
.icon-image-file {
	font-size:(512/10)*1rem;
	width:(512/512)*1em;
}
.icon-instagram {
	font-size:(300/10)*1rem;
	width:(300/300)*1em;
}
.icon-linkedin {
	font-size:(430.117/10)*1rem;
	width:(430.117/430.117)*1em;
}
.icon-list {
	font-size:(60.12/10)*1rem;
	width:(60.12/60.12)*1em;
}
.icon-marker {
	font-size:(512/10)*1rem;
	width:(512/512)*1em;
}
.icon-nls {
	font-size:(63/10)*1rem;
	width:(50/63)*1em;
}
.icon-odnoklass {
	font-size:(95.481/10)*1rem;
	width:(95.481/95.481)*1em;
}
.icon-opened-box {
	font-size:(466.48/10)*1rem;
	width:(466.48/466.48)*1em;
}
.icon-padlock {
	font-size:(482.8/10)*1rem;
	width:(482.8/482.8)*1em;
}
.icon-paper-plane {
	font-size:(512/10)*1rem;
	width:(512/512)*1em;
}
.icon-pdf-file {
	font-size:(533.333/10)*1rem;
	width:(533.333/533.333)*1em;
}
.icon-pig {
	font-size:(487.6/10)*1rem;
	width:(487.6/487.6)*1em;
}
.icon-piggy-bank {
	font-size:(487.6/10)*1rem;
	width:(487.6/487.6)*1em;
}
.icon-sad {
	font-size:(567.42/10)*1rem;
	width:(567.42/567.42)*1em;
}
.icon-security-badge {
	font-size:(737.407/10)*1rem;
	width:(737.408/737.407)*1em;
}
.icon-smile {
	font-size:(567.42/10)*1rem;
	width:(567.42/567.42)*1em;
}
.icon-success {
	font-size:(52/10)*1rem;
	width:(52/52)*1em;
}
.icon-telegram {
	font-size:(300/10)*1rem;
	width:(300/300)*1em;
}
.icon-truck {
	font-size:(512/10)*1rem;
	width:(512/512)*1em;
}
.icon-twitter {
	font-size:(310/10)*1rem;
	width:(310/310)*1em;
}
.icon-vk {
	font-size:(304.36/10)*1rem;
	width:(304.36/304.36)*1em;
}
.icon-warning {
	font-size:(294.95/10)*1rem;
	width:(294.95/294.95)*1em;
}
.icon-youtube {
	font-size:(310/10)*1rem;
	width:(310/310)*1em;
}
