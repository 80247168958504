.catalog-menu {
  font-family: "Glober-Regular";
  @media all and (max-width: 900px) {
    margin-left: -28px;
    margin-right: -28px;
  }
  @media all and (max-width: 768px) {
    margin-left: -10px;
    margin-right: -10px;
  }

  &__title {
    margin-bottom: 50px;
    @media (max-width: 900px) {
      height: 1px;
      left: -10000px;
      overflow: hidden;
      position: absolute;
      top: auto;
      width: 1px;
    }
  }

  &__toggle-input {
    display: none;
  }

  &__toggle-button {
    border-bottom: 1px solid fade(#161616, 8%);
    border-top: 1px solid fade(#161616, 8%);
    color: #202020;
    font-family: "Glober-Regular";
    overflow: hidden;
    padding: 15px 48px 13px 28px;
    position: relative;
    text-overflow: ellipsis;
    user-select: none;
    white-space: nowrap;
    &--small {
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0.4px;
      line-height: 1.79;
    }
    &--big {
      font-size: 20px;
      letter-spacing: -0.5px;
      line-height: 1.25;
    }
    @media (max-width: 900px) {
      display: block;
    }
  }

  &__items {
    @media (max-width: 900px) {
      display: none;
    }
  }
  &__toggle-input:checked ~ &__items {
    display: block;
  }

  &__item {
    @media all and (max-width: 900px) {
      margin-top: -1px;
    }
    & + & {
      @media all and (min-width: 901px) {
        margin-top: 38px;
      }
    }
  }

  &__link {
    color: #3a3a3a;
    font-family: "Glober-Regular";
    font-weight: 600;
    letter-spacing: 0.4px;
    &--active {
      color: @red-main; // earlier color: #ff2513
    }
    @media all and (max-width: 900px) {
      border-bottom: 1px solid fade(#161616, 8%);
      display: block;
      font-size: 14px;
      line-height: 1.79;
      padding: 11px 28px 10px;
    }
    @media all and (min-width: 901px) {
      font-size: 16px;
      line-height: 1.56;
    }
  }

  &__subitems {
    @media all and (max-width: 900px) {
      background-color: #f5f5f5;
      display: none;
    }
    @media all and (min-width: 901px) {
      margin: 22px 0 0 20px;
    }
  }
  &__link--active ~ &__subitems {
    @media all and (max-width: 900px) {
      display: block;
    }
  }

  &__subitem {
    & + & {
      @media all and (min-width: 901px) {
        margin-top: 24px;
      }
    }
  }

  &__sublink {
    font-family: "Glober-Regular";
    &--active {
      color: #ff2513;
    }
    &:hover {
      color: @red-main;
    }
    @media all and (max-width: 900px) {
      color: #3a3a3a;
      display: block;
      font-size: 12px;
      letter-spacing: 0.3px;
      line-height: 2.08;
      padding: 9px 28px 6px 49px;
      text-align: left;
    }
    @media all and (min-width: 901px) {
      color: #565656;
      font-size: 14px;
      letter-spacing: 0.4px;
      line-height: 1.79;
    }
  }
}