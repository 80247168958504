.author-rights {
	margin-top: 8px;
	margin-left: -30px;
	a {
		color: #8a8a8a;
	}
	span {
		display: inline;
		color: lighten(#8a8a8a, 15%);
	}	
}