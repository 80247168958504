.events-calendar {
  align-items: flex-start;
  background-color: #fff;
  box-shadow: -2.3px 4.4px 15px 0 rgba(0, 0, 0, .04);
  display: flex;
  font-family: "Glober-Regular";
  position: relative;
  @media (max-width: 800px) {
  }
  @media (min-width: 1021px) {
    &::before {
      background-color: fade(#000, 8%);
      content: '';
      height: 100%;
      left: 0;
      margin: 0 auto;
      position: absolute;
      right: 0;
      top: 0;
      width: 1px;
    }
  }

  &__button {
    display: block;
    position: absolute;
    top: 0;
    @media (max-width: 800px) {
      height: 53px;
      width: 53px;
    }
    @media (min-width: 801px) {
      height: 63px;
      width: 68px;
    }
  }
  &__button--previous {
    border-left: 1px solid transparent;
    border-right: 1px solid fade(#000, 8%);
    left: 0;
  }
  &__button--next {
    border-left: 1px solid fade(#000, 8%);
    border-right: 1px solid transparent;
    right: 0;
  }

  &__button-icon {
    bottom: 0;
    fill: #b0b0b0;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    transition: fill .1s ease;
    &:hover {
      fill: #000;
    }
    @media (max-width: 800px) {
      height: 17px;
      width: 17px;
    }
    @media (min-width: 801px) {
      height: 20px;
      width: 20px;
    }
  }
  &__button-icon--previous {
    transform-origin: 50% 50%;
    transform: scaleX(-1);
    transition: fill .1s ease;
    &:hover {
      fill: #000;
    }
  }

  &__month {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    flex: 0 0 auto;
    @media (max-width: 800px) {
      padding-bottom: 26px;
    }
    @media (min-width: 801px) {
      padding: 0 50px 28px 13px;
    }
    @media (max-width: 1020px) {
      display: none;
      width: 100%;
    }
    @media (min-width: 1021px) {
      width: 50%;
    }
  }
  &__month--current {
    @media (max-width: 1020px) {
      display: flex;
    }
  }

  &__month-name {
    border-bottom: 1px solid fade(#000, 8%);
    color: #3a3a3a;
    flex: 0 0 auto;
    white-space: nowrap;
    text-align: center;
    @media (max-width: 800px) {
      font-size: 14px;
      line-height: 51px;
      margin-bottom: 20px;
      padding: 2px 53px 0;
      width: 100%;
    }
    @media (min-width: 801px) {
      font-size: 16px;
      letter-spacing: .2px;
      line-height: 61px;
      margin-bottom: 30px;
      margin-left: -13px;
      padding: 2px 68px 0;
      width: ~"calc(100% + 63px)";
    }
  }

  &__cell {
    flex: 0 0 auto;
    text-align: center;
    @media (max-width: 800px) {
      margin-left: ~"calc((100% - 196px) / 14)";
      margin-right: ~"calc((100% - 196px) / 14)";
      width: 28px;
      /*&:nth-of-type(7n+2),
      &:nth-of-type(7n+3),
      &:nth-of-type(7n+4),
      &:nth-of-type(7n+5),
      &:nth-of-type(7n+6),
      &:nth-of-type(7n+7) {
      }*/
    }
    @media (min-width: 801px) {
      margin-left: ~"calc((100% - 287px) / 7)";
      width: 41px;
    }
  }

  &__week-day-name {
    color: #878787;
    font-weight: 600;
    letter-spacing: .2px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    @media (max-width: 800px) {
      font-size: 10px;
    }
    @media (min-width: 801px) {
      font-size: 15px;
      margin-bottom: 14px;
    }
  }
  &__week-day-name--highlighted {
    color: @red-main; // earlier color: #dd2a1b
  }

  &__day {
    border-radius: 50%;
    border-style: solid;
    border-width: 1px;
    @media (max-width: 800px) {
      font-size: 12px;
      height: 28px;
      line-height: 26px;
      margin-top: 5px;
      padding-top: 2px;
    }
    @media (min-width: 801px) {
      font-size: 18px;
      line-height: 39px;
      height: 41px;
      margin-top: 8px;
      padding-top: 2px;
    }
  }
  &__day--disabled {
    border-color: transparent;
    color: #cacaca;
  }
  &__day--enabled {
    border-color: transparent;
    color: #000;
  }
  &__day--finished {
    background-color: #ebebeb;
    border-color: transparent;
    color: #000;
    position: relative;
  }
  &__day--current {
    border-color: @red-main; // earlier color: #dd2a1b
  }
  &__day--active {
    background-color: @red-main; // earlier color: #dd2a1b
    border-color: transparent;
    color: #fff;
    position: relative;
  }

  &__day-open-input {
    display: none;
  }

  &__day-open-button {
    border-radius: 50%;
    cursor: pointer;
    height: 100%;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    user-select: none;
    width: 100%;
  }

  &__day-content {
    background-color: #fff;
    box-shadow: -2.3px 4.4px 15px 0 rgba(0, 0, 0, 0.04);
    display: none;
    line-height: 1.2;
    text-align: left;
    z-index: 1;
    &:before {
      border-bottom: 12px solid transparent;
      border-top: 12px solid transparent;
      bottom: 0;
      content: '';
      height: 0;
      position: absolute;
      top: 0;
      width: 0;
      margin: auto 0;
    }
    @media (max-width: @tablet) {
      padding-left: 28px;
      padding-right: 28px;
    }
    @media (max-width: 768px) {
      padding-left: 10px;
      padding-right: 10px;
    }
    @media (min-width: 769px) and (max-width: 1020px) {
      padding-left: 20px;
      padding-right: 20px;
    }
    @media (max-width: 1020px) {
      left: 0;
      max-height: 100%;
      overflow-y: auto;
      padding-top: 38px;
      position: fixed;
      top: 0;
      width: 100%;
      &::after {
        content: '';
        display: block;
        height: 25px;
      }
    }
    @media (min-width: 1021px) {
      border-radius: 5px;
      padding: 22px 34px 30px 24px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  &__day-content--left {
    @media (min-width: 1021px) {
      right: ~"calc(100% + 10px)";
      &:before {
        border-left: 18px solid #fff;
        right: -18px;
      }
    }
  }
  &__day-content--right {
    @media (min-width: 1021px) {
      left: ~"calc(100% + 10px)";
      &:before {
        border-right: 18px solid #fff;
        left: -18px;
      }
    }
  }
  &__day-content--finished {
    @media (min-width: 1021px) {
      width: 374px;
    }
  }
  &__day-content--active {
    @media (min-width: 1021px) {
      width: 522px;
    }
  }
  &__day-open-input:checked ~ &__day-content {
    display: block;
  }

  &__item {
    & + & {
      border-top: 1px solid fade(#000, 8%);
      margin-top: 25px;
      padding-top: 24px;
    }
  }

  &__day-content-close-button {
    cursor: pointer;
    height: 20px;
    position: absolute;
    right: 15px;
    top: 16px;
    width: 20px;
    &::before,
    &::after {
      background-color: #878787;
      bottom: 0;
      content: '';
      height: 20px;
      left: 0;
      margin: auto;
      position: absolute;
      right: 0;
      top: 0;
      transform-origin: 50% 50%;
      width: 1px;
    }
    &::before {
      transform: rotate(45deg);
    }
    &::after {
      transform: rotate(-45deg);
    }
    @media (max-width: 1020px) {
      display: block;
    }
    @media (min-width: 1021px) {
      display: none;
    }
  }
}