.calendar-event-preview {
  align-items: flex-start;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  font-family: "Glober-Regular";

  &__title {
    color: #202020;
    flex: 0 0 auto;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.2px;
    line-height: 1.3;
    width: 100%;
  }

  &__description {
    color: #565656;
    flex: 0 0 auto;
    font-size: 12px;
    letter-spacing: 0.2px;
    line-height: 1.67;
    width: 100%;
    @media (max-width: 800px) {
      margin-bottom: 15px;
      margin-top: 18px;
    }
    @media (min-width: 801px) {
      margin-bottom: 19px;
      margin-top: 18px;
    }
  }

  &__participate-link {
    border-radius: 16px;
    border: 1px solid #dd2a1b;
    color: #dd2a1b;
    flex: 0 0 auto;
    font-size: 12px;
    font-weight: 600;
    line-height: 28px;
    height: 32px;
    margin-right: auto;
    overflow: hidden;
    padding-top: 2px;
    position: relative;
    text-align: center;
    text-overflow: ellipsis;
    transition: all .3s ease;
    white-space: nowrap;
    width: 106px;
    &:hover {
      background-color: #dd2a1b;
      color: #fff;
    }
    @media (max-width: 530px) {
      margin-bottom: 2px;
      margin-right: 28px;
      top: 7px;
    }
  }

  &__time {
    color: #878787;
    flex: 0 0 auto;
    font-size: 12px;
    padding-left: 33px;
    position: relative;
    white-space: nowrap;
    @media (max-width: 530px) {
      margin-bottom: 10px;
      width: ~"calc(100% - 136px)";
    }
    @media (min-width: 531px) {
      align-self: center;
    }
  }

  &__time-icon {
    fill: #878787;
    left: 0;
    position: absolute;
    top: -4px;
    height: 20px;
    width: 21px;
  }

  &__status {
    color: #878787;
    flex: 0 0 auto;
    font-size: 12px;
    padding-left: 22px;
    position: relative;
    @media (max-width: 800px) {
      margin-top: 19px;
      width: 100%;
    }
    @media (min-width: 801px) {
      margin-left: auto;
    }
  }

  &__status-icon {
    fill: #878787;
    height: 17px;
    left: 0;
    position: absolute;
    top: -4px;
    width: 17px;
  }

  &__duration {
    align-self: center;
    color: #878787;
    flex: 0 1 auto;
    font-size: 12px;
    padding-left: 25px;
    position: relative;
    white-space: nowrap;
    @media (max-width: 530px) {
      margin-left: 134px;
    }
    @media (min-width: 531px) {
      margin-left: auto;
    }
  }

  &__duration-icon {
    fill: #878787;
    height: 16px;
    left: 0;
    position: absolute;
    top: -2px;
    width: 16px;
  }

  &__price {
    align-self: center;
    color: #878787;
    flex: 0 0 auto;
    font-size: 12px;
    margin-left: auto;
    position: relative;
    white-space: nowrap;
    @media (max-width: 800px) {
      padding-left: 28px;
    }
    @media (min-width: 801px) {
      padding-left: 25px;
    }
  }

  &__price-icon {
    fill: #878787;
    left: 0;
    position: absolute;
    top: -2px;
    @media (max-width: 800px) {
      height: 13px;
      width: 15px;
    }
    @media (min-width: 801px) {
      height: 15px;
      width: 17px;
    }
  }
  &__price-icon--free {
    fill: #dd2a1b;
  }
}