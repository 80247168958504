.contacts {
  height: 40px;

  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media all and (max-width: 1024px) {
    padding: 0 20px;
  }
  &-wrapper-line {
    border-bottom: 1px solid #e9e9e9;
  }
  &__phone {
    font: 14px 'Glober-Regular';
    color: #a6a6a6;
    @media all and (max-width: 768px) {
      color: #909090;
    }
  }
  &__list {
    width: 130px;
    margin-right: 0;
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
    @media all and (max-width: 768px) {
      display: none;
    }
  }

  &__item {
    display: inline-block;
    margin: 0 11px;
    &:last-child {
      margin-right: 0;
    }
    &:first-child {
      margin-left: 0;
    }
  }

  &__icon {
    font-size: 1rem;
  }
}