.bottom {
  display: inline-block;
  height: 50px;
  border-radius: 25px;
  background: @red-main;
  cursor: pointer;
  color: white;
  font-size: 14px;
  padding: 18px 26px;
  vertical-align: middle;
  transition: background-color 300ms;
  @media all and (max-width: 800px) {
    height: 36px;
    font-size: 12px;
    padding: 12px 20px;
  }
  &:hover {
    background: @red-main-hover;
    transition: background-color 300ms;
  }
  &:hover &__icon {
    position: relative;
    left: 5px;
    transition: left 200ms;
  }
  &__icon {
    position: relative;
    left: 0;
    margin-left: 10px;
    font-size: 0.9rem;
    vertical-align: middle;
    float: right;
    transition: left 200ms;
  }
  &--center {
    // display: block;
    margin: 0 auto;
  }
}