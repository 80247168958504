.main-slider {
  width: 100%;
  height: 640px;
  margin: 0;
  padding: 0;
  padding-bottom: 50px;
  @media all and (max-width: 1200px) {
    height: 580px;
  }
  @media all and (max-width: 800px) {
    margin-top: 0;
    height: auto;
  }
  @media all and (max-width: @mobile) {
    height: 500px;
  }

  &__item {
    opacity: 0;
    @media all and (max-width: 800px) {
      display: flex;
      flex-direction: column;
    }
  }
  &__content {
    position: relative;
    font-size: 0;
    height: auto;
    display: block;
    // border: 1px solid red;   // DEBUG
    padding: 0;
    margin: 0 auto;
    @media all and (max-width: 800px) {
      padding: 0;
      // margin-top: 30px;
    }
    @media all and (max-width: 768px) {
      height: auto;
    }
    @media all and (max-width: @mobile) {
      // margin-top: 70px;
    }
  }
  &__left-side,
  &__right-side {
    position: relative;
    height: 640px;
    max-height: 640px;
    display: inline-block;
    vertical-align: top;
    @media all and (max-width: 1200px) {
      height: 515px;
      max-height: 515px;
    }
    @media all and (max-width: 800px) {
      height: 330px;
      max-height: 330px;
    }
  }
  &__left-side {
    padding-left: 19%;
    @media all and (max-width: 1366px) {
      padding-left: 7%;
    }
    @media all and (max-width: 1200px) {
      padding-left: 20px;
    }
    @media all and (max-width: @tablet) {
      padding: 0 30px;
    }

    @media all and (max-width: 800px) {
      position: static;
      padding-left: 30px;
      padding-right: 30px;
      height: auto;
    }

  }

  &__right-side {
    position: absolute;
    top: 0;
    transform: skew(26deg);
    z-index: 99999;
    width: 60%;
    margin-left: 50%;
    overflow: hidden;
    padding-left: 50px;
    @media all and (max-width: 1366px) {
      margin-left: 55%;
      width: 60%;
    }
    @media all and (max-width: @tablet) {
      margin-left: 42%;
      width: 75%;
    }
    @media all and (max-width: 800px) {
      // border: 1px solid red;
      width: 100%;
      position: static;
      transform: none;
      margin: 0;
      height: 500px;
      padding-left: 0;
    }
    @media all and (max-width: 640px) {
      height: 400px;
    }
    @media all and (max-width: 480px) {
      height: 300px;
    }
  }

  &__header {
    max-width: 420px;
    overflow: hidden;
    // min-height: 114px;
    max-height: 140px;
    margin: 100px 0 25px;
    overflow: hidden;
    white-space: nowrap;
    font: 48px 'Glober-Regular';

    @media all and (max-width: 1024px) {
      font-size: 42px;
      max-width: 250px;
      overflow: hidden;
    }
    @media all and (max-width: 900px) {
      font-size: 36px;
      max-width: 220px;
    }
    @media all and (max-width: 800px) {
      margin: 0;
      font-size: 30px;
      min-height: auto;
      margin: 0 0 15px 0;
    }
  }

  &__description {
    max-width: 400px;
    max-height: 104px;
    margin-bottom: 40px;
    overflow: hidden;
    font: 17px 'Glober-Regular';
    line-height: 26px;
    color: #565656;
    text-overflow: ellipsis;

    p {
      @media all and (max-width: 800px) {
        max-height: 100px;
        overflow: hidden;
      }
    }

    @media all and (max-width: 1500px) {
      max-width: 330px;
    }

    @media all and (max-width: 1280px) {
      max-width: 380px;
    }

    @media all and (max-width: 1100px) {
      max-width: 380px;
    }

    @media all and (max-width: @tablet) {
      max-width: 330px;
      font-size: 14px;
    }

    @media all and (max-width: 1000px) {
      max-width: 250px;
      margin-bottom: 30px;
    }

    @media all and (max-width: 800px) {
      max-width: 90%;
      line-height: 20px;
      height: 100px;
      display: table-cell;
      vertical-align: middle;
    }

  }

  &__icon-list {
    position: absolute;
    bottom: 50px;
    display: flex;
    align-items: center;
    margin-left: -55px; // earlier set 40px
    @media all and (max-width: 1200px) {
      bottom: 0;
    }
    @media all and (max-width: 800px) {
      display: none;
    }
  }
  
  &__icon-item {
    position: relative;
    min-width: 190px;
    width: 190px;
    display: inline-block;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
    &::after {
      content: '';
      position: absolute;
      display: block;
      width: 50px;
      height: 1px;
      top: 37px;
      right: -25px;
      background: #dfdfdf;
    }
    &:last-child::after {
      display: none;
    }
    @media all and (max-width: @tablet) {
      width: 170px;
      min-width: 170px;
      padding: 0;
    }
    @media all and (max-width: 900px) {
      width: 150px;
    }
  }

  &__icon {
    align-items: center;
    border-radius: 50%;
    border: 1px solid rgba(86,86,86, 0.1);
    color: #565656;
    display: flex;
    font-size: 1.7rem;
    height: 80px;
    justify-content: center;
    margin: 0 auto 4px;
    position: relative;
    width: 80px;
    @media all and (max-width: @tablet) {
      font-size: 1.5rem;
      height: 70px;
      width: 70px;
    }
  }

  &__icon .icon {
    flex: 0 0 auto;
    height: auto;
  }

  &__icon-header {
    margin-top: 15px;
    text-transform: uppercase;
    font: 12px 'Glober-SemiBold';
    color: #565656;
    text-align: center;
    @media all and (max-width: @tablet) {
      font-size: 11px;
    }
  }
  &__icon-subheader {
    font: 12px 'Glober-Regular';
    color: #565656;
    text-align: center;
    @media all and (max-width: @tablet) {
      font-size: 11px;
    }
  }

  &__bottom {
    margin-bottom: 100px;
    @media all and (max-width: @tablet) {
      margin-bottom: 10px;
    }
    @media all and (max-width: 800px) {
      margin: 15px 0 0 0;
    }
  }

  &__figure {
    // border: 1px solid red;   // DEBUG
    overflow: hidden;
    
    &--img {
      position: relative;
      height:  calc(~"100% + 2px"); 
      // box-shadow: 10px 10px 30px #000000;
      z-index: 3;
      background: #ffffff;
      @media all and (max-width: 800px) {
        position: relative;
        // border: 1px solid red;
        // box-shadow: none;
        height: 100%;
        &::after {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          bottom: -2px;
          border: 65px solid transparent;
          border-bottom: 0px solid #f5f5f5;
          border-left: 100vw solid #f5f5f5;
        }
      }
    }
    &--frame-img {
      position: relative;
      top: 0;
      left: -155px;
      transform: skew(-26deg);
      height: 100%;
      background: no-repeat;
      background-size: cover;
      background-position: center;
      // @media all and (max-width: @tablet) {
      //   background-size: cover;
      // }
      @media all and (max-width: 800px) {
        position: static;
        transform: none;
        height: 100%;
        background-position: center;
        background-size: cover;
        // border: 1px solid red;
        // background-size: cover;
      }
      img {
        height: 100%;
      }
    }
    &--red {
      position: absolute;
      transform: skew(1.5deg);
      top: 15px;
      left: 42px;
      background: @red-main;
      height: 100%;
      width: 100%;
      // box-shadow: 10px 10px 30px #000000;
      z-index: 2;
      @media all and (max-width: 800px) {
        display: none;
      }
    }
    &--blue {
      position: absolute;
      transform: skew(3deg);
      top: 28px;
      left: 35px;
      background: #0d3147;
      height: 100%;
      width: 100%;
      //box-shadow: 10px 10px 30px #000000;
      z-index: 0;
      @media all and (max-width: 800px) {
        display: none;
      }
    }
  }



  /*              АНИМАЦИЯ  Правой части          */
  .slick-slide &__figure--blue {
    animation: fadeOutRight 1000ms both 600ms;
  }
  .slick-slide.slick-active &__figure--blue {
    animation: fadeInRight 1000ms both 1700ms;
  }

  .slick-slide &__figure--red {
    animation: fadeOutRight 1000ms both 300ms;
  }

  .slick-slide.slick-active &__figure--red {
    animation: fadeInRight 1000ms both 1300ms;
  }

  .slick-slide &__figure--img {
    animation: fadeOutRight 1000ms both 0ms;
  }
  
  .slick-slide.slick-active &__figure--img {
    animation: fadeInRight 1000ms both 1600ms;
  }

/*              АНИМАЦИЯ  Левой части          */
  .slick-slide &__header {
    animation: fadeOutLeft 1000ms both 0ms;
  }
  .slick-slide.slick-active &__header {
    animation: fadeInRight 800ms both 1000ms;
  }
  .slick-slide &__description {
    animation: fadeOutLeft 1000ms both 300ms;
  }
  .slick-slide.slick-active &__description {
    animation: fadeInRight 800ms both 1300ms;
  } 
  .slick-slide &__bottom {
    animation: fadeOutLeft 1000ms both 600ms;
  }
  .slick-slide.slick-active &__bottom {
    animation: fadeInRight 800ms both 1600ms;
  }
  .slick-slide &__icon-list {
    animation: fadeOut 500ms both 700ms;
  }
  .slick-slide.slick-active &__icon-list {
    animation: fadeIn 500ms both 1000ms;
  }

  &__content &__right-side {
    opacity: 0;
    transition: opacity 200ms;
  }
 
   &__content.slick-initialized &__right-side{
      opacity: 1;
      transition: opacity 200ms;
   }

  .slick-dots {
    position: absolute;
    top: 40%;
    left: 11%;
    margin-top: 60px;
    list-style: none;
    display: inline-block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 10px;
    @media all and (max-width: 1366px) {
      left: 3%;
    }
    @media all and (max-width: 1200px) {
      top: auto;
      bottom: -40px;
      left: 50%;
      width: auto;
      height: 10px;
    }
    @media all and (max-width: 1200px) {
      bottom: -70px;
    }
    @media all and (max-width: 800px) {
      bottom: -60px;
      left: 0;
      right: 0;
    }
  }
  .slick-dots li {
    width: 10px;
    height: 10px;
    margin: 5px 0;
    @media all and (max-width: 1200px) {
      margin: 0 5px;
    }
  }
  .slick-dots li button {
    position: relative;
    padding: 0;
    width: 10px;
    height: 10px;
    margin: 0 auto;
  }
  .slick-dots li button::before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 10px;
    height: 10px;
    font-family: "slick";
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: white;
    border: 1px solid #3b3b3b;
    border-radius: 50%;
    opacity: 1;
  }
  .slick-dots li.slick-active button::before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 10px;
    height: 10px;
    text-align: center;
    background: #565656;
    border: 1px solid #565656;
    border-radius: 50%;
    z-index: 9;
  }

}