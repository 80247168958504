.slider-clients {
  position: relative;
  margin: 80px 50px;
  @media all and (max-width: @tablet) {
    margin: 50px 50px 60px;
  }

  // .slick-track {
  //   display: flex;
  //   justify-content: space-between;
  //   @media all and (max-width: @mobile) {
  //     display: block;
  //   }
  // }

  &__item {
    position: relative;
    display: inline-block;
    font-size: 0;
    height: 85px;

    &:last-child {
      margin-right: 0;
    }
    &:first-child {
      margin-left: 0;
    }
    @media all and (max-width: 580px) {
      margin: 0;
    }
  }
  &__img-container {
    height: 100%;
    // max-width: 70px;
    // margin: 0 auto;

  }
  &__img {
    position: absolute;
    top: 0;
    width: auto;
    max-height: 85px;
    z-index: 1;
    left: 50%;
    transform: translateX(-50%);
    transition: opacity 300ms;
    &:hover {
      opacity: 0;
      transition: opacity 300ms;
    }
  }
  &__img:hover ~ &__img--hover {
    opacity: 1;
    transition: opacity 300ms;
  }
  &__img--hover {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    z-index: 0;
    transition: opacity 300ms;
  }
  &__img::hover &__img--hover {
    opacity: 1;
  }
  &__btn {
    position: relative;
    font-size: 1.5rem;
    &--next,
    &--prev {
      position: absolute;
      top: 15%;
      display: inline-block;
      color: #b0b0b0;
      transition: color 200ms;
      &:hover {
        color: #1e201d;
        transition: color 200ms;
      }
    }
    &--next {
      right: -50px;
    }
    &--prev {
      left: -50px;
      transform: rotate(180deg);
    }
  }
  .slick-dots {
    position: static;
    margin-top: 60px;
  }
  .slick-dots li {
    width: 10px;
    height: 10px;
    margin: 0 8px;
  }
  .slick-dots li button {
    position: relative;
    padding: 0;
    width: 10px;
    height: 10px;
    margin: 0 auto;
  }
  .slick-dots li button::before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 10px;
    height: 10px;
    font-family: "slick";
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: white;
    border: 1px solid #3b3b3b;
    border-radius: 50%;
    opacity: 1;
  }
  .slick-dots li.slick-active button::before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 10px;
    height: 10px;
    text-align: center;
    background: @red-main; // earlier color: #e25347
    border: 1px solid @red-main;
    border-radius: 50%;
    z-index: 9;
  }

}