.contacts-detailed {
  border-top: 1px solid #e9e9e9;
  font-family: "Glober-Regular";
  position: relative;

  &__body {
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
    padding-top: 32px;
    @media (max-width: 768px) {
      padding-left: 10px;
      padding-right: 10px;
    }
    @media (min-width: 769px) and (max-width: @tablet) {
      padding-left: 28px;
      padding-right: 28px;
    }
    @media (max-width: 800px) {
      padding-bottom: 48px;
    }
    @media (min-width: (@tablet + 1px)) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  &__breadcrumbs {
    background-color: #f5f5f5;
    @media (max-width: 800px) {
      display: none;
    }
    @media (min-width: 801px) {
      padding-right: 20px;
      width: 50%;
    }
  }

  &__title {
    color: #202020;
    font-weight: 400;
    line-height: 1.3;
    @media (max-width: 800px) {
      font-size: 30px;
      letter-spacing: -.8px;
    }
    @media (min-width: 801px) {
      font-size: 44px;
      letter-spacing: -1.1px;
      margin-top: 46px;
      padding-right: 20px;
      width: 50%;
    }
  }

  &__subtitle {
    color: #565656;
    font-weight: 600;
    @media (max-width: 800px) {
      font-size: 15px;
      letter-spacing: .2px;
      line-height: 1.4;
      margin-top: 40px;
    }
    @media (min-width: 801px) {
      font-size: 17px;
      letter-spacing: .3px;
      margin-top: 48px;
      padding-right: 20px;
      width: 50%;
    }
  }

  &__info {
    letter-spacing: .2px;
    line-height: 1.4;
    @media (max-width: 800px) {
      display: block;
      font-size: 14px;
      margin-top: 33px;
    }
    @media (min-width: 801px) {
      font-size: 16px;
      padding-right: 20px;
      padding-top: 2px;
      width: 50%;
    }
  }

  &__info-body {
    @media (max-width: 800px) {
      display: block;
    }
  }

  &__info-item {
    @media (max-width: 800px) {
      display: block;
      & + & {
        margin-top: 32px;
      }
    }
  }

  &__info-name {
    color: #878787;
    font-weight: 400;
    text-align: left;
    vertical-align: top;
    @media (max-width: 800px) {
      display: block;
    }
    @media (min-width: 801px) {
      padding-right: 20px;
      padding-top: 36px;
      width: 145px;
    }
  }

  &__info-value {
    color: #565656;
    font-weight: 400;
    vertical-align: top;
    @media (max-width: 800px) {
      display: block;
      margin-top: 12px;
    }
    @media (min-width: 801px) {
      padding-top: 36px;
    }
  }

  &__info-link {
    color: #565656;
  }
  &__info-link-highlighted {
    color: #171717;
  }

  &__map {
    @media (max-width: 768px) {
      margin-left: -10px;
      margin-right: -10px;
    }
    @media (min-width: 769px) and (max-width: 800px) {
      margin-left: -28px;
      margin-right: -28px;
    }
    @media (max-width: 800px) {
      border-bottom: 1px solid fade(#000, 8%);
      border-top: 1px solid fade(#000, 8%);
      height: 330px;
      margin-top: 28px;
    }
    @media (min-width: 801px) {
      height: 100%;
      left: ~"calc(50% + 20px)";
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  &__response {
    position: relative;
    @media (max-width: 800px) {
      padding-top: 35px;
    }
    @media (min-width: 801px) {
      margin-top: 56px;
      padding-bottom: 60px;
      width: ~"calc(50% - 20px)";
    }
  }

  &__response-form {
    position: relative;
    @media (min-width: 801px) {
      border-radius: 5px;
      border: 1px solid #e2e2e2;
      // box-shadow: -0.9px 1.8px 15px 0 rgba(0, 0, 0, 0.04);
      max-width: 495px;
      padding: 38px 55px 42px 45px;
    }
  }

  &__response-success {
    border-radius: 5px;
    color: #fff;
    border-radius: 5px;
    border: 1px solid #e2e2e2;
    box-shadow: -0.9px 1.8px 15px 0 rgba(0, 0, 0, 0.04);
    @media (max-width: 800px) {
      margin: 0 auto;
      padding: 37px 30px;
    }
    @media (min-width: 801px) {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
      left: 0;
      max-width: 495px;
      min-height: 180px;
      padding: 30px 40px 30px 90px;
    }
  }

  &__response-success-title {
    color: #30576f;
    flex: 0 0 auto;
    line-height: 1.2;
    position: relative;
    width: 100%;
    @media (max-width: 800px) {
      font-size: 25px;
      letter-spacing: -.6px;
      padding-left: 46px;
    }
    @media (min-width: 801px) {
      font-size: 28px;
      letter-spacing: -.8px;
    }
  }

  &__response-success-icon {
    fill: #30576f;
    position: absolute;
    top: -2px;
    @media (max-width: 800px) {
      height: 30px;
      left: 0;
      width: 30px;
    }
    @media (min-width: 801px) {
      height: 28px;
      left: -44px;
      width: 28px;
    }
  }

  &__response-success-subtitle {
    color: #565656;
    flex: 0 0 auto;
    margin-top: 17px;
    width: 100%;
    @media (max-width: 800px) {
      font-size: 12px;
      letter-spacing: 0.2px;
    }
    @media (min-width: 801px) {
      font-size: 14px;
      line-height: 1.3;
    }
  }
}