.menu {
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 35px auto 50px;
  padding: 0 20px;
  @media all and (max-width: 1024px) {
    padding: 0 30px;
  }
  @media all and (max-width: 768px) {
    position: absolute;
    margin: 40px 0 0 0;
    padding: 20px 20px 0;
    top: 0;
    right: 0;
    width: 100%;
    // height: 100px;
    // background: red;
    z-index: 1;
  }

  &__btn-mobile {
    display: none;
    @media all and (max-width: 768px) {
      position: absolute;
      top: -30px;
      right: 20px;
      display: inline-block;
      width: 18px;
      height: 14px;
    }
  }

  &.js-open &__btn-mobile-icon {
    @media all and (max-width: 768px) {
      position: absolute;
      top: -30px;
      right: 20px;
      display: inline-block;
      background: none;
      width: 16px;
      height: 16px;
      margin: 0;
      transform: rotate(45deg);
      &::before,
      &::after {
        position: absolute;
        top: 0;
        content: '';
        background: #909090;
        border-radius: 1px;
      }
      &::before {
        width: 2px;
        height: 16px;
        left: 7px;
      }
      &::after {
        width: 16px;
        height: 2px;
        top: 7px;
      }
    }
  }

  &__btn-mobile-icon {
    display: none;
    @media all and (max-width: 768px) {
      // position: relative;   
      display: inline-block;
      width: 18px;
      height: 2px;
      background: #909090;
      margin: 6px 0;
      border-radius: 1px;
      &::before,
      &::after {
        position: absolute;
        left: 0;
        content: '';
        display: inline-block;
        width: 18px;
        height: 2px;
        background: #909090;
        border-radius: 1px;
      }
      &::before {
        top: -5px;
      }
      &::after {
        bottom: -5px;
      }
    }
  }

  &__logo {
    display: inline-block;
    font-size: 0;
    margin-right: 5px;
    width: 280px;
    @media all and (max-width: 768px) {
      margin: 0;
    }
    @media all and (max-width: @mobile) {
      width: 240px;
    }
    @media all and (max-width: 480px) {
      width: 175px;
    }
  } 

  &__logo-img {
    max-width: 100%;
  }

  &__list {
    width: 430px;
    display: flex;
    justify-content: space-between;
    @media all and (max-width: @tablet) {
      width: 50%;
    }
    @media all and (max-width: 768px) {
      display: none;
      animation: fadeOut 200ms both 0ms;
    }
  }

  &__item {
    text-transform: uppercase;
    @media all and (max-width: 768px) {
      display: inline-block;
      width: 100%;
      text-align: center;
      margin: 0 auto;
    }
  }

  &__link {
    margin-top: 10px;
    position: relative;
    font: 14px 'Glober-Regular';
    color: #565656;
    display: inline-block;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      height: 20px;
      width: 0; 
      border-bottom: solid 3px @red-main; // earlier color: #dd2a1b
      transition: all 200ms ease-in-out;	
    } 
    @media all and (max-width: 768px) {
      margin-top: 20px;
    }
  }

  &__link--active,
  &__link:hover {
    &:after {
      width: 100%;
    }
  }

  &.js-open &__list {
    @media all and (max-width: 768px) {
      position: absolute;
      top: -10px;
      left: 0;
      right: 0;
      display: block;
      width: 100%;
      background: #f5f5f5;
      padding-bottom: 20px;
      border-bottom: 1px solid #e9e9e9;
      animation: fadeIn 200ms both 0ms;
      z-index: 10;
    }
  }
}